import React, { useState } from 'react';
import Header from '../components/Header';
import ItemMain from './ItemMain';
import FileImport from './FileImport';

function MainPage({ vendorId }) {
  const [itemOpen, setItemOpen] = useState(false);
  const [itemCatalogOpen, setItemCatalogOpen] = useState(true);
  const [approvals, setApprovals] = useState(false);
  const [proprietaryItem, setProprietaryItem] = useState(null);

  return (
    <React.Fragment>
      {/*<Router>*/}
      {/*  <Header />*/}
      {/*  <Route*/}
      {/*    path="/suite/item-enrichment/item-catalog"*/}
      {/*    render={(props) => (*/}
      {/*      <ItemMain {...props} itemOpen={itemOpen} setItemOpen={setItemOpen} userId={userId} />*/}
      {/*    )}*/}
      {/*  />*/}
      {/*  <Route path="/suite/item-enrichment/file-import"*/}
      {/*         render={(props) => (*/}
      {/*             <FileImport {...props} userId={userId} />*/}
      {/*         )}/>*/}
      {/*  <Route path="/suite/item-enrichment">*/}
      {/*    <Redirect exact to="/suite/item-enrichment/item-catalog" />*/}
      {/*  </Route>*/}
      {/*</Router>*/}
      <Header
        setItemCatalogOpen={setItemCatalogOpen}
        setApprovals={setApprovals}
        vendorId={vendorId}
        setItemOpen={setItemOpen}
        proprietaryFilter={proprietaryItem}
      />

      {itemCatalogOpen ? (
        <ItemMain 
          itemOpen={itemOpen} 
          setItemOpen={setItemOpen} 
          approvals={approvals} 
          vendorId={vendorId} 
          setProprietaryItem={setProprietaryItem} 
          proprietaryItem={proprietaryItem} 
        />
      ) : (
        <FileImport vendorNo={vendorId} />
      )}

      {/* <Dashboard /> */}
    </React.Fragment>
  );
}

export default MainPage;

MainPage.propTypes = {};
