import React from 'react';
import i18n from 'i18next';

function Packaging({ coreData, flags }) {
  const getWeightDataClassName = () => {
    return flags?.catchWeight === 'Y' ? '(Est.)' : '';
  };
  return coreData?.packaging ? (
    <div id="info-column-preview-info-row-2" className="info-row">
      <label id="info-column-preview-datablock-packaging-label" className="label">
        {i18n.t('infoColumn.packaging')}
      </label>
      {coreData?.packaging?.itemWeight && (
        <div id="info-column-preview-datablock-weight" className="data-block weight">
          <div id="info-column-preview-datablock-weight-label" className="label">
            {i18n.t('infoColumn.packaging.itemWeight')}
          </div>
          <div id="info-column-preview-datablock-weight-data" className="data">{`${
            coreData?.packaging?.itemWeight
          }${getWeightDataClassName()}`}</div>
        </div>
      )}
      {coreData?.packaging?.caseCube && (
        <div id="info-column-preview-datablock-cube" className="data-block cube">
          <div id="info-column-preview-datablock-cube-label" className="label">
            {i18n.t('infoColumn.packaging.caseCube')}
          </div>
          <div id="info-column-preview-datablock-cube-data" className="data">
            {coreData?.packaging?.caseCube}
          </div>
        </div>
      )}
      {coreData?.packaging?.servings && (
        <div id="info-column-preview-datablock-case" className="data-block case">
          <div id="info-column-preview-datablock-case-label" className="label">
            {i18n.t('infoColumn.packaging.servings')}
          </div>
          <div id="info-column-preview-datablock-case-data" className="data">
            {coreData?.packaging?.servings}
          </div>
        </div>
      )}
      {coreData?.packaging?.servingsUOM && (
        <div id="info-column-preview-datablock-uom" className="data-block uom">
          <div id="info-column-preview-datablock-uom-label" className="label">
            {i18n.t('infoColumn.packaging.servingsUOM')}
          </div>
          <div id="info-column-preview-datablock-uom-data" className="data">
            {coreData?.packaging?.servingsUOM}
          </div>
        </div>
      )}
    </div>
  ) : null;
}

export default Packaging;
