import React from 'react';
import CommonMediaWidgets from './CommonMediaWidgets';

function MediaColumnMobile({ mediaInfo, supc, permissions }) {
  return (
    <div id="media-column-mobile-mode" className="media-column mobile-mode">
      <CommonMediaWidgets
        id="media-column-mobile-mode-common-media-widgets"
        mediaInfo={mediaInfo}
        supc={supc}
        permissions={permissions}
        id="mobile"
      />
    </div>
  );
}

export default MediaColumnMobile;
