import React, { useState } from 'react';
import { attributeEditorDetails, isValidToDisplay } from '../../../utils/Utils';
import {
  APPROVED,
  DELETE_APPROVED,
  DELETE_PENDING,
  DELETE_REJECTED,
  PENDING,
  REJECTED,
} from '../../../enums/ApprovalStatus';
import { Button, Icon, Tooltip } from 'antd';
import i18n from '../../../_i18n';
import { CERTIFICATION } from '../../../enums/AttributeType';
import { CERTIFICATES_PREFIX } from '../../../constants/Constants';
import { approvePendingFile, deleteFile, updateStagingAttribute } from '../../../actions';
import UploadModalSingle from '../../../components/modals/UploadModalSingle';
import RejectModal from '../../../components/modals/RejectModal';
import { useDispatch } from 'react-redux';

const fileCSSApplier = (approvalStatus, isChangedUser) => {
  if (approvalStatus === APPROVED && isChangedUser) {
    return 'file-thumbnail highlighted approved';
  } else if (approvalStatus === DELETE_APPROVED && isChangedUser) {
    return 'file-thumbnail highlighted approved';
  } else if (approvalStatus === REJECTED && isChangedUser) {
    return 'file-thumbnail highlighted rejected';
  } else if (approvalStatus === DELETE_REJECTED && isChangedUser) {
    return 'file-thumbnail highlighted rejected';
  } else if (approvalStatus === DELETE_PENDING) {
    return 'file-thumbnail highlighted rejected';
  } else if (approvalStatus === PENDING) {
    return 'file-thumbnail highlighted';
  } else {
    return 'file-thumbnail';
  }
};

function getCertificateApprovedState(certification) {
  return certification?.attributeStatus === DELETE_PENDING ? DELETE_APPROVED : APPROVED;
}

function getCertificateDeleteStatus(certification, attributeManagement) {
  if (certification?.attributeStatus === PENDING) {
    return null;
  } else if (attributeManagement?.CERTIFICATION?.approvable) {
    return DELETE_APPROVED;
  } else {
    return DELETE_PENDING;
  }
}

function getCertificateRejectedStatus(certificateUnderEdit) {
  return certificateUnderEdit?.attributeStatus === DELETE_PENDING ? DELETE_REJECTED : REJECTED;
}

function showApproveButtons(certification, attributeManagement) {
  return (
    (certification?.attributeStatus === PENDING || certification?.attributeStatus === DELETE_PENDING) &&
    attributeManagement?.CERTIFICATION?.approvable
  );
}

function getDeletePendingText(certification) {
  return certification?.attributeStatus === DELETE_PENDING
    ? i18n.t('mediaColumn.pendingApprovalDelete')
    : i18n.t('mediaColumn.pendingApproval');
}

function showPendingHighlighted(certification, attributeManagement) {
  return (
    (certification?.attributeStatus === PENDING || certification?.attributeStatus === DELETE_PENDING) &&
    !attributeManagement?.CERTIFICATION?.approvable
  );
}

function getDeleteApprovedStatus(certification) {
  return certification?.attributeStatus === DELETE_APPROVED
    ? i18n.t('mediaColumn.approvedDelete')
    : i18n.t('mediaColumn.approved');
}

function showDismiss(certification, userType) {
  return (
    certification?.authorRole === userType &&
    (certification?.attributeStatus === REJECTED || certification?.attributeStatus === DELETE_REJECTED)
  );
}

function getDeleteRejectedText(certification) {
  return certification?.attributeStatus === DELETE_REJECTED
    ? i18n.t('mediaColumn.rejectedDelete')
    : i18n.t('mediaColumn.rejected');
}

function showDeleteButton(certification, attributeManagement) {
  return (
    !(
      certification?.attributeStatus === DELETE_APPROVED ||
      certification?.attributeStatus === DELETE_PENDING ||
      certification?.attributeStatus === REJECTED
    ) && attributeManagement.CERTIFICATION.editable
  );
}

function showInfoButton(certification, userType) {
  return (
    (certification?.authorRole === userType ||
      certification?.attributeStatus === PENDING ||
      certification?.attributeStatus === DELETE_PENDING) &&
    certification?.attributeStatus !== REJECTED &&
    certification?.attributeStatus !== DELETE_REJECTED &&
    certification?.attributeStatus !== null
  );
}

function Certifications({ nutritionalInfo, userType, associatedUsers, supc, userId, permissions }) {
  const dispatch = useDispatch();

  const certifications = [];
  const attributeImages = [];

  const [showCertificationUpload, setShowCertificationUpload] = useState(false);
  const [rejectModalVisibility, setRejectModalVisibility] = useState(false);
  const [certificateUnderEdit, setCertificateUnderEdit] = useState(null);

  const handleFileDelete = async (
    certification,
    uploadType,
    attributeStatus,
    identifier,
    deletingUserId,
    deletingUserType,
    deletingSupc,
    deletingAssociatedUsers
  ) => {
    const updatedFile = {
      attributeType: uploadType,
      value: certification?.value,
      attributeStatus: attributeStatus,
      comment: null,
      author: deletingUserId,
      authorRole: deletingUserType,
      timestamp: null,
      identifier: identifier,
    };
    dispatch(
      deleteFile(
        certification?.value?.certificateName,
        uploadType,
        deletingSupc,
        deletingUserId,
        i18n.language,
        attributeStatus,
        updatedFile,
        deletingUserType,
        deletingAssociatedUsers
      )
    );
  };

  const handleSave = (
    attributeType,
    attributeValue,
    status,
    comment,
    identifier,
    savingUserId,
    savingUserType,
    savingSupc,
    savingAssociatedUsers
  ) => {
    const updatedFile = {
      ...attributeValue,
      attributeStatus: status,
      comment,
      author: savingUserId,
      identifier: identifier,
      authorRole: savingUserType,
    };

    if (attributeValue.attributeStatus === DELETE_APPROVED && null === status) {
      dispatch(
        deleteFile(
          attributeValue?.value?.certificateName,
          attributeType,
          savingSupc,
          savingUserId,
          i18n.language,
          status,
          updatedFile,
          savingUserType,
          savingAssociatedUsers
        )
      );
    } else {
      const attribute = {
        attributeType: attributeType,
        value: [updatedFile],
        attributeStatus: status,
        comment,
        author: savingUserId,
        authorRole: savingUserType,
        timestamp: null,
      };
      dispatch(updateStagingAttribute(supc, i18n.language, attribute, associatedUsers));
    }
  };

  const handleApprovingPendingFile = async (
    fileName,
    copySource,
    destination,
    certification,
    approvingCertifications,
    attributeStatus,
    identifier,
    approvingSupc,
    approvingUserId,
    approvingUserType,
    approvingAssociatedUsers
  ) => {
    dispatch(
      approvePendingFile(
        i18n.language,
        CERTIFICATION,
        approvingSupc,
        approvingUserId,
        fileName,
        copySource,
        destination,
        certification,
        approvingCertifications,
        attributeStatus,
        identifier,
        approvingUserType,
        approvingAssociatedUsers
      )
    );
  };

  const completeApprovingPendingFile = (
    certification,
    approvingNutritionalInfo,
    approvingSupc,
    approvingUserId,
    approvingUserType,
    approvingAssociatedUsers
  ) => {
    let urlArray = certification?.value?.certificateURL.split('/');

    handleApprovingPendingFile(
      certification?.value?.certificateName,
      'staging/' + CERTIFICATES_PREFIX + urlArray[urlArray.length - 1],
      CERTIFICATES_PREFIX + urlArray[urlArray.length - 1],
      certification,
      approvingNutritionalInfo?.certification,
      getCertificateApprovedState(certification),
      certification?.value?.certificateURL,
      approvingSupc,
      approvingUserId,
      approvingUserType,
      approvingAssociatedUsers
    );
  };

  const dismissCertificationLabel = (certification) => {
    handleSave(
      CERTIFICATION,
      certification,
      null,
      null,
      certification?.value?.certificateURL,
      userId,
      userType,
      supc,
      associatedUsers
    );
  };

  nutritionalInfo?.attributeImages?.length > 0 &&
    attributeImages.push(
      nutritionalInfo?.attributeImages?.map((attributeImage, i) => (
        <li>
          <img id={`nutritional-info-attribute-image-${i + 1}`} src={attributeImage} />
        </li>
      ))
    );

  nutritionalInfo?.certification?.filter((cert) => cert?.value?.certificateURL && isValidToDisplay(cert, userType))
    ?.length > 0 &&
    certifications.push(
      nutritionalInfo?.certification
        ?.filter((cert) => cert?.value?.certificateURL && isValidToDisplay(cert, userType))
        ?.map((certification, i) => (
          <div
            id={`nutritional-info-certifications-status-${i + 1}`}
            key={`nutritional-info-certifications-status-${i + 1}`}
            className={fileCSSApplier(certification?.attributeStatus, certification?.authorRole === userType)}
          >
            {showApproveButtons(certification, permissions) && (
              <div id={`nutritional-info-certifications-label-${i + 1}`} className="file-label-header">
                <Icon
                  id={`nutritional-info-certifications-warning-icon-${i + 1}`}
                  type="warning"
                  theme="filled"
                  className="status-icon warning"
                />
                {/* <Icon type="checked"  className="status-icon approved" />
                          <Icon type="cross" theme="filled" className="status-icon rejected" /> */}
                <div id={`nutritional-info-certifications-status-label-${i + 1}`} className="label">
                  {getDeletePendingText(certification)}
                </div>
                <div id={`nutritional-info-certifications-label-actions-${i + 1}`} className="label-file-actions">
                  <Button
                    id={`nutritional-info-certifications-label-actions-approve-${i + 1}`}
                    className="btn green-action-btn pure empty-btn"
                    onClick={() => {
                      completeApprovingPendingFile(
                        certification,
                        nutritionalInfo,
                        supc,
                        userId,
                        userType,
                        associatedUsers
                      );
                    }}
                  >
                    <Icon id={`nutritional-info-certifications-label-actions-approve-icon-${i + 1}`} type="check" />
                  </Button>
                  <Button
                    id={`nutritional-info-certifications-label-actions-reject-${i + 1}`}
                    className="btn red-action-btn pure empty-btn"
                    onClick={() => {
                      setRejectModalVisibility(true);
                      setCertificateUnderEdit(certification);
                    }}
                  >
                    <Icon id={`nutritional-info-certifications-label-actions-reject-icon-${i + 1}`} type="close" />
                  </Button>
                </div>
              </div>
            )}
            {showPendingHighlighted(certification, permissions) && (
              <div id={`nutritional-info-certifications-label-pending-${i + 1}`} className="file-label-header">
                <Icon
                  id={`nutritional-info-certifications-label-pending-icon-${i + 1}`}
                  type="warning"
                  theme="filled"
                  className="status-icon warning"
                />
                {/* <Icon type="checked"  className="status-icon approved" />
                          <Icon type="cross" theme="filled" className="status-icon rejected" /> */}
                <div id={`nutritional-info-certifications-pending-label-${i + 1}`} className="label">
                  {getDeletePendingText(certification)}
                </div>
              </div>
            )}
            {certification?.authorRole === userType &&
              (certification?.attributeStatus === APPROVED || certification?.attributeStatus === DELETE_APPROVED) && (
                <div id={`nutritional-info-certifications-label-approved-${i + 1}`} className="file-label-header">
                  <Icon
                    id={`nutritional-info-certifications-label-approved-icon-${i + 1}`}
                    type="checked"
                    theme="filled"
                    className="status-icon approved"
                  />
                  {/* <Icon type="checked"  className="status-icon approved" />
                          <Icon type="cross" theme="filled" className="status-icon rejected" /> */}
                  <div id={`nutritional-info-certifications-label-approved-label-${i + 1}`} className="label">
                    {getDeleteApprovedStatus(certification)}
                  </div>
                  <Button
                    id={`nutritional-info-certifications-label-approved-dismiss-${i + 1}`}
                    className="btn btn-reject"
                    onClick={() => {
                      dismissCertificationLabel(certification);
                    }}
                  >
                    {i18n.t('infoColumn.dismiss')}{' '}
                  </Button>
                </div>
              )}
            {showDismiss(certification, userType) && (
              <div id={`nutritional-info-certifications-label-rejected-${i + 1}`} className="file-label-header">
                <Icon
                  id={`nutritional-info-certifications-label-rejected-icon-${i + 1}`}
                  type="cross"
                  theme="filled"
                  className="status-icon rejected"
                />
                {/* <Icon type="checked"  className="status-icon approved" />
                          <Icon type="cross" theme="filled" className="status-icon rejected" /> */}
                <div id={`nutritional-info-certifications-label-rejected-label-${i + 1}`} className="label">
                  {getDeleteRejectedText(certification)}
                </div>
                <Button
                  id={`nutritional-info-certifications-label-rejected-dismiss-${i + 1}`}
                  className="btn btn-reject"
                  onClick={() => {
                    dismissCertificationLabel(certification);
                  }}
                >
                  {i18n.t('infoColumn.dismiss')}{' '}
                </Button>
              </div>
            )}

            <div id={`nutritional-info-certifications-inner-file-info-${i + 1}`} className="inner-file-info">
              <a
                id={`nutritional-info-certifications-inner-file-info-url-${i + 1}`}
                className="icon"
                href={certification?.value?.certificateURL}
                target="_blank"
              />
              <div id={`nutritional-info-certifications-inner-file-info-data-${i + 1}`} className="info">
                <div id={`nutritional-info-certifications-inner-file-info-data-filename-${i + 1}`} className="filename">
                  <a
                    id={`nutritional-info-certifications-inner-file-info-data-filename-url-${i + 1}`}
                    href={certification?.value?.certificateURL}
                    target="_blank"
                  >
                    {certification?.value?.certificateName}{' '}
                  </a>
                  {showDeleteButton(certification, permissions) && (
                    <i
                      id={`nutritional-info-certifications-inner-file-info-data-delete-${i + 1}`}
                      className="delete-icon fi flaticon-delete"
                      onClick={() =>
                        handleFileDelete(
                          certification,
                          CERTIFICATION,
                          getCertificateDeleteStatus(certification, permissions),
                          certification?.value?.certificateURL,
                          userId,
                          userType,
                          supc,
                          associatedUsers
                        )
                      }
                    />
                  )}
                </div>
                <div id="nutritional-info-update-date" className="update-date">
                  {certification?.value?.updateDate}
                </div>
              </div>

              {showDismiss(certification, userType) && (
                <Tooltip
                  id={`nutritional-info-certifications-inner-file-info-tooltip-${i + 1}`}
                  overlayStyle={{ whiteSpace: 'pre-line' }}
                  title={
                    attributeEditorDetails(userType, certification?.attributeStatus, certification) +
                    '\n' +
                    i18n.t('infoColumn.reason') +
                    ': ' +
                    certification?.comment
                  }
                >
                  <i
                    id={`nutritional-info-certifications-inner-file-info-tooltip-icon-${i + 1}`}
                    className="info-icon fi flaticon-information-button"
                  />
                </Tooltip>
              )}
              {showInfoButton(certification, userType) && (
                <Tooltip
                  id={`nutritional-info-certifications-inner-file-info-not-rejected-tooltip-icon-${i + 1}`}
                  title={attributeEditorDetails(userType, certification?.attributeStatus, certification)}
                >
                  <i className="info-icon fi flaticon-information-button" />
                </Tooltip>
              )}
            </div>
          </div>
        ))
    );

  return (
    <div>
      <div id="nutritional-column-certifications" className="certifications">
        {permissions.CERTIFICATION.visible && (
          <div id="nutritional-column-certifications-info" className="info-row">
            <div id="nutritional-column-certifications-block" className="data-block editable no-highlight">
              <div id="nutritional-column-certifications-label" className="label">
                {i18n.t('nutritionalColumn.certifications')} {/*<Dropdown overlay={dataBlockMenu}>*/}
                {/*  <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>*/}
                {/*    <Icon className="icon options-menu-icon" type="more" />*/}
                {/*  </a>*/}
                {/*</Dropdown>*/}
                {permissions.CERTIFICATION.editable && (
                  <i
                    id="nutritional-column-certifications-upload-btn"
                    className="icon add-data-block fi flaticon-add-button-inside-black-circle"
                    onClick={() => setShowCertificationUpload(true)}
                  />
                )}
              </div>
              {attributeImages?.length > 0 && (
                <ul id="nutritional-column-attribute-images-list" className="logos">
                  {attributeImages}
                </ul>
              )}
              {certifications?.length > 0 && <ul id="nutritional-column-certifications-list">{certifications}</ul>}
            </div>
          </div>
        )}
      </div>
      <UploadModalSingle
        visible={showCertificationUpload}
        loading={false}
        uploadType={CERTIFICATION}
        supc={supc}
        visibleSetter={setShowCertificationUpload}
        existing={nutritionalInfo?.certification}
        approvable={permissions?.CERTIFICATION?.approvable}
        urls={nutritionalInfo?.certification?.map((cert) => cert?.value?.certificateURL)}
      />
      <RejectModal
        modalVisibility={rejectModalVisibility}
        setRejectVisibility={setRejectModalVisibility}
        rejectFunction={(comment) => {
          handleSave(
            CERTIFICATION,
            certificateUnderEdit,
            getCertificateRejectedStatus(certificateUnderEdit),
            comment,
            certificateUnderEdit?.value?.certificateURL,
            userId,
            userType,
            supc,
            associatedUsers
          );
        }}
        rejectAttribute={i18n.t('nutritionalColumn.certifications')}
      />
    </div>
  );
}

export default Certifications;
