import React from 'react';
import { useTranslation } from 'react-i18next';

export function AllergenStaticAttribute({ label, value, id }) {
  const { t } = useTranslation();
  return value ? (
    <div id={`item-allergens-${id}-block`} className="data-block">
      <div id={`item-allergens-${id}-label`} className="label">
        {t(label)}
      </div>
      <div id={`item-allergens-${id}-value`} className="data">
        {value}
      </div>
    </div>
  ) : null;
}

export default AllergenStaticAttribute;
