import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function populateValue(value) {
  return value !== '' ? parseFloat(value).toFixed(2) : value;
}

function certificationVisibility(c, userType) {
  let visible = false;
  if ((c?.attributeStatus === 'REJECTED' && c?.authorRole === userType) || c.attributeStatus !== 'REJECTED') {
    visible = true;
  }
  return visible;
}

function NutritionalColumnPreview({ nutritionalInfo }) {
  const { t } = useTranslation();

  const {
    data: { userRole: userType },
  } = useSelector((state) => state?.userData);

  const attributeImages = [];
  const certifications = [];

  const getDvValue = (dvValue) => {
    return dvValue !== '' ? parseFloat(dvValue).toFixed(2) : dvValue;
  };

  nutritionalInfo?.attributeImages?.length > 0 &&
    attributeImages.push(
      nutritionalInfo?.attributeImages
        ?.filter((i) => i != '')
        ?.map((attributeImage) => (
          <li
            id={`nutritional-info-attribute-images-${attributeImage}`}
            key={`nutritional-info-attribute-images-${attributeImage}`}
          >
            <img
              id={`nutritional-info-attribute-images-url-${attributeImage}`}
              key={`nutritional-info-attribute-images-url-${attributeImage}`}
              src={attributeImage}
            />
          </li>
        ))
    );
  nutritionalInfo?.certification?.length > 0 &&
    certifications.push(
      nutritionalInfo?.certification
        ?.filter((c) => c?.value?.certificateURL && certificationVisibility(c, userType))
        .map((certification, i) => (
          <div
            id={`nutritional-info-preview-certifications-file-thumbnail-${i + 1}`}
            key={`nutritional-info-preview-certifications-thumbnail-${certification?.value?.certificateURL}`}
            className="file-thumbnail"
          >
            <div id={`nutritional-info-preview-certifications-thumbnail-${i + 1}`} className="inner-file-info">
              <a
                id={`nutritional-info-preview-certifications-icon-${i + 1}`}
                className="icon"
                href={certification?.certificateURL}
                target="_blank"
              />
              <div id={`nutritional-info-preview-certifications-info-${i + 1}`} className="info">
                <div id={`nutritional-info-preview-certifications-filename-${i + 1}`} className="filename">
                  <a
                    id={`nutritional-info-preview-certifications-url-${i + 1}`}
                    href={certification?.value?.certificateURL}
                    target="_blank"
                  >
                    {certification?.value?.certificateName}
                  </a>
                </div>
                <div id={`nutritional-info-preview-certifications-update-date-${i + 1}`} className="update-date">
                  {certification?.updateDate}
                </div>
              </div>
            </div>
          </div>
        ))
    );

  return (
    <div id="nutritional-column-preview" className="nutritional-column">
      {nutritionalInfo?.nutritions?.value ? (
        <div id="nutritional-column-preview-nutrition-block" className="nutrition-block adjusted-for-source">
          <header id="nutritional-column-preview-nutrition-block-header">
            Nutrition Facts{' '}
            <div id="nutritional-column-preview-nutrition-fr" className="french">
              Valeur nutritive
            </div>
          </header>

          <div id="nutritional-column-preview-nutrition-block-serving-size" className="serving-size">
            Serving Size ({nutritionalInfo.nutritions?.value?.servingSize}
            {nutritionalInfo.nutritions?.value?.servingSizeUOM})
            <div id="nutritional-column-preview-nutrition-block-portion" className="french">
              Portion ({nutritionalInfo.nutritions?.value?.servingSize}
              {nutritionalInfo.nutritions?.value?.servingSizeUOM})
            </div>
          </div>

          <div id="nutritional-column-preview-nutrition-block-calories" className="nutrition-row calories no-border">
            <div id="nutritional-column-preview-nutrition-block-calories-value" className="label">
              Calories <span className="data">{nutritionalInfo.nutritions?.value?.calories}</span>
            </div>

            <div id="nutritional-column-preview-nutrition-block-calories-daily-value" className="dv">
              % Daily Value*{' '}
              <div id="nutritional-column-preview-nutrition-block-calories-daily-value-french" className="french">
                % Valeur quotidienne*
              </div>
            </div>
          </div>

          <div id="nutritional-column-preview-nutrition-block-fat" className="nutrition-row section-level-1 no-border">
            <div id="nutritional-column-preview-nutrition-block-fat-label" className="label">
              Fat / Lipides
              <span id="nutritional-column-preview-nutrition-block-fat-data" className="data">
                {nutritionalInfo.nutritions?.value?.totalFat}
                {nutritionalInfo.nutritions?.value?.totalFatUOM}
              </span>
            </div>

            <div id="nutritional-column-preview-nutrition-block-fat-daily-value" className="dv-data ">
              {populateValue(nutritionalInfo.nutritions?.value?.totalFatDV)}%
            </div>
          </div>

          <div
            id="nutritional-column-preview-nutrition-block-saturated"
            className="nutrition-row sub-section no-border"
          >
            <div id="nutritional-column-preview-nutrition-block-saturated-label" className="label">
              Saturated / Saturés
              <span id="nutritional-column-preview-nutrition-block-saturated-data" className="data">
                {nutritionalInfo.nutritions?.value?.saturated}
                {nutritionalInfo.nutritions?.value?.saturatedUOM}
              </span>
            </div>

            <div id="nutritional-column-preview-nutrition-block-saturated-daily-value" className="dv-data ">
              {populateValue(nutritionalInfo.nutritions?.value?.saturatedDV)}%
            </div>
          </div>

          <div id="nutritional-column-preview-nutrition-block-trans" className="nutrition-row sub-section">
            <div id="nutritional-column-preview-nutrition-block-trans-label" className="label">
              + Trans / trans
              <span id="nutritional-column-preview-nutrition-block-trans-data" className="data">
                {nutritionalInfo.nutritions?.value?.transFat}
                {nutritionalInfo.nutritions?.value?.transFatUOM}
              </span>
            </div>
          </div>
          <div
            id="nutritional-column-preview-nutrition-block-carbo"
            className="nutrition-row section-level-1 no-border"
          >
            <div id="nutritional-column-preview-nutrition-block-carbo-label" className="label">
              Carbohydrate / Glucides
              <span id="nutritional-column-preview-nutrition-block-carbo-data" className="data">
                {nutritionalInfo.nutritions?.value?.carbohydrates}
                {nutritionalInfo.nutritions?.value?.carbohydratesUOM}
              </span>
            </div>
          </div>
          <div id="nutritional-column-preview-nutrition-block-fibre" className="nutrition-row sub-section no-border">
            <div id="nutritional-column-preview-nutrition-block-fibre-label" className="label">
              Fibre / Fibres
              <span id="nutritional-column-preview-nutrition-block-fibre-data" className="data">
                {nutritionalInfo.nutritions?.value?.fibre}
                {nutritionalInfo.nutritions?.value?.fibreUOM}
              </span>
            </div>

            <div id="nutritional-column-preview-nutrition-block-fibre-daily-value" className="dv-data ">
              {populateValue(nutritionalInfo.nutritions?.value?.fibreDV)}%
            </div>
          </div>
          <div id="nutritional-column-preview-nutrition-block-sugars" className="nutrition-row sub-section">
            <div id="nutritional-column-preview-nutrition-block-sugars-label" className="label">
              Sugars / Sucres
              <span id="nutritional-column-preview-nutrition-block-sugars-data" className="data">
                {nutritionalInfo.nutritions?.value?.sugars}
                {nutritionalInfo.nutritions?.value?.sugarsUOM}
              </span>
            </div>

            <div id="nutritional-column-preview-nutrition-block-sugars-daily-value" className="dv-data ">
              {populateValue(nutritionalInfo.nutritions?.value?.sugarsDV)}%
            </div>
          </div>
          <div id="nutritional-column-preview-nutrition-block-protein" className="nutrition-row section-level-1">
            <div id="nutritional-column-preview-nutrition-block-protein-label" className="label">
              Protein / Protéines
              <span id="nutritional-column-preview-nutrition-block-protein-data" className="data">
                {nutritionalInfo.nutritions?.value?.proteins}
                {nutritionalInfo.nutritions?.value?.proteinsUOM}
              </span>
            </div>
          </div>
          <div id="nutritional-column-preview-nutrition-block-cholesterol" className="nutrition-row section-level-1">
            <div id="nutritional-column-preview-nutrition-block-cholesterol-label" className="label">
              Cholesterol
              <span id="nutritional-column-preview-nutrition-block-cholesterol-data" className="data">
                {nutritionalInfo.nutritions?.value?.cholesterol}
                {nutritionalInfo.nutritions?.value?.cholesterolUOM}
              </span>
            </div>
          </div>
          <div
            id="nutritional-column-preview-nutrition-block-sodium"
            className="nutrition-row section-level-1 divider-bottom"
          >
            <div id="nutritional-column-preview-nutrition-block-sodium-label" className="label">
              Sodium
              <span id="nutritional-column-preview-nutrition-block-sodium-data" className="data">
                {nutritionalInfo.nutritions?.value?.sodium}
                {nutritionalInfo.nutritions?.value?.sodiumUOM}
              </span>
            </div>

            <div id="nutritional-column-preview-nutrition-block-sodium-daily-value" className="dv-data ">
              {populateValue(nutritionalInfo.nutritions?.value?.sodiumDV)}%
            </div>
          </div>
          <div id="nutritional-column-preview-nutrition-block-potassium" className="nutrition-row">
            <div id="nutritional-column-preview-nutrition-block-potassium-label" className="label">
              Potassium
              <span id="nutritional-column-preview-nutrition-block-potassium-data" className="data">
                {nutritionalInfo.nutritions?.value?.potassium}
                {nutritionalInfo.nutritions?.value?.potassiumUOM}
              </span>
            </div>
            <div id="nutritional-column-preview-nutrition-block-potassium-daily-value" className="dv-data ">
              {getDvValue(nutritionalInfo?.nutritions?.value?.potassiumDV)}%
            </div>
          </div>
          <div id="nutritional-column-preview-nutrition-block-calcium" className="nutrition-row">
            <div id="nutritional-column-preview-nutrition-block-calcium-label" className="label">
              Calcium
              <span id="nutritional-column-preview-nutrition-block-calcium-data" className="data">
                {nutritionalInfo.nutritions?.value?.calcium}
                {nutritionalInfo.nutritions?.value?.calciumUOM}
              </span>
            </div>

            <div id="nutritional-column-preview-nutrition-block-calcium-daily-value" className="dv-data ">
              {getDvValue(nutritionalInfo?.nutritions?.value?.calciumDV)}%
            </div>
          </div>
          <div id="nutritional-column-preview-nutrition-block-iron" className="nutrition-row divider-bottom">
            <div id="nutritional-column-preview-nutrition-block-iron-label" className="label">
              Iron / Fer
              <span id="nutritional-column-preview-nutrition-block-iron-data" className="data">
                {nutritionalInfo.nutritions?.value?.iron}
                {nutritionalInfo.nutritions?.value?.ironUOM}
              </span>
            </div>

            <div id="nutritional-column-preview-nutrition-block-iron-daily-value" className="dv-data ">
              {getDvValue(nutritionalInfo?.nutritions?.value?.ironDV)}%
            </div>
          </div>
          <div id="nutritional-column-preview-nutrition-block-dv-note" className="nutrition-row dv-note">
            *5% or less is <span> a little</span>, 15% or more is <span> a lot</span>
            <div id="nutritional-column-preview-nutrition-block-dv-note-french" className="french">
              *5% ou moins c'est un <span>peu</span>, 15% ou plus c'est <span>beaucoup</span>
            </div>
          </div>
        </div>
      ) : null}

      {nutritionalInfo?.ingredients && (
        <div id="nutritional-column-preview-ingredients" className="ingredients">
          <div id="nutritional-column-preview-ingredients-row" className="info-row">
            <div id="nutritional-column-preview-ingredients-row-datablock" className="data-block ">
              <div id="nutritional-column-preview-ingredients-row-datablock-label" className="label">
                {t('nutritionalColumn.ingredients')}
              </div>
              <div id="nutritional-column-preview-ingredients-row-datablock-data" className="data description">
                {nutritionalInfo.ingredients}
              </div>
            </div>
          </div>
        </div>
      )}
      {attributeImages?.length > 0 || certifications?.length > 0 ? (
        <div id="nutritional-column-preview-attributeimages-preview-certifications" className="certifications">
          <div id="nutritional-column-preview-attributeimages-preview-certifications-row" className="info-row">
            <div
              id="nutritional-column-preview-attributeimages-preview-certifications-row-datablock"
              className="data-block no-highlight"
            >
              <div
                id="nutritional-column-preview-attributeimages-preview-certifications-row-datablock-label"
                className="label"
              >
                {t('nutritionalColumn.certifications')}
              </div>
              {attributeImages?.length > 0 && (
                <ul
                  id="nutritional-column-preview-attributeimages-preview-certifications-row-datablock-attributeimages"
                  className="logos"
                >
                  {attributeImages}
                </ul>
              )}
              {certifications?.length > 0 && (
                <ul id="nutritional-column-preview-attributeimages-preview-certifications-row-datablock-preview-certifications">
                  {certifications}
                </ul>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default NutritionalColumnPreview;
