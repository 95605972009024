import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import { Button, DatePicker, Modal, Checkbox } from 'antd';
import { downloadActivityLog } from '../../actions';
import moment from 'moment';

export function getAttributeTypes(){
  return {
    ABOUT_BRAND: i18n.t('infoColumn.aboutTheBrand'),
    RECIPES: i18n.t('mediaWidgetColumn.additionalResources'),
    CERTIFICATION: i18n.t('nutritionalColumn.certifications'),
    SPECIFICATION_SHEET: i18n.t('mediaWidgetColumn.specificationSheet'),
    MSDS_SHEET: i18n.t('mediaWidgetColumn.msdsSheet'),
    LIFESTYLE_IMAGE: i18n.t('activityLog.lifestyleImage'),
    PRODUCT_IMAGE: i18n.t('activityLog.productImage'),
    GS1_IMAGE: i18n.t('activityLog.gs1Image'),
    VIDEO: i18n.t('activityLog.video'),
    UNABBREVIATED_DESCRIPTION: i18n.t('fileImport.columnDescription'),
    ITEM_OVERVIEW: i18n.t('fileImport.itemOverview'),
    PRODUCT_DESCRIPTOR: i18n.t('activityLog.productDescriptor'),
    PACKAGING_INFORMATION: i18n.t('activityLog.packagingInformation'),
    SIZE_AND_SHAPE_OF_PRODUCT: i18n.t('activityLog.sizeAndShapeOfProduct'),
    YIELD_OR_SERVING_SIZE: i18n.t('activityLog.yieldOrServingSize'),
    QUALITY_AND_FORMAT: i18n.t('activityLog.qualityAndFormat'),
    PREPARATION_AND_COOKING_INSTRUCTIONS: i18n.t('activityLog.preparationAndCookingInstructions'),
    STORAGE_AND_USAGE: i18n.t('activityLog.storageAndUsage'),
    HANDLING_INSTRUCTIONS: i18n.t('activityLog.handlingInstructions'),
    ADDITIONAL_PRODUCT_INFORMATION: i18n.t('activityLog.additionalProductInformation'),
    MARKETING_STATEMENTS: i18n.t('activityLog.marketingStatements'),
    CULINARY_APPLICATIONS: i18n.t('activityLog.culinaryApplications')
  };
}

export function ActivityLogDownloadModal({ visible, setVisible}){
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [types, setTypes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  function onChange(checkedValues) {
    setTypes(checkedValues);
  }

  const labels = Object.values(getAttributeTypes());
  const values = Object.keys(getAttributeTypes());
  const options = [];
  let i;
  for (i = 0; i < labels.length; i++) {
    options[i] = { label: labels[i], value: values[i] };
  }

  function onChangeStart(value) {
    setSelectedDate(moment(value));
    var date = `${moment(value).format("YYYY-MM-DD")} 00:00:00`;  
    setStartDate(date);
  }

  function onChangeEnd(value) {
    var date = `${moment(value).format("YYYY-MM-DD")} 23:59:59`;
    setEndDate(date);
  }

  function disabledDate(date) {  
    return date < moment(selectedDate).endOf('day') || date > moment(selectedDate).add(92, 'day');
  }

  function info() {
    Modal.confirm({
      centered: true,
      closable: true,
      cancelText: i18n.t('infoColumn.cancel'),
      content: (
        <div id="activity-log-download-modal-info" className="activity-log-download-info">
          {i18n.t('activityLog.downloadInfoModal')}
        </div>
      ),
      onOk() {download()},
      onCancel() {setVisible(true)},
    });
  }

  const download = () => {
      setVisible(false);
      dispatch(
        downloadActivityLog(startDate, endDate, types)
      );
  };

  return (
    <Modal
      id="activity-log-download-modal"
      className="item-popup"
      visible={visible}
      destroyOnClose={false}
      width="830px"
      onCancel={() => setVisible(false)}
      title={[
        <div id="activity-log-download-modal-header" className="header activity-log-download-main-title" key="activity-log-download-modal-header">
          <i className="icon fi flaticon-history" />
          <div id="activity-log-download-title" className="title" key="activity-log-download-title">
            {i18n.t('header.downloadActivityLog')}
          </div>
        </div>,
      ]}
      footer={[
        <Button
          id="activity-log-download-back"
          key="activity-log-download-back"
          onClick={() => setVisible(false)}
        >
          {i18n.t('infoColumn.cancel')}
        </Button>,
        <Button
          id="activity-log-download-submit"
          key="activity-log-download-submit"
          type="primary"
          disabled={types?.length === 0 || endDate?.length === 0}
          onClick={() => {
            info();
          }}
        >
          {i18n.t('activityLog.download')}
        </Button>,
      ]}
    >
      <div id="activity-log-download-time-picker-title" className="activity-log-title ">
        {i18n.t('activityColumn.approvedDate')} <span className='activity-log-attribute-title-asterisk'>*</span>
      </div>
      <div>
        <DatePicker
          id="activity-log-download-time-picker-start"
          className='date-range-picker-left'
          format="YYYY-MM-DD"
          placeholder={i18n.t('activityLog.startTime')}
          onChange={onChangeStart}
          onOk={onChangeStart}
          showToday={false}
        />
        <DatePicker
          id="activity-log-download-time-picker-end"
          className='date-range-picker-right'
          disabledDate={disabledDate}
          disabled={startDate?.length === 0}
          format="YYYY-MM-DD"
          placeholder={ i18n.t('activityLog.endTime')}
          onOk={onChangeEnd}
          onChange={onChangeEnd}
          showToday={false}
        />
      </div>
      <div id="activity-log-download-attribute-types-title" className="activity-log-attribute-title">
        {i18n.t('activityColumn.attributeType')}<span className='activity-log-attribute-title-asterisk'> *</span>
      </div>
      <div id="activity-log-download-attribute-types" className='download-activity-log-wrap'>
        <Checkbox.Group options={options} className="download-activity-log-checkbox" onChange={onChange} />
      </div>
      <div id="activity-log-download-info" className="note">
        <i className="icon fi flaticon-information-button" /> {i18n.t('activityLog.downloadInformation')}
      </div>
    </Modal>
  );
}

export default ActivityLogDownloadModal;
  
  