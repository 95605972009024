import React, { useState } from 'react';

export default function ReadMoreReadLess({ fullText, charLimit }) {
  let [overviewTextClipped, setoverviewTextClipped] = useState(fullText?.length > charLimit);

  const toggleoverviewTextClipped = () => {
    setoverviewTextClipped(!overviewTextClipped);
  };

  return (
    <div id="item-overview-block" className="overview">
      {overviewTextClipped ? (
        <div id="item-overview-clipped" className="clipped-text">
          {fullText?.substring(0, charLimit)}...{' '}
          <a id="item-overview-expand" onClick={toggleoverviewTextClipped}>
            READ MORE
          </a>
        </div>
      ) : (
        <div id="item-overview-whole" className="full-text">
          {fullText}
          {fullText?.length > charLimit && (
            <a id="item-overview-shrink" onClick={toggleoverviewTextClipped}>
              SEE LESS
            </a>
          )}
        </div>
      )}
    </div>
  );
}
