import React from 'react';
import i18n from 'i18next';

function MainImagePreview({ showPreview, isVideoPreview, videoSrc, mainImage, togglePreview }) {
  return (
    showPreview && (
      <div id="media-column-images-toggle" className="preview" onClick={togglePreview}>
        <div id="media-column-images-toggle-preview" className="modal">
          {isVideoPreview ? (
            <video
              id="media-column-images-video-preview"
              width="320"
              height="240"
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              controlsList="nodownload"
              controls
            >
              <source id="media-column-images-video-preview-source" src={videoSrc} type="video/mp4" />
              {i18n.t('mediaColumn.browserMessage')}
            </video>
          ) : (
            <img id="media-column-images-main" src={mainImage} />
          )}
        </div>
      </div>
    )
  );
}

export default MainImagePreview;
