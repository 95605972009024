import React, { useEffect } from 'react';
import MediaColumn from './components/MediaColumn/MediaColumn';
import MediaColumnMobile from './components/MediaColumn/MediaColumnMobile';
import InfoColumn from './components/InfoColumn/infoColumn';
import NutritionalColumn from './components/NutritionalColumn/NutritionalColumn';
import { useDispatch, useSelector } from 'react-redux';
import AppLoader from '../components/AppLoader';
import { vendorScore } from '../actions';
import i18n from '../_i18n';
import { VENDOR } from '../enums/UserType';
import attributeManagement from 'utils/attributeManagement';

function ProductSheet({ vendorId }) {
  const dispatch = useDispatch();
  const { data: itemData, fetching: itemFetching } = useSelector((state) => state.item);
  const { fetching: approvalFetching } = useSelector((state) => state.approveAttributes);
  const image = useSelector((state) => state.currentImage);
  const {
    data: { userRole },
  } = useSelector((state) => state.userData);

  useEffect(() => {
    if (userRole === VENDOR) {
      dispatch(vendorScore(vendorId, i18n.language));
    }
  }, [itemData]);

  const permissions = attributeManagement(userRole, itemData?.enrichable);

  return itemFetching || approvalFetching ? (
    <AppLoader show />
  ) : (
    <>
      <div id="product-sheet-container" className="wrapper product-sheet-wrapper">
        <MediaColumn
          media={itemData.media}
          mediaInfo={itemData.mediaInfo}
          supc={itemData?.coreData?.supc}
          currentImage={image}
          permissions={permissions}
        />
        <InfoColumn coreData={itemData.coreData} 
        flags={itemData.flags} 
        coreDataErrors={itemData?.coreDataErrors} 
        permissions={permissions}/>
        <NutritionalColumn
          nutritionalInfo={itemData.nutritionalInformation}
          supc={itemData?.coreData?.supc}
          coreData={itemData?.coreData}
          permissions={permissions}
        />
        <MediaColumnMobile 
        mediaInfo={itemData.mediaInfo} 
        supc={itemData?.coreData?.supc} 
        permissions={permissions}/>
      </div>
    </>
  );
}

export default ProductSheet;
