import React from 'react';
import EnrichmentHeader from '../components/EnrichmentHeader';
import ProductSheet from '../productSheet/ProductSheet';

export default function ProductSheetEditor({ setItemOpen, vendorId }) {
  return (
    <div id="product-sheet" className="product-sheet">
      <EnrichmentHeader setItemOpen={setItemOpen} />
      <div id="product-sheet-item-info" className="editor product-sheet-wrapper">
        <ProductSheet vendorId={vendorId} />
      </div>
      {/* <CompletedItemOverlay /> */}
    </div>
  );
}
