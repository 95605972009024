import React from 'react';
import { useTranslation } from 'react-i18next';
import { RECIPES } from 'enums/AttributeType';
import { Icon, Button, Tooltip } from 'antd';
import i18n from '../../../_i18n';
import { useSelector } from 'react-redux';
import {
  PENDING,
  REJECTED,
  APPROVED,
  DELETE_APPROVED,
  DELETE_PENDING,
  DELETE_REJECTED,
} from '../../../enums/ApprovalStatus';

function checkPendingStatus(recipe, attributeManagement) {
  return (
    (recipe?.attributeStatus === PENDING || recipe?.attributeStatus === DELETE_PENDING) &&
    attributeManagement?.RECIPES?.approvable
  );
}

function checkSkippingPendingStatus(recipe, attributeManagement) {
  return (
    (recipe?.attributeStatus === PENDING || recipe?.attributeStatus === DELETE_PENDING) &&
    !attributeManagement?.RECIPES?.approvable
  );
}

function checkApprovedStatus(recipe, userType) {
  return (
    recipe?.authorRole === userType &&
    (recipe.attributeStatus === APPROVED || recipe.attributeStatus === DELETE_APPROVED)
  );
}

function checkRejectedStatus(recipe, userType) {
  return (
    recipe?.authorRole === userType &&
    (recipe.attributeStatus === REJECTED || recipe.attributeStatus === DELETE_REJECTED)
  );
}

function checkNotRejectedStatus(recipe, userType) {
  return (
    (recipe?.authorRole === userType ||
      recipe?.attributeStatus === PENDING ||
      recipe?.attributeStatus === DELETE_PENDING) &&
    recipe.attributeStatus !== REJECTED &&
    recipe.attributeStatus !== DELETE_REJECTED &&
    recipe.attributeStatus !== null
  );
}

function checkfileDelete(recipe, attributeManagement) {
  return (
    attributeManagement.RECIPES.editable &&
    !(
      recipe.attributeStatus === DELETE_PENDING ||
      recipe.attributeStatus === DELETE_APPROVED ||
      recipe?.attributeStatus === REJECTED
    )
  );
}

function showLabel(recipe, status, labelIfTrue, labelIfFalse) {
  return recipe?.attributeStatus === status ? i18n.t(labelIfTrue) : i18n.t(labelIfFalse);
}

function getRecipesStatus(recipe, attributeManagement) {
  if (recipe?.attributeStatus === PENDING) {
    return null;
  } else if (attributeManagement?.RECIPES?.approvable) {
    return DELETE_APPROVED;
  } else {
    return DELETE_PENDING;
  }
}

function Recipes({
  id,
  mediaInfo,
  attributeManagement,
  isValidToDisplay,
  fileCSSApplier,
  completeApprovingPendingFile,
  setRejectModalVisibility,
  setRecipeUnderEdit,
  handleSave,
  handleFileDelete,
  attributeEditorDetails,
  setShowRecipeUpload,
}) {
  const { t } = useTranslation();
  const {
    data: { userRole: userType },
  } = useSelector((state) => state.userData);

  return (
    <div id={`common-media-widgets-recipes-info-row-${id}`} className="info-row">
      <div id={`common-media-widgets-recipes-info-row-editable-${id}`} className="data-block editable no-highlight">
        <div id={`common-media-widgets-recipes-info-row-editable-label-${id}`} className="label">
          {t('mediaWidgetColumn.additionalResources')}
          {attributeManagement.RECIPES.editable && (
            <i
              id={`common-media-widgets-recipes-info-row-editable-add-${id}`}
              className="icon add-data-block fi flaticon-add-button-inside-black-circle"
              onClick={() => setShowRecipeUpload(true)}
            />
          )}
        </div>
        {mediaInfo?.recipes?.filter((recipe) => isValidToDisplay(recipe, userType))?.length > 0
          ? mediaInfo?.recipes
              ?.filter((recipe) => isValidToDisplay(recipe, userType))
              ?.filter((recipe) => recipe?.value?.recipeURL)
              ?.map((recipe, i) => (
                <div
                  id={`common-media-widgets-recipes-status-${i + 1}-${id}`}
                  key={`common-media-widgets-recipes-status-${recipe?.value?.recipeURL}`}
                  className={fileCSSApplier(recipe?.attributeStatus, recipe?.authorRole === userType)}
                >
                  {checkPendingStatus(recipe, attributeManagement) && (
                    <div id={`common-media-widgets-recipes-label-${i + 1}-${id}`} className="file-label-header">
                      <Icon
                        id={`common-media-widgets-recipes-warning-icon-${i + 1}-${id}`}
                        type="warning"
                        theme="filled"
                        className="status-icon warning"
                      />
                      <div id={`common-media-widgets-recipes-status-label-${i + 1}-${id}`} className="label">
                        {showLabel(
                          recipe,
                          DELETE_PENDING,
                          'mediaColumn.pendingApprovalDelete',
                          'mediaColumn.pendingApproval'
                        )}
                      </div>
                      <div
                        id={`common-media-widgets-recipes-label-actions-${i + 1}-${id}`}
                        className="label-file-actions"
                      >
                        <Button
                          id={`common-media-widgets-recipes-label-actions-approve-${i + 1}-${id}`}
                          className="btn green-action-btn pure empty-btn"
                          onClick={() => {
                            completeApprovingPendingFile(recipe);
                          }}
                        >
                          <Icon
                            id={`common-media-widgets-recipes-label-actions-approve-icon-${i + 1}-${id}`}
                            type="check"
                          />
                        </Button>
                        <Button
                          id={`common-media-widgets-recipes-label-actions-reject-${i + 1}-${id}`}
                          className="btn red-action-btn pure empty-btn"
                          onClick={() => {
                            setRejectModalVisibility(true);
                            setRecipeUnderEdit(recipe);
                          }}
                        >
                          <Icon
                            id={`common-media-widgets-recipes-label-actions-reject-icon-${i + 1}-${id}`}
                            type="close"
                          />
                        </Button>
                      </div>
                    </div>
                  )}
                  {checkSkippingPendingStatus(recipe, attributeManagement) && (
                    <div id={`common-media-widgets-recipes-label-pending-${i + 1}-${id}`} className="file-label-header">
                      <Icon
                        id={`common-media-widgets-recipes-label-pending-icon-${i + 1}-${id}`}
                        type="warning"
                        theme="filled"
                        className="status-icon warning"
                      />
                      <div id={`common-media-widgets-recipes-pending-label-${i + 1}-${id}`} className="label">
                        {showLabel(
                          recipe,
                          DELETE_PENDING,
                          'mediaColumn.pendingApprovalDelete',
                          'mediaColumn.pendingApproval'
                        )}
                      </div>
                    </div>
                  )}
                  {checkApprovedStatus(recipe, userType) && (
                    <div
                      id={`common-media-widgets-recipes-label-approved-${i + 1}-${id}`}
                      className="file-label-header"
                    >
                      <Icon
                        id={`common-media-widgets-recipes-label-approved-icon-${i + 1}-${id}`}
                        type="checked"
                        theme="filled"
                        className="status-icon approved"
                      />
                      <div id={`common-media-widgets-recipes-label-approved-label-${i + 1}-${id}`} className="label">
                        {showLabel(recipe, DELETE_APPROVED, 'mediaColumn.approvedDelete', 'mediaColumn.approved')}
                      </div>
                      <Button
                        id={`common-media-widgets-recipes-label-approved-dismiss-${i + 1}-${id}`}
                        className="btn btn-reject"
                        onClick={() => handleSave(RECIPES, recipe, null, null, recipe?.value?.recipeURL)}
                      >
                        {i18n.t('infoColumn.dismiss')}
                      </Button>
                    </div>
                  )}
                  {checkRejectedStatus(recipe, userType) && (
                    <div
                      id={`common-media-widgets-recipes-label-rejected-${i + 1}-${id}`}
                      className="file-label-header"
                    >
                      <Icon
                        id={`common-media-widgets-recipes-label-rejected-icon-${i + 1}-${id}`}
                        type="cross"
                        theme="filled"
                        className="status-icon rejected"
                      />
                      <div id={`common-media-widgets-recipes-label-rejected-label-${i + 1}-${id}`} className="label">
                        {showLabel(recipe, DELETE_REJECTED, 'mediaColumn.rejectedDelete', 'mediaColumn.rejected')}
                      </div>
                      <div
                        id={`common-media-widgets-recipes-label-rejected-actions-${i + 1}-${id}`}
                        className="label-file-actions"
                      >
                        <Button
                          id={`common-media-widgets-recipes-label-rejected-dismiss-${i + 1}-${id}`}
                          className="btn btn-reject"
                          onClick={() => handleSave(RECIPES, recipe, null, null, recipe?.value?.recipeURL)}
                        >
                          {i18n.t('infoColumn.dismiss')}{' '}
                        </Button>
                      </div>
                    </div>
                  )}
                  <div id={`common-media-widgets-recipes-inner-file-info-${i + 1}-${id}`} className="inner-file-info">
                    <a
                      id={`common-media-widgets-recipes-inner-file-info-url-${i + 1}-${id}`}
                      className="icon"
                      href={recipe?.value?.recipeURL}
                      target="_blank"
                    />
                    <div id={`common-media-widgets-recipes-inner-file-info-data-${i + 1}-${id}`} className="info">
                      <div
                        id={`common-media-widgets-recipes-inner-file-info-data-filename-${i + 1}-${id}`}
                        className="filename"
                      >
                        <a
                          id={`common-media-widgets-recipes-inner-file-info-data-filename-url-${i + 1}-${id}`}
                          href={recipe?.value?.recipeURL}
                          target="_blank"
                        >
                          {recipe?.value?.recipeName}
                        </a>
                        {checkfileDelete(recipe, attributeManagement) && (
                          <i
                            id={`common-media-widgets-recipes-inner-file-info-data-delete-${i + 1}-${id}`}
                            className="delete-icon fi flaticon-delete"
                            onClick={() =>
                              handleFileDelete(
                                recipe?.value?.recipeName,
                                RECIPES,
                                recipe,
                                mediaInfo?.recipes,
                                getRecipesStatus(recipe, attributeManagement),
                                recipe?.value?.recipeURL
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                    {checkRejectedStatus(recipe, userType) && (
                      <Tooltip
                        id={`common-media-widgets-recipes-inner-file-info-tooltip-${i + 1}-${id}`}
                        overlayStyle={{ whiteSpace: 'pre-line' }}
                        title={
                          attributeEditorDetails(userType, recipe.attributeStatus, recipe) +
                          '\n' +
                          i18n.t('infoColumn.reason') +
                          ': ' +
                          recipe?.comment
                        }
                      >
                        <i
                          id={`common-media-widgets-recipes-inner-file-info-tooltip-icon-${i + 1}-${id}`}
                          className="info-icon fi flaticon-information-button"
                        />
                      </Tooltip>
                    )}
                    {checkNotRejectedStatus(recipe, userType) && (
                      <Tooltip
                        id={`common-media-widgets-recipes-inner-file-info-not-rejected-tooltip-icon-${i + 1}-${id}`}
                        title={attributeEditorDetails(userType, recipe.attributeStatus, recipe)}
                      >
                        <i className="info-icon fi flaticon-information-button" />
                      </Tooltip>
                    )}
                  </div>
                </div>
              ))
          : null}
      </div>
    </div>
  );
}

export default Recipes;
