import React from 'react';

function Fab({ feature, id, attribute }) {
  const preparedFeature = () => {
    return feature?.trim();
  };
  return (
    <li
      id={`info-column-${id}-row-edit-description-list-item-${preparedFeature()}`}
      key={`info-column-${id}-row-edit-description-list-item-${preparedFeature()}`}
      className={`data description ${attribute?.identifier === 'gs1' ? 'gs1-font' : ''}`}
    >
      {preparedFeature()}
    </li>
  );
}

export default Fab;
