import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { Input, Pagination, Select, Table } from 'antd';
import { loadItemCatalog, loadStagingItem } from 'actions';
import { useTranslation } from 'react-i18next';
import i18n from '../_i18n';
import AppLoader from './AppLoader';
import MissingInfoPlaceholder from './MissingInfoPlaceholder';
import { VENDOR } from '../enums/UserType';
import { isValidToDisplay } from '../utils/Utils';
import { DELETE_APPROVED } from 'enums/ApprovalStatus';
import { v4 as uuidv4 } from 'uuid';
const { Option } = Select;
const { Search } = Input;

function getSortKey(sortOptions) {
  return sortOptions[0] ? sortOptions[0].sortKey : null;
}

const convertTimeZone = (date, timezone) => {
  return new Date(date).toLocaleString('sv-SE', { timeZone: timezone });
};

const changePage = (page, setPage) => {
  setPage(page > 0 ? page - 1 : page);
};

function getValidThumbnail(item, userRole) {
  if (isValidToDisplay(item?.lifeStyleImage, userRole)) return item?.thumbnailImage;

  return item?.lifeStyleImage?.attributeStatus === DELETE_APPROVED ? '' : item?.lifeStyleImage?.previousValue?.url;
}

function generateItemList(result, data, setIsDateInvalid, userRole) {
  let y = 0;
  if (result?.length > 0) {
    if (convertTimeZone(`${result[0]?.lastEdited} MST`, data?.timeZone) !== 'Invalid Date') {
      setIsDateInvalid(true);
    }
    return result.map((item) => {
      y += 1;
      return {
        key: y,
        image: getValidThumbnail(item, userRole),
        item: item,
        category: item?.category,
        vendor: item?.vendor,
        brand: item?.brand,
        pack: item?.pack,
        size: item?.itemSize,
        supc: item?.supc,
        //mfgnum: item?.mfgProduct,
        gtin: item?.gtin,
        lastedited:
          data?.timeZone && convertTimeZone(`${item?.lastEdited} MST`, data?.timeZone) !== 'Invalid Date'
            ? convertTimeZone(`${item?.lastEdited} MST`, data?.timeZone)
            : item?.lastEdited,
        description: item?.description,
      };
    });
  } else {
    return [];
  }
}

function getSortOptions(userRole) {
  return userRole === VENDOR
    ? [
        { sortKey: null, value: i18n.t('itemList.all') },
        { sortKey: 'DESCRIPTION', value: i18n.t('itemList.description') },
      ]
    : [
        { sortKey: null, value: i18n.t('itemList.all') },
        { sortKey: 'DESCRIPTION', value: i18n.t('itemList.description') },
        { sortKey: 'VENDOR', value: i18n.t('itemList.vendor') },
      ];
}

function generateStretchedColums(data, isDateInvalid) {
  return [
    {
      // title: 'Image',
      dataIndex: 'image',
      width: '5.5rem',
      // fixed: 'left',
      render: (text) => (
        <>
          {text ? (
            <div
              id={`item-list-streched-column-thumbnail-1-${text}`}
              className="item-thumb"
              style={{ backgroundImage: 'url(' + text + '?key=' + uuidv4() + ')' }}
            />
          ) : (
            <div id={`item-list-streched-column-thumbnail-2-${text}`} className="item-thumb" />
          )}
        </>
      ),
      // fixed: 'left',
    },
    {
      title: 'SUPC',
      dataIndex: 'supc',
      className: 'supc-col',
      width: '5.5rem',
      // fixed: 'left',
    },
    {
      title: i18n.t('itemList.description'),
      dataIndex: 'description',
      className: 'desc-col',
      width: '15rem',
      // fixed: 'left',
      render: (description) => (
        <>
          {description ? (
            <div id={`item-list-streched-column-description-${description}`} className="smart-description">
              <div id={`item-list-streched-column-description-label-${description}`} className="label">
                {description}
              </div>
            </div>
          ) : (
            <MissingInfoPlaceholder id="item-list-strched-column-description-missing-placeholder" show />
          )}
        </>
      ),
    },
    {
      title: i18n.t('infoColumn.category'),
      dataIndex: 'category',
      ellipsis: true,
      className: 'category-col',
      width: '12rem',
      render: (text) => (
        <>
          {text ? (
            <span id={`item-list-streched-column-category-${text}`}>{text}</span>
          ) : (
            <MissingInfoPlaceholder id="item-list-streched-column-category-missing-placeholder" show />
          )}
        </>
      ),
    },
    {
      title: i18n.t('itemList.vendor'),
      dataIndex: 'vendor',
      className: 'vendor-col',
      width: '15rem',
      render: (text) => (
        <>
          {text ? (
            <span id={`item-list-streched-column-vendor-${text}`}>{text}</span>
          ) : (
            <MissingInfoPlaceholder id="item-list-streched-column-vendor-missing-placeholder" show />
          )}
        </>
      ),
    },
    {
      title: i18n.t('itemList.brand'),
      dataIndex: 'brand',
      className: 'brand-col',
      width: '12rem',
    },
    {
      title: i18n.t('itemList.pack'),
      dataIndex: 'pack',
      className: 'pack-col',
      width: '7rem',
    },
    {
      title: i18n.t('itemList.itemSize'),
      dataIndex: 'size',
      className: 'size-col',
      width: '8rem',
    },
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      className: 'gtin-col',
      width: '12rem',
    },
    {
      title: `${i18n.t('itemList.lastEdited')} (${data?.timeZone && isDateInvalid ? data.timeZone : 'MST'})`,
      dataIndex: 'lastedited',
      className: 'lastedited-col',
      width: '12rem',
    },
  ];
}

function ItemList({
  setItemOpen,
  vendorNos,
  classNames,
  status,
  approvalTypes,
  enrichmentLevel,
  brandType,
  proprietaryItem,
  itemOpen,
  approvals,
  vendorId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDateInvalid, setIsDateInvalid] = useState(false);

  const {
    data: { totalItems, content: result },
    fetching,
  } = useSelector((state) => state.itemCatalog);
  const {
    data: { userRole },
  } = useSelector((state) => state.userData);
  const { data } = useSelector((state) => state.userData);
  const { fetching: updateItemFetching } = useSelector((state) => state.updateItem);

  const sortOptions = getSortOptions(userRole);

  const [sortKey, setSortKey] = useState(getSortKey(sortOptions));
  const [page, setPage] = useState(0);
  const [pageSize] = useState(10);
  const [searchKey, setSearchKey] = useState(null);
  const [itemList, setItemList] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [gridScrollHeight, setGridScrollHeight] = useState('60vh');
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState({
    saItemFilter: {
      vendors: [],
      categoryCodes: [],
      itemStatus: [],
      syscoBrandType: [],
    },
    vendorItemFilter: {
      itemStatus: [],
      approvals: [],
      enrichmentLevels: [],
      syscoBrandType: [],
      proprietaryType: [],
    },
  });

  const onResize = (width, height) => {
    setGridScrollHeight(height - 180);
  };

  useEffect(() => {
    let updatedFilter = { ...filter };
    updatedFilter.saItemFilter.vendors = vendorNos;
    updatedFilter.saItemFilter.categoryCodes = classNames;
    updatedFilter.saItemFilter.itemStatus = status;
    updatedFilter.saItemFilter.syscoBrandType = brandType;
    updatedFilter.vendorItemFilter.approvals = approvalTypes;
    updatedFilter.vendorItemFilter.enrichmentLevels = enrichmentLevel;
    updatedFilter.vendorItemFilter.itemStatus = status;
    updatedFilter.vendorItemFilter.syscoBrandType = brandType;
    updatedFilter.vendorItemFilter.proprietaryType = proprietaryItem;
    setFilter(updatedFilter);

    dispatch(
      loadItemCatalog(i18n.language, sortKey, vendorId, pageSize, page, searchKey, updatedFilter, approvals, userRole)
    );
  }, [
    sortKey,
    i18n.language,
    searchKey,
    page,
    vendorNos,
    classNames,
    status,
    approvals,
    approvalTypes,
    enrichmentLevel,
    brandType,
    proprietaryItem,
    vendorId,
    userRole,
  ]);

  useEffect(() => {
    let updatedFilter = { ...filter };
    updatedFilter.saItemFilter.vendors = [];
    updatedFilter.saItemFilter.categoryCodes = [];
    updatedFilter.saItemFilter.itemStatus = null;
    updatedFilter.vendorItemFilter.approvals = null;
    updatedFilter.vendorItemFilter.enrichmentLevels = null;
    updatedFilter.vendorItemFilter.itemStatus = null;
    setFilter(updatedFilter);
    setSearchValue(null);
    setSearchKey(null);

    dispatch(loadItemCatalog(i18n.language, null, vendorId, pageSize, page, null, updatedFilter, approvals, userRole));
  }, [i18n.language]);

  console.log('result: ', result);

  useEffect(() => {
    setItemList(generateItemList(result, data, setIsDateInvalid, userRole));
    if (refresh === true) {
      setRefresh(false);
    } else {
      setItemOpen(false);
    }
    // Find a better solution to this
    if (totalItems / pageSize < page) {
      setPage(0);
    }
  }, [result]);

  useEffect(() => {
    if (updateItemFetching === false && refresh === false) {
      setRefresh(true);
      dispatch(
        loadItemCatalog(i18n.language, sortKey, vendorId, pageSize, page, searchKey, filter, approvals, userRole)
      );
    }
  }, [updateItemFetching]);

  const getColumns = () => {
    return itemOpen ? columns : stretchedColumns;
  };

  const columns = [
    {
      dataIndex: 'image',
      width: '5.5rem',
      render: (text) => (
        <div
          id={text && `item-list-thumbnail-${text.split('/')[text.split('/').length - 1]}`}
          className="item-thumb"
          style={{ backgroundImage: 'url(' + text + '?key=' + uuidv4() + ')' }}
        />
      ),
    },
    {
      title: i18n.t('itemList.description'),
      dataIndex: 'item',
      width: 'auto',
      className: 'desc-col',
      render: (item) => (
        <div id={`item-list-item-${item?.supc}`} className="smart-description">
          <div id={`item-list-item-header-${item?.supc}`} className="desc-header">
            <div id={`item-list-item-supc-${item?.supc}`} className="supc">{`SUPC ${item?.supc}`}</div>
            <div id={`item-list-item-category-${item?.supc}`} className="category">
              {item?.category}
            </div>
          </div>
          <div id={`item-list-item-description-${item?.supc}`} className="label">
            {item?.description}
          </div>
          <div id={`item-list-item-footer-${item?.supc}`} className="desc-footer">
            <div id={`item-list-item-brand-${item?.supc}`} className="brand">
              {item?.brand}
            </div>
            {/*<Icon className="icon" type="warning" theme="filled" />*/}
          </div>
        </div>
      ),
    },
  ];

  const stretchedColumns = generateStretchedColums(data, isDateInvalid);

  return fetching ? (
    <AppLoader show />
  ) : (
    <ReactResizeDetector id="item-list-resize-detector" handleHeight onResize={onResize} targetRef={this}>
      <div id="item-list-resize-detector-list" className="item-list">
        <div id="item-list-resize-detector-attention-bar" className="attention-bar"></div>
        <div id="item-list-resize-detector-filter-bar" className="filter-bar">
          <Search
            id="item-list-resize-detector-filter-bar-search"
            className="search-item-list"
            placeholder={t('itemList.search')}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={(value) => setSearchKey(value)}
          />
          <div id="item-list-resize-detector-filter-bar-sort" className="sort-select-wrapper">
            <div id="item-list-resize-detector-filter-bar-sort-label" className="label">
              {t('itemList.sortBy')}
            </div>
            <Select
              id="item-list-resize-detector-filter-bar-sort-select"
              dropdownMatchSelectWidth={false}
              defaultValue={sortKey}
              className="sort-select"
            >
              {sortOptions.map((sort, index) => {
                return (
                  <Option
                    id={`item-list-resize-detector-filter-bar-sort-key${sort?.value}`}
                    key={`item-list-resize-detector-filter-bar-sort-key${sort?.value}`}
                    value={sort?.sortKey}
                    onClick={() => setSortKey(sort?.sortKey)}
                  >
                    {sort?.value}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>

        <div id="item-list-detector-table" className="item-list-table">
          <Table
            id="item-list-resize-detector-table"
            columns={getColumns()}
            dataSource={itemList}
            pagination={false}
            scroll={{ x: !itemOpen ? 'max-content' : 'auto', y: gridScrollHeight }}
            onRow={(record) => {
              return {
                onClick: (event) => {
                  dispatch(loadStagingItem(record.item.supc, i18n.language));
                  setItemOpen(true);
                }, // click row
              };
            }}
          />
          <Pagination
            id="item-list-resize-detector-table-pagination"
            // size="small"
            total={totalItems}
            current={page + 1}
            onChange={(changedPage) => changePage(changedPage, setPage)}
          />
        </div>
      </div>
    </ReactResizeDetector>
  );
}

export default ItemList;
