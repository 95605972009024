import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export function sheetVisibility(sheet, userType) {
  let visible = false;
  if (
    (sheet?.attributeStatus === 'REJECTED' && sheet?.authorRole === userType) ||
    sheet.attributeStatus !== 'REJECTED'
  ) {
    visible = true;
  }
  return visible;
}

function MsdsSheet({ mediaInfo, idMobile }) {
  const { t } = useTranslation();

  const {
    data: { userRole: userType },
  } = useSelector((state) => state?.userData);

  return (
    <div id={`common-media-widgets-preview-msdssheet-${idMobile}`} className="msds-sheets">
      {mediaInfo?.msdsSheet?.length > 0 ? (
        <div id={`common-media-widgets-preview-msdssheet-row-${idMobile}`} className="info-row">
          <div
            id={`common-media-widgets-preview-msdssheet-row-datablock-${idMobile}`}
            className="data-block no-highlight"
          >
            <div id={`common-media-widgets-preview-msdssheet-row-datablock-label-${idMobile}`} className="label">
              {t('mediaWidgetColumn.msdsSheet')}
            </div>
            <ul>
              {mediaInfo.msdsSheet
                ?.filter((sheet) => sheet?.value?.sheetURL && sheetVisibility(sheet, userType))
                ?.map((sheet, i) => (
                  <div
                    id={`common-media-widgets-preview-msdssheet-row-datablock-thumbnail-${i + 1}-${idMobile}`}
                    key={`common-media-widgets-preview-msdssheet-row-datablock-thumbnail-${sheet?.value?.sheetURL}`}
                    className="file-thumbnail"
                  >
                    <div
                      id={`common-media-widgets-preview-msdssheet-row-datablock-fileinfo-${i + 1}-${idMobile}`}
                      className="inner-file-info"
                    >
                      <a
                        id={`common-media-widgets-preview-msdssheet-row-datablock-icon-${i + 1}-${idMobile}`}
                        className="icon"
                        href={sheet?.value?.sheetURL}
                        target="_blank"
                      />
                      <div
                        id={`common-media-widgets-preview-msdssheet-row-datablock-info-${i + 1}-${idMobile}`}
                        className="info"
                      >
                        <div
                          id={`common-media-widgets-preview-msdssheet-row-datablock-filename-${i + 1}-${idMobile}`}
                          className="filename"
                        >
                          <a
                            id={`common-media-widgets-preview-msdssheet-row-datablock-fileurl-${i + 1}-${idMobile}`}
                            href={sheet?.value?.sheetURL}
                            target="_blank"
                          >
                            {sheet?.value?.sheetName}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </ul>
            </div>
          </div>
        ) : null}
    </div>
  );
}

export default MsdsSheet;
