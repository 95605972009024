import { action } from 'reduxHelpers';
import {
  ATTRIBUTES,
  CATEGORIES,
  DELETE_FILE,
  ITEM,
  ITEM_CATALOG,
  UPDATE_ATTRIBUTE,
  APPROVE_ATTRIBUTE,
  AUDITING_DETAILS,
  DOWNLOAD_AUDIT,
  DOWNLOAD_ITEMS,
  VENDOR,
  ITEM_APPROVAL_PENDING_VENDOR,
  BULK_UPLOAD,
  USER_DATA,
  CLASSES,
  VENDOR_SCORE,
  APPROVE_PENDING_FILE,
  REPORT_ERROR,
  UPDATED_ITEM,
} from './actionTypes';

const loadItemCatalog = (language, sort, vendorNo, size, page, searchKey, updatedFilter, actionRequired, userType) =>
  action(ITEM_CATALOG.REQUEST, {
    language,
    sort,
    vendorNo,
    size,
    page,
    searchKey,
    updatedFilter,
    actionRequired,
    userType,
  });
const loadVendors = (vendorName, page, size) => action(VENDOR.REQUEST, { vendorName, page, size });
const loadItemApprovalPendingVendors = (vendorName, page, size, language, userType) =>
  action(ITEM_APPROVAL_PENDING_VENDOR.REQUEST, { vendorName, page, size, language, userType });
const loadCategories = (language, page, size) => action(CATEGORIES.REQUEST, { language, page, size });
const loadClasses = (language, page, size) => action(CLASSES.REQUEST, { language, page, size });
const loadAttributes = (language, page, size) => action(ATTRIBUTES.REQUEST, { language, page, size });
const loadStagingItem = (supc, language) => action(ITEM.REQUEST, { supc, language });
const updateStagingAttribute = (supc, language, stagingAttribute, associatedUsers) =>
  action(UPDATE_ATTRIBUTE.REQUEST, { supc, language, stagingAttribute, associatedUsers });
const approvePendingAttributes = (supc, language, stagingAttribute, associatedUsers) =>
  action(APPROVE_ATTRIBUTE.REQUEST, { supc, language, stagingAttribute, associatedUsers });

const loadAuditingDetails = (supc, attributeType, attributeStatus) =>
  action(AUDITING_DETAILS.REQUEST, { supc, attributeType, attributeStatus });

const downloadActivityLog = (startDate, endDate, attributeTypes) =>
  action(DOWNLOAD_AUDIT.REQUEST, { startDate, endDate, attributeTypes });

const downloadItems = (vendorNo, totalItems, proprietary) =>
  action(DOWNLOAD_ITEMS.REQUEST, { vendorNo, totalItems, proprietary });

const approvePendingFile = (
  language,
  uploadType,
  supc,
  userId,
  fileName,
  copySource,
  destination,
  file,
  fileList,
  attributeStatus,
  identifier,
  userType,
  associatedUsers
) =>
  action(APPROVE_PENDING_FILE.REQUEST, {
    language,
    uploadType,
    supc,
    userId,
    fileName,
    copySource,
    destination,
    file,
    fileList,
    attributeStatus,
    identifier,
    userType,
    associatedUsers,
  });

const deleteFile = (fileName, uploadType, supc, userId, language, attributeStatus, file, userType, associatedUsers) =>
  action(DELETE_FILE.REQUEST, {
    fileName,
    uploadType,
    supc,
    userId,
    language,
    attributeStatus,
    file,
    userType,
    associatedUsers,
  });

const bulkUpload = (language, override, userType, uploadItems, vendorNo, associatedUsers) =>
  action(BULK_UPLOAD.REQUEST, {
    language,
    override,
    userType,
    uploadItems,
    vendorNo,
    associatedUsers,
  });

const userData = (username) => action(USER_DATA.REQUEST, { username });
const vendorScore = (vendorNumber, language) => action(VENDOR_SCORE.REQUEST, { vendorNumber, language });
const reportError = (supc, request) => action(REPORT_ERROR.REQUEST, { supc, request });
const updateItem = () => action(UPDATED_ITEM.REQUEST, {});

export {
  approvePendingFile,
  loadItemCatalog,
  loadStagingItem,
  updateStagingAttribute,
  approvePendingAttributes,
  loadAttributes,
  loadVendors,
  loadItemApprovalPendingVendors,
  loadCategories,
  loadAuditingDetails,
  downloadActivityLog,
  downloadItems,
  deleteFile,
  bulkUpload,
  userData,
  loadClasses,
  vendorScore,
  reportError,
  updateItem,
};
