import React, { useEffect, useState } from 'react';
import { APPROVED, PENDING, REJECTED } from '../../../enums/ApprovalStatus';
import { Button, Icon, Input, Tooltip } from 'antd';
import i18n from '../../../_i18n';
import MissingInfoPlaceholder from '../../../components/MissingInfoPlaceholder';
import { attributeEditorDetails, subFieldCommentSelector, subFieldNameSelector } from '../../../utils/Utils';
import RejectModal from '../../../components/modals/RejectModal';
import { useTranslation } from 'react-i18next';
import { PRODUCT_DESCRIPTOR } from 'enums/AttributeType';
import Fab from './FAB';

function getCurrentValue(attribute, userType) {
  return attribute?.attributeStatus === REJECTED && attribute?.authorRole !== userType
    ? attribute?.previousValue
    : attribute?.value;
}

function showHighlighted(attribute, userType) {
  return (
    attribute?.authorRole === userType &&
    (attribute?.attributeStatus === APPROVED || attribute?.attributeStatus === REJECTED)
  );
}

function checkEmptyFabs(attributeType, value) {
  return attributeType !== PRODUCT_DESCRIPTOR && (value === null || value === undefined || value === '');
}

function handleFabsSave(value, valueUnderEdit, handleSave, approvable, setValue, attributeType) {
  if (checkEmptyFabs(attributeType, value)){
    value = '• ';
  }
  if (value !== valueUnderEdit) {
    if (valueUnderEdit?.trim() === '•') {
      valueUnderEdit = '' ;
    }
    setValue(valueUnderEdit);
    handleSave(attributeType, valueUnderEdit, approvable ? APPROVED : PENDING);
  }
}

function handleFabsSaveEdit(attributeType, saveValue, status, handleSave, comment){
  if (saveValue?.trim() === '•') {
    saveValue = '' ;
  }
  handleSave(attributeType, saveValue, status, comment);
}

function getValueUnderEdit(value, attributeType) {
  if (checkEmptyFabs(attributeType, value)){
    return '• ';
  } else {
    return value;
  }  
}

function FABsField({
  attributeCSSApplier,
  coreData,
  userType,
  handleSave,
  attribute,
  attributeType,
  approvable,
  editable,
  valueList,
  id,
}) {
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [value, setValue] = useState(getCurrentValue(attribute, userType));
  const [valueUnderEdit, setValueUnderEdit] = useState(getValueUnderEdit(value, attributeType));
  const [valueEdit, setValueEdit] = useState(false);
  const [fabsRejectModal, setFabsRejectModal] = useState(false);

  const subField = subFieldNameSelector(attributeType);
  const subFieldComment = subFieldCommentSelector(attributeType);

  useEffect(() => {
    setValue(getCurrentValue(attribute, userType));
  }, [coreData]);

  return (
    <div
      id={`info-column-${id}`}
      style={{ paddingTop: 15 + 'px' }}
      className="info-row fandb-items-wrap fandb-row product-descriptor-text"
    >
      <div
        id={`info-column-${id}-status`}
        className={attributeCSSApplier(attribute?.attributeStatus, attribute?.authorRole === userType)}
      >
        {editable && (
          <div
            id={`info-column-${id}-edit`}
            className="icon edit-icon fi flaticon-edit"
            onClick={() => setValueEdit(true)}
          />
        )}

        {attribute?.authorRole === userType && attribute?.attributeStatus === APPROVED && (
          <Icon id={`info-column-${id}-approved-icon`} type="check" className="icon status-icon approved" />
        )}

        <div id={`info-column-${id}-label`} className="label">
          <span className="fnb-label">{subField}</span>

          <Tooltip
            id={`info-column-${id}-tooltip`}
            overlayStyle={{ whiteSpace: 'pre-line' }}
            title={subFieldComment}
            placement={'rightBottom'}
          >
            <i
              id={`info-column-${id}-tooltip-icon`}
              className="fi flaticon-information-button"
              style={{ marginLeft: 5 + 'px' }}
            />
          </Tooltip>

          {!approvable && attribute?.attributeStatus === PENDING && (
            <div id={`info-column-${id}-edits-pending`} className="label-actions">
              {i18n.t('infoColumn.editsPending')}
            </div>
          )}
          {approvable && attribute?.attributeStatus === PENDING && (
            <div id={`info-column-${id}-approvalble`} className="label-actions">
              <Button
                id={`info-column-${id}-approvalble-approve`}
                className="btn green-action-btn invert"
                onClick={() => {
                  if (attribute?.identifier === 'gs1') {
                    handleFabsSaveEdit(attributeType, '•', APPROVED, handleSave);
                  } else {
                    handleFabsSaveEdit(attributeType, valueUnderEdit, APPROVED, handleSave);
                  }
                }}
              >
                <Icon id={`info-column-${id}-approvalble-approve-icon`} type="check" /> {i18n.t('infoColumn.approve')}
              </Button>
              <Button
                id={`info-column-${id}-approvalble-reject`}
                className="btn red-action-btn invert"
                onClick={() => {
                  setFabsRejectModal(true);
                }}
              >
                <Icon id={`info-column-${id}-approvalble-reject-icon`} type="close" /> {i18n.t('infoColumn.reject')}
              </Button>
            </div>
          )}
          {showHighlighted(attribute, userType) && (
            <div id={`info-column-${id}-attribute-status`} className="label-actions">
              <div id={`info-column-${id}-attribute-status-label`} className="status-label">
                {attribute?.attributeStatus}
              </div>
              {/*{attribute?.authorRole === userType && (*/}
              <Button
                id={`info-column-${id}-attribute-status-dismiss`}
                className="btn"
                onClick={() => {
                  setValue(attribute?.previousValue);
                  setValueUnderEdit(attribute?.previousValue);
                  handleFabsSaveEdit(attributeType, value, null, handleSave);
                }}
              >
                {i18n.t('infoColumn.dismiss')}
              </Button>
            </div>
          )}
        </div>

        <div id={`info-column-${id}-data-description`} className={`data description ${!valueEdit ? '' : 'hide'}`}>
          <MissingInfoPlaceholder id={`info-column-${id}-data-description-missing-placeholder`} show={!value} />
          {attributeType === PRODUCT_DESCRIPTOR && (
            <div id={`info-column-${id}-data-description-button`} className={`overview fnb-content-font ${attribute?.identifier === 'gs1' ? 'gs1-font' : ''}`}>
              {value}
            </div>
          )}
          {valueList !== null && (
            <ul id={`info-column-${id}-row-edit-description-list`}>
              {valueList?.map((feature) => (
                <Fab feature={feature} id={id} key={feature} attribute={attribute}/>
              ))}
            </ul>
          )}
        </div>
        <div
          id={`info-column-${id}-data-description-edit`}
          className={`data-edit description ${valueEdit ? '' : 'hide'}`}
        >
          {attributeType === PRODUCT_DESCRIPTOR && (
            <TextArea
              id={`info-column-${id}-data-description-edit-textarea`}
              value={valueUnderEdit}
              rows={4}
              onChange={(e) => setValueUnderEdit(e.target.value)}
            />
          )}
          {attributeType !== PRODUCT_DESCRIPTOR && (
            <TextArea
              id={`info-column-${id}-data-description-edit-textarea`}
              value={valueUnderEdit}
              rows={4}
              onChange={(e) => {
                var val = e.target.value;
                if (val !== null && val.slice(0, 1) !== '•') {
                  val = '• '+ val ;
                }
                setValueUnderEdit(val?.replace('*', '•'));
              }}
              onPressEnter={(e) => {
                e.preventDefault();
                setValueUnderEdit(`${e.target.value}\n• `);
              }}
            />
          )}
        </div>
        {valueEdit && (
          <div id={`info-column-${id}-data-description-edit-textarea-footer`} className="footer-bar">
            <div id={`info-column-${id}-data-description-edit-textarea-footer-actions`} className="footer-actions">
              <Button
                id={`info-column-${id}-data-description-edit-textarea-footer-actions-save`}
                className="btn green-action-btn invert"
                onClick={() => {
                  handleFabsSave(value, valueUnderEdit, handleSave, approvable, setValue, attributeType);
                  setValueEdit(false);
                }}
              >
                <Icon id={`info-column-${id}-data-description-edit-textarea-footer-actions-save-icon`} type="check" />{' '}
                {t('infoColumn.save')}
              </Button>
              <Button
                id={`info-column-${id}-data-description-edit-textarea-footer-actions-cancel`}
                className="btn red-action-btn invert"
                onClick={() => {
                  setValueEdit(false);
                  setValueUnderEdit(getValueUnderEdit(value, attributeType));
                }}
              >
                <Icon id={`info-column-${id}-data-description-edit-textarea-footer-actions-cancel-icon`} type="close" />{' '}
                {t('infoColumn.cancel')}
              </Button>
            </div>
          </div>
        )}
        {(attribute?.attributeStatus === PENDING || attribute?.authorRole === userType) && (
          <div id={`info-column-${id}-data-footer-bar`} className="footer-bar">
            <div id={`info-column-${id}-data-footer-bar-edited-meta`} className="edited-meta">
              <div id={`info-column-${id}-data-footer-bar-edited-meta-data`}>
                {attributeEditorDetails(userType, attribute?.attributeStatus, attribute)}
              </div>
              {attribute?.attributeStatus === REJECTED && (
                <div id={`info-column-${id}-data-footer-bar-edited-meta-data-reason`} className="reason">
                  {i18n.t('infoColumn.reason')}: <span>{attribute?.comment}</span>
                </div>
              )}
            </div>
          </div>
        )}
        <RejectModal
          id={`info-column-${id}-reject-modal`}
          modalVisibility={fabsRejectModal}
          setRejectVisibility={setFabsRejectModal}
          rejectFunction={(comment) => {
            setValue(attribute?.previousValue);
            setValueUnderEdit(attribute?.previousValue);
            handleFabsSaveEdit(attributeType, value, REJECTED, handleSave, comment);
          }}
          rejectAttribute={subField}
        />
      </div>
    </div>
  );
}

export default FABsField;
