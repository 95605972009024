import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { deleteS3Request, getRequest, patchRequest, postRequest, postRequestCompressed } from '_http';

import {
  ATTRIBUTES,
  CATEGORIES,
  DELETE_FILE,
  ITEM,
  ITEM_CATALOG,
  UPDATE_ATTRIBUTE,
  APPROVE_ATTRIBUTE,
  AUDITING_DETAILS,
  DOWNLOAD_AUDIT,
  DOWNLOAD_ITEMS,
  VENDOR,
  ITEM_APPROVAL_PENDING_VENDOR,
  BULK_UPLOAD,
  USER_DATA,
  CLASSES,
  VENDOR_SCORE,
  APPROVE_PENDING_FILE,
  REPORT_ERROR,
  UPDATED_ITEM,
} from 'actions/actionTypes';
import i18n from '_i18n';

import { action } from 'reduxHelpers';
import { split } from 'lodash';
import {
  CERTIFICATION,
  LIFESTYLE_IMAGE,
  SPECIFICATION_SHEET,
  PRODUCT_IMAGE,
  RECIPES,
  VIDEO,
  MSDS_SHEET,
  GS1_IMAGE,
} from '../enums/AttributeType';
import { FILENAME_DELIMITER, VIDEO_THUMBNAIL_PREFIX } from '../constants/Constants';
import { getMimeType } from '../utils/FileUploadValidator';
import Notification from '../components/Notification';
import { getFileName, checkFileName, getFileNameForDelete } from '../utils/Utils';
import { APPROVED, DELETE_APPROVED, DELETE_PENDING, PENDING, REJECTED } from '../enums/ApprovalStatus';
import downloadItemList from 'components/DownloadItemList';
import downloadAudit from 'components/DownloadAudit';

const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const adPrefix = process.env.REACT_APP_AD_PREFIX;
const oktaPrefix = process.env.REACT_APP_OKTA_PREFIX;
const CONTENT_SERVER_URL = process.env.REACT_APP_CONTENT_SERVER_URL;

function* loadStagingItemAsync({ supc }) {
  try {
    const response = yield call(getRequest, '/staging/items', { supc, language: i18n.language });

    yield put({ type: ITEM.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ITEM.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.itemFetchError'),
      })
    );
  }
}

function* updateAttributeAsync({ supc, language, stagingAttribute, associatedUsers }) {
  try {
    const response = yield call(
      patchRequest,
      '/staging/items',
      {
        ...stagingAttribute,
        associatedUsers: associatedUsers,
      },
      {
        supc,
        language,
      }
    );
    yield put({ type: ITEM.SUCCESS, payload: response.data });
    yield put({ type: UPDATE_ATTRIBUTE.SUCCESS, payload: response.data });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: i18n.t('notification.success'),
        className: 'success',
        message: i18n.t('notification.updateAttributeSuccess'),
      })
    );
  } catch (error) {
    yield put({ type: ITEM.FAILURE, payload: error.message });
    yield put({ type: UPDATE_ATTRIBUTE.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.updateAttributeError'),
      })
    );
  }
}

function* approveAttributeAsync({ supc, language, stagingAttribute, associatedUsers }) {
  try {
    const response = yield call(
      patchRequest,
      '/staging/items/approve',
      {
        ...stagingAttribute,
        associatedUsers: associatedUsers,
      },
      {
        supc,
        language,
      }
    );
    yield put({ type: ITEM.SUCCESS, payload: response.data });
    yield put({ type: APPROVE_ATTRIBUTE.SUCCESS, payload: response.data });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: i18n.t('notification.success'),
        className: 'success',
        message: i18n.t('notification.updateAttributeSuccess'),
      })
    );
  } catch (error) {
    yield put({ type: ITEM.FAILURE, payload: error.message });
    yield put({ type: APPROVE_ATTRIBUTE.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.updateAttributeError'),
      })
    );
  }
}

const isSingleUpload = (uploadType) => {
  return uploadType === LIFESTYLE_IMAGE;
};

const invalidationNeeded = (uploadType) => {
  return (
    uploadType === LIFESTYLE_IMAGE || uploadType === PRODUCT_IMAGE || uploadType === GS1_IMAGE || uploadType === VIDEO
  );
};

function generateAttribute(uploadType, attributeStatus, newObject, file, userId, userType, identifier) {
  let attribute;
  if (isSingleUpload(uploadType)) {
    attribute = {
      attributeType: uploadType,
      value: attributeStatus === APPROVED ? newObject : file?.value,
      attributeStatus: file?.attributeStatus === DELETE_PENDING ? DELETE_APPROVED : APPROVED,
      comment: null,
      author: userId,
      timestamp: null,
      authorRole: userType,
    };
  } else {
    const updatedList = [
      // ...temp,
      {
        attributeType: uploadType,
        value: attributeStatus === APPROVED ? newObject : file?.value,
        attributeStatus: file?.attributeStatus === DELETE_PENDING ? DELETE_APPROVED : APPROVED,
        comment: null,
        author: userId,
        timestamp: null,
        identifier: identifier,
        authorRole: userType,
      },
    ];

    attribute = {
      attributeType: uploadType,
      value: updatedList,
      attributeStatus: file?.attributeStatus === DELETE_PENDING ? DELETE_APPROVED : APPROVED,
      comment: null,
      author: userId,
      timestamp: null,
      authorRole: userType,
    };
  }

  return attribute;
}

function* approvePendingFileAsync({
  language,
  uploadType,
  supc,
  userId,
  fileName,
  copySource,
  destination,
  file,
  fileList,
  attributeStatus,
  identifier,
  userType,
  associatedUsers,
}) {
  try {
    let newObject;
    if (attributeStatus === APPROVED) {
      yield call(postRequest, '/staging/approving-pending-files', null, {
        copySource,
        destination,
      });

      const tempDestination = destination;
      if (uploadType === VIDEO) {
        const copySourceThumbnail = `staging/${VIDEO_THUMBNAIL_PREFIX}${file?.value?.videoID.replace('mp4', 'jpg')}`;
        const destinationThumbnail = `${VIDEO_THUMBNAIL_PREFIX}${file?.value?.videoID.replace('mp4', 'jpg')}`;

        copySource = copySourceThumbnail;
        destination = destinationThumbnail;
        yield call(postRequest, '/staging/approving-pending-files', null, {
          copySource,
          destination,
        });
      }

      destination = tempDestination;

      var contentUrl = `${CONTENT_SERVER_URL}/${destination}`;
      var videothumbnail = `${CONTENT_SERVER_URL}/${destination
        ?.replace('videos', 'videos/thumbnails')
        .replace('mp4', 'jpg')}`;
      switch (uploadType) {
        case LIFESTYLE_IMAGE:
          newObject = { url: contentUrl, imageID: fileName };
          break;
        case PRODUCT_IMAGE:
          newObject = { url: contentUrl, imageID: fileName };
          break;
        case VIDEO:
          newObject = { url: contentUrl, videoID: fileName, thumbnail: videothumbnail };
          break;
        case SPECIFICATION_SHEET:
          newObject = { sheetURL: contentUrl, sheetName: fileName };
          break;
        case MSDS_SHEET:
          newObject = { sheetURL: contentUrl, sheetName: fileName };
          break;
        case CERTIFICATION:
          newObject = { certificateURL: contentUrl, certificateName: fileName };
          break;
        case RECIPES:
          newObject = { recipeURL: contentUrl, recipeName: fileName };
          break;
      }
    }

    let attribute = generateAttribute(uploadType, attributeStatus, newObject, file, userId, userType, identifier);

    const finalResponse = yield call(
      patchRequest,
      '/staging/items',
      {
        ...attribute,
        associatedUsers: associatedUsers,
      },
      {
        supc,
        language,
      }
    );

    if (invalidationNeeded(uploadType)) {
      yield call(postRequest, '/staging/invalidating-image', {}, { path: `/${destination}` });
    }

    yield put({ type: APPROVE_PENDING_FILE.SUCCESS, payload: null });
    yield put({ type: ITEM.SUCCESS, payload: finalResponse.data });

    yield put(
      action(SHOW_NOTIFICATION, {
        description: i18n.t('notification.success'),
        className: 'success',
        message: i18n.t('notification.updateAttributeSuccess'),
        // duration: 4,
      })
    );
  } catch (error) {
    yield put({ type: APPROVE_PENDING_FILE.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.approvalFailure'),
      })
    );
  }
}

function generateAttributeforDeleteFile(uploadType, attributeStatus, file, userId, userType) {
  let attribute;
  if (uploadType === LIFESTYLE_IMAGE || uploadType === GS1_IMAGE) {
    if (file?.attributeStatus === PENDING && uploadType === LIFESTYLE_IMAGE) {
      attribute = {
        attributeType: uploadType,
        value: file?.previousValue,
        attributeStatus: APPROVED,
        comment: null,
        author: userId,
        timestamp: null,
        authorRole: userType,
      };
    } else {
      attribute = {
        attributeType: uploadType,
        value: file?.value,
        attributeStatus: attributeStatus,
        comment: null,
        author: userId,
        timestamp: null,
        authorRole: userType,
      };
    }
  } else {
    attribute = {
      attributeType: uploadType,
      value: [file],
      attributeStatus: attributeStatus,
      comment: null,
      author: userId,
      timestamp: null,
      authorRole: userType,
    };
  }

  return attribute;
}

function generateFileName(file, uploadType, fileName) {
  let filenameWithPciPrefix = '';
  if (file?.attributeStatus === REJECTED && uploadType === LIFESTYLE_IMAGE) {
    filenameWithPciPrefix = getFileName(uploadType, fileName);
  } else if (file?.attributeStatus === PENDING && uploadType === LIFESTYLE_IMAGE) {
    filenameWithPciPrefix = 'staging/' + checkFileName(uploadType, fileName);
  } else {
    filenameWithPciPrefix = getFileNameForDelete(uploadType, fileName, file?.attributeStatus);
  }
  return filenameWithPciPrefix;
}

function* deleteFileAsync({
  fileName,
  uploadType,
  supc,
  userId,
  language,
  attributeStatus,
  file,
  userType,
  associatedUsers,
}) {
  try {
    if (!fileName) {
      yield put(
        action(SHOW_NOTIFICATION, {
          description: i18n.t('notification.success'),
          className: 'success',
          message: i18n.t('notification.fileDoesntExist'),
        })
      );
    } else {
      // Catching Dismiss scenario with attributeStatus === null
      if (
        attributeStatus === null ||
        (attributeStatus === DELETE_APPROVED && file?.attributeStatus !== DELETE_PENDING)
      ) {
        let filenameWithPciPrefix = generateFileName(file, uploadType, fileName);

        const splitFilename = fileName.split(FILENAME_DELIMITER);
        const extension = FILENAME_DELIMITER.concat(splitFilename[splitFilename.length - 1]);
        const fileToDelete = {
          fileName: filenameWithPciPrefix,
          contentType: getMimeType(extension),
          operation: 'deleteObject',
        };
        const signedUrlResponse = yield call(postRequest, '/staging/signed-url', fileToDelete, {});
        yield call(deleteS3Request, signedUrlResponse?.data?.url, fileToDelete);
        if (invalidationNeeded(uploadType)) {
          yield call(postRequest, '/staging/invalidating-image', {}, { path: `/${filenameWithPciPrefix}` });
        }

        // CHECK AGAIN
        if (uploadType === VIDEO) {
          let filenameWithPciPrefixVideo = getFileNameForDelete(
            uploadType,
            fileName.replace('mp4', 'jpg'),
            file?.attributeStatus
          );
          const splitFilenameVideo = fileName.split(FILENAME_DELIMITER);
          const extensionVideo = FILENAME_DELIMITER.concat(splitFilenameVideo[splitFilenameVideo.length - 1]);
          const fileToDeleteVideo = {
            fileName: filenameWithPciPrefixVideo,
            contentType: getMimeType(extensionVideo),
            operation: 'deleteObject',
          };
          const signedUrlResponseVideo = yield call(postRequest, '/staging/signed-url', fileToDeleteVideo, {});
          const deleteResponseVideo = yield call(deleteS3Request, signedUrlResponseVideo?.data?.url, fileToDeleteVideo);
          yield put({ type: DELETE_FILE.SUCCESS, payload: deleteResponseVideo.data });
        }
        // const signedUrlResponse = yield call(putS3Request, url, file);
        // yield put({ type: DELETE_FILE.SUCCESS, payload: deleteResponse.data });
      }

      let attribute = generateAttributeforDeleteFile(uploadType, attributeStatus, file, userId, userType);

      const finalResponse = yield call(
        patchRequest,
        '/staging/items',
        {
          ...attribute,
          associatedUsers: associatedUsers,
        },
        {
          supc,
          language,
        }
      );

      yield put({ type: ITEM.SUCCESS, payload: finalResponse.data });
      yield put({ type: DELETE_FILE.SUCCESS, payload: null });

      yield put(
        action(SHOW_NOTIFICATION, {
          description: i18n.t('notification.success'),
          className: 'success',
          message: i18n.t('notification.fileDeleteSuccess'),
        })
      );
    }
  } catch (error) {
    yield put({ type: DELETE_FILE.FAILURE, payload: error });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.fileDeleteError'),
      })
    );
  }
}

function* loadItemCatalogAsync({
  language,
  sort,
  vendorNo,
  size,
  page,
  searchKey,
  updatedFilter,
  actionRequired,
  userType,
}) {
  console.log('userType: ', userType);
  try {
    const response = yield call(postRequest, '/items/item-catalog', updatedFilter, {
      language,
      sort,
      vendorNo,
      size,
      page,
      searchKey,
      actionRequired,
      userType,
    });

    yield put({ type: ITEM_CATALOG.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ITEM_CATALOG.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.itemCatalogFetchError'),
      })
    );
  }
}

function* bulkUploadAsync({ language, override, userType, uploadItems, vendorNo, associatedUsers }) {
  try {
    const supcList = uploadItems?.map((item) => item?.supc);
    const validateResponse = yield call(
      postRequestCompressed,
      '/staging/item/upload/validate',
      { supcList: supcList },
      {
        userType,
        vendorNo,
      }
    );

    if (validateResponse?.data?.status === 'ERROR') {
      const errorMessage = 'SUPC: ' + validateResponse?.data?.message;
      yield put({ type: BULK_UPLOAD.FAILURE, payload: validateResponse?.data });
      yield put(
        action(SHOW_NOTIFICATION, {
          description: errorMessage,
          className: 'error',
          message: i18n.t('notification.invalidSupc'),
          duration: 10,
          isClosable: true,
        })
      );
    } else {
      let size = 5;
      const total = Math.ceil(uploadItems.length / size);
      const successResponses = [];
      yield put({ type: BULK_UPLOAD.SUCCESS, payload: successResponses });
      yield put(
        action(SHOW_NOTIFICATION, {
          description: '',
          className: 'success',
          message: i18n.t('notification.bulkUploadWait'),
          duration: 10,
          isClosable: true,
        })
      );

      for (let i = 0; i < total; i++) {
        const startIndex = i * size;
        const endIndex = startIndex + size;
        const items = uploadItems.slice(startIndex, endIndex);

        const response = yield call(
          postRequestCompressed,
          '/staging/item/upload',
          { uploadItems: items, associatedUsers: associatedUsers },
          {
            language,
            override,
            userType,
            vendorNo,
          }
        );
        successResponses.push(response.data);
      }

      yield put({ type: BULK_UPLOAD.SUCCESS, payload: successResponses });
      yield put(
        action(SHOW_NOTIFICATION, {
          description: i18n.t('notification.success'),
          className: 'success',
          message: i18n.t('notification.bulkUploadSuccess'),
          duration: 10,
          isClosable: true,
        })
      );
    }
  } catch (error) {
    yield put({ type: BULK_UPLOAD.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.bulkUploadError'),
      })
    );
  }
}

function* loadVendorsAsync({ vendorName, page, size }) {
  try {
    const response = yield call(getRequest, '/vendors', {
      vendorName,
      page,
      size,
    });
    yield put({ type: VENDOR.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: VENDOR.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.vendorsFetchError'),
      })
    );
  }
}

function* loadItemApprovalPendingVendorsAsync({ vendorName, page, size, language, userType }) {
  try {
    const response = yield call(getRequest, '/vendors/action-required-vendors', {
      vendorName,
      page,
      size,
      language,
      userType,
    });
    yield put({ type: ITEM_APPROVAL_PENDING_VENDOR.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ITEM_APPROVAL_PENDING_VENDOR.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.vendorsFetchError'),
      })
    );
  }
}

function* loadCategoriesAsync({ language, page, size }) {
  try {
    const response = yield call(getRequest, '/categories', {
      language,
      page,
      size,
    });
    yield put({ type: CATEGORIES.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CATEGORIES.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.categoriesFetchError'),
      })
    );
  }
}

function* loadClassesAsync({ language, page, size }) {
  try {
    const response = yield call(getRequest, '/classes', {
      language,
      page,
      size,
    });
    yield put({ type: CLASSES.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: CLASSES.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.categoriesFetchError'),
      })
    );
  }
}

const getUserId = (username) => {
  if (username.indexOf(adPrefix) >= 0) {
    return split(username, '@')[0].replace(adPrefix, '');
  }
  if (username.indexOf(oktaPrefix) >= 0) {
    return username.replace(oktaPrefix, '');
  }
  return username;
};

function* loadUserAsync({ username }) {
  const id = getUserId(username);
  try {
    const response = yield call(getRequest, `/users/username/${id}`);
    yield put({ type: USER_DATA.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: USER_DATA.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.userDataError'),
      })
    );
  }
}

function* loadVendorScoreAsync({ vendorNumber, language }) {
  try {
    const response = yield call(getRequest, `/vendors/vendor-score/vendorNumber/${vendorNumber}`, { language });
    yield put({ type: VENDOR_SCORE.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: VENDOR_SCORE.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.vendorScoreError'),
      })
    );
  }
}

function* loadAttributesAsync({ language, page, size }) {
  try {
    const response = yield call(getRequest, '/items/attributes', {
      language,
      page,
      size,
    });
    yield put({ type: ATTRIBUTES.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ATTRIBUTES.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.attributeFetchError'),
      })
    );
  }
}

function* loadAuditingDetailsAsync({ supc, attributeType, attributeStatus }) {
  try {
    const response = yield call(getRequest, '/auditing/info/', {
      supc,
      attributeStatus,
      attributeType,
    });
    yield put({ type: AUDITING_DETAILS.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: AUDITING_DETAILS.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: 'Error while fetching auditing item list',
      })
    );
  }
}

function* showNotificationAsync(notificationAction) {
  const { message, description, className, isClosable, duration } = notificationAction;
  yield Notification({ message, description, className, isClosable, duration });
}

function* reportErrorAsync({ supc, request }) {
  try {
    const response = yield call(postRequest, '/items/report-error', request, {
      supc,
    });
    yield put({ type: ITEM.SUCCESS, payload: response.data?.item });
    yield put({ type: REPORT_ERROR.SUCCESS, payload: response.data });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: i18n.t('notification.success'),
        className: 'success',
        message: 'Core data error updated successfully',
        isClosable: true,
      })
    );
  } catch (error) {
    yield put({ type: REPORT_ERROR.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: 'Error while updating core data error',
      })
    );
  }
}

function* downloadActivityLogAsync({ startDate, endDate, attributeTypes }) {
  try {
    let size = 1000;
    let itemList = [];
    let page = 0;
    let count = 0;
    do {
      const response = yield call(getRequest, `/auditing/download`, {
        startDate,
        endDate,
        attributeTypes,
        page,
        size,
      });
      itemList = [...itemList, ...response?.data];
      page++;
      count = (response?.data).length;
    } while (count == size);

    downloadAudit(itemList, i18n.language);
    yield put({ type: DOWNLOAD_AUDIT.SUCCESS, payload: itemList });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: i18n.t('notification.success'),
        className: 'success',
        message: i18n.t('notification.downloadAuditSuccess'),
      })
    );
  } catch (error) {
    yield put({ type: DOWNLOAD_AUDIT.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.downloadAuditError'),
        isClosable: true,
        duration: 0,
      })
    );
  }
}

function* downloadItemListAsync({ vendorNo, totalItems, proprietary }) {
  try {
    let size = 100;
    let itemList = [];

    const pageIterator = Math.ceil(totalItems / size);

    for (let page = 0; page < pageIterator; page++) {
      const response = yield call(getRequest, `/items/download-list`, {
        vendorNo,
        page,
        size,
        proprietary,
      });
      itemList = [...itemList, ...response?.data];
    }
    downloadItemList(itemList, i18n.language);
    yield put({ type: DOWNLOAD_ITEMS.SUCCESS, payload: itemList });
  } catch (error) {
    yield put({ type: DOWNLOAD_ITEMS.FAILURE, payload: error.message });
    yield put(
      action(SHOW_NOTIFICATION, {
        description: error.message,
        className: 'error',
        message: i18n.t('notification.downloadItemsError'),
      })
    );
  }
}

function* updateItemAsync() {
  try {
    const response = '';
    yield put({ type: UPDATED_ITEM.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATED_ITEM.FAILURE, payload: error.message });
  }
}

function* watchLoadUser() {
  // yield takeLatest(ITEM.REQUEST, loadItemAsync);
  yield takeLatest(ITEM_CATALOG.REQUEST, loadItemCatalogAsync);
  yield takeLatest(VENDOR.REQUEST, loadVendorsAsync);
  yield takeLatest(ITEM_APPROVAL_PENDING_VENDOR.REQUEST, loadItemApprovalPendingVendorsAsync);
  yield takeLatest(ITEM.REQUEST, loadStagingItemAsync);
  yield takeLatest(UPDATE_ATTRIBUTE.REQUEST, updateAttributeAsync);
  yield takeLatest(APPROVE_ATTRIBUTE.REQUEST, approveAttributeAsync);
  yield takeLatest(ATTRIBUTES.REQUEST, loadAttributesAsync);
  yield takeLatest(DELETE_FILE.REQUEST, deleteFileAsync);
  yield takeLatest(CATEGORIES.REQUEST, loadCategoriesAsync);
  yield takeLatest(CLASSES.REQUEST, loadClassesAsync);
  yield takeLatest(AUDITING_DETAILS.REQUEST, loadAuditingDetailsAsync);
  yield takeLatest(DOWNLOAD_AUDIT.REQUEST, downloadActivityLogAsync);
  yield takeLatest(DOWNLOAD_ITEMS.REQUEST, downloadItemListAsync);
  yield takeLatest(BULK_UPLOAD.REQUEST, bulkUploadAsync);
  yield takeLatest(USER_DATA.REQUEST, loadUserAsync);
  yield takeLatest(VENDOR_SCORE.REQUEST, loadVendorScoreAsync);
  yield takeLatest(APPROVE_PENDING_FILE.REQUEST, approvePendingFileAsync);
  yield takeLatest(REPORT_ERROR.REQUEST, reportErrorAsync);
  yield takeLatest(UPDATED_ITEM.REQUEST, updateItemAsync);
  yield takeEvery(SHOW_NOTIFICATION, showNotificationAsync);
}

export default function* rootSaga() {
  yield all([watchLoadUser()]);
}
