import React from 'react';
import MissingInfoPlaceholder from '../../../components/MissingInfoPlaceholder';

function BrandAndLogo({ aboutBrand, coreData, aboutBrandEdit, showMissingInfoPlaceholder }) {
  const getClassName = () => {
    return !aboutBrandEdit ? '' : 'hide';
  };
  return aboutBrand || coreData?.brandLogoURL ? (
    <div id="info-column-about-brand-data-description" className={`data description ${getClassName()}`}>
      {coreData?.brandLogoURL && (
        <img
          id="info-column-about-brand-logo-url"
          className="inline-image info-column-brand-logo"
          src={coreData?.brandLogoURL}
        />
      )}
      {aboutBrand}
    </div>
  ) : (
    <MissingInfoPlaceholder
      id="info-column-about-brand-data-description-missing-placeholder-show"
      show={showMissingInfoPlaceholder}
    />
  );
}

export default BrandAndLogo;
