import React from 'react';
import i18n from 'i18next';
import { Button, Icon, Tooltip } from 'antd';
import { LIFESTYLE_IMAGE } from 'enums/AttributeType';
import { attributeEditorDetails } from '../../../utils/Utils';
import { LIFESTYLE_PREFIX } from '../../../constants/Constants';
import { PENDING, APPROVED, DELETE_APPROVED, DELETE_PENDING } from '../../../enums/ApprovalStatus';

function LifestyleImagePending({
  media,
  currentImage,
  attributeManagement,
  handleApprovingPendingFile,
  setLifeStyleImageRejectModal,
  userRole,
}) {
  return (
    (currentImage?.attributeStatus === PENDING || currentImage?.attributeStatus === DELETE_PENDING) &&
    currentImage.attributeType === LIFESTYLE_IMAGE && (
      <div id="media-column-lifestyle-images-footer-pending" className="footer-bar warning">
        <Icon
          id="media-column-lifestyle-images-footer-pending-warning-icon"
          type="warning"
          theme="filled"
          className="icon status-icon warning"
        />
        <div id="media-column-lifestyle-images-footer-pending-label" className="label">
          {currentImage?.attributeStatus === DELETE_PENDING
            ? i18n.t('mediaColumn.pendingApprovalDelete')
            : i18n.t('mediaColumn.pendingApproval')}
        </div>

        <div id="media-column-lifestyle-images-footer-pending-actions" className="footer-actions">
          {attributeManagement.LIFESTYLE_IMAGE?.approvable && (
            <React.Fragment>
              <Button
                id="media-column-lifestyle-images-footer-pending-actions-approve"
                className="btn green-action-btn invert"
                onClick={() => {
                  handleApprovingPendingFile(
                    currentImage?.value?.imageID,
                    'staging/' + LIFESTYLE_PREFIX + currentImage?.value?.imageID,
                    LIFESTYLE_PREFIX + currentImage?.value?.imageID,
                    currentImage,
                    null,
                    LIFESTYLE_IMAGE,
                    currentImage?.attributeStatus === DELETE_PENDING ? DELETE_APPROVED : APPROVED,
                    currentImage?.value.imageID
                  );
                }}
              >
                <Icon id="media-column-lifestyle-images-footer-pending-actions-approve-icon" type="check" />
                {i18n.t('infoColumn.approve')}
              </Button>
              <Button
                id="media-column-lifestyle-images-footer-pending-actions-reject"
                className="btn red-action-btn invert"
                onClick={() => {
                  setLifeStyleImageRejectModal(true);
                }}
              >
                <Icon id="media-column-lifestyle-images-footer-pending-actions-reject-icon" type="close" />
                {i18n.t('infoColumn.reject')}
              </Button>
            </React.Fragment>
          )}
          {(attributeManagement.LIFESTYLE_IMAGE?.editable || attributeManagement.LIFESTYLE_IMAGE?.approvable) && (
            <Tooltip
              id="media-column-lifestyle-images-tooltip"
              title={attributeEditorDetails(userRole, media?.lifestyleImageUrl?.attributeStatus, currentImage)}
            >
              <i
                id="media-column-lifestyle-images-tooltip-icon"
                className="info-icon icon fi flaticon-information-button"
              />
            </Tooltip>
          )}
        </div>
      </div>
    )
  );
}

export default LifestyleImagePending;
