import React from 'react';
import { LIFESTYLE_IMAGE } from 'enums/AttributeType';
import { PENDING, REJECTED, DELETE_APPROVED, DELETE_PENDING } from '../../../enums/ApprovalStatus';

function checkDeleteStatus(approved) {
  return approved ? DELETE_APPROVED : DELETE_PENDING;
}

function LifestyleImageDelete({ currentImage, attributeManagement, handleLifestyleImageDelete }) {
  return (
    currentImage?.attributeType === LIFESTYLE_IMAGE &&
    attributeManagement.LIFESTYLE_IMAGE.editable &&
    !(
      currentImage?.attributeStatus === DELETE_APPROVED ||
      currentImage?.attributeStatus === DELETE_PENDING ||
      currentImage?.attributeStatus === REJECTED
    ) && (
      <div
        id="media-column-images-editable-delete"
        style={{ paddingLeft: 10 + 'px' }}
        className="delete-icon fi flaticon-delete"
        onClick={() => {
          handleLifestyleImageDelete(
            currentImage?.value?.imageID,
            LIFESTYLE_IMAGE,
            currentImage?.attributeStatus === PENDING
              ? null
              : checkDeleteStatus(attributeManagement?.LIFESTYLE_IMAGE?.approvable),
            currentImage
          );
        }}
      />
    )
  );
}

export default LifestyleImageDelete;
