import React, { useState } from 'react';
import { APPROVED, PENDING, REJECTED } from '../../../enums/ApprovalStatus';
import { Button, Icon, Input } from 'antd';
import i18n from '../../../_i18n';
import { ABOUT_BRAND } from '../../../enums/AttributeType';
import { attributeEditorDetails } from '../../../utils/Utils';
import RejectModal from '../../../components/modals/RejectModal';
import { useTranslation } from 'react-i18next';
import BrandAndLogo from './BrandAndLogo';
import { VENDOR } from 'enums/UserType';

function showHighlighted(coreData) {
  return (
    coreData?.aboutBrand?.aboutTheBrand?.attributeStatus === APPROVED ||
    coreData?.aboutBrand?.aboutTheBrand?.attributeStatus === REJECTED
  );
}

function getBrandValue(coreData, userType) {
  return coreData?.aboutBrand?.aboutTheBrand?.attributeStatus === REJECTED &&
    coreData?.aboutBrand?.aboutTheBrand?.authorRole !== userType
    ? coreData?.aboutBrand?.aboutTheBrand?.previousValue
    : coreData?.aboutBrand?.aboutTheBrand?.value;
}

function handleBrandSave(aboutBrand, aboutBrandUnderEdit, setAboutBrand, handleSave, attributeManagement) {
  if (aboutBrand !== aboutBrandUnderEdit) {
    setAboutBrand(aboutBrandUnderEdit);
    handleSave(ABOUT_BRAND, aboutBrandUnderEdit, attributeManagement.ABOUT_BRAND.approvable ? APPROVED : PENDING);
  }
}

function brandEditHideClassName(aboutBrandEdit) {
  return `data-edit description ${aboutBrandEdit ? '' : 'hide'}`;
}

function Brand({
  coreData,
  attributeCSSApplier,
  userType,
  setShowMissingInfoPlaceholder,
  handleSave,
  showMissingInfoPlaceholder,
  permissions
}) {
  const { TextArea } = Input;
  const { t } = useTranslation();
  // const attributeManagement = attributeVisibility(userType);

  const [aboutBrand, setAboutBrand] = useState(getBrandValue(coreData, userType));
  const [aboutBrandUnderEdit, setAboutBrandUnderEdit] = useState(aboutBrand);
  const [aboutBrandEdit, setAboutBrandEdit] = useState(false);
  const [aboutBrandRejectModal, setAboutBrandRejectModal] = useState(false);

  return (
    permissions.ABOUT_BRAND.visible && (aboutBrand || coreData?.brandLogoURL || userType !== VENDOR) &&  (
      <div id="info-column-about-brand" className="info-row">
        <div
          id="info-column-about-brand-status"
          className={attributeCSSApplier(
            coreData?.aboutBrand?.aboutTheBrand?.attributeStatus,
            coreData?.aboutBrand?.aboutTheBrand?.authorRole === userType
          )}
        >
          {permissions.ABOUT_BRAND.editable ? (
            <div
              id="info-column-about-brand-edit"
              className="icon edit-icon fi flaticon-edit"
              onClick={() => {
                setAboutBrandEdit(true);
                setShowMissingInfoPlaceholder(false);
              }}
            />
          ) : null}
          {coreData?.aboutBrand?.aboutTheBrand?.authorRole === userType &&
            coreData?.aboutBrand?.aboutTheBrand?.attributeStatus === APPROVED && (
              <Icon id="info-column-about-approved-icon" type="check" className="icon status-icon approved" />
            )}
          <div id="info-column-about-brand-label" className="label">
            {i18n.t('infoColumn.aboutTheBrand')}
            {!permissions.ABOUT_BRAND.approvable &&
              coreData?.aboutBrand?.aboutTheBrand?.attributeStatus === PENDING && (
                <div id="info-column-about-brand-label-pending" className="label-actions">
                  {i18n.t('infoColumn.editsPending')}
                </div>
              )}
            {permissions.ABOUT_BRAND.approvable &&
              coreData?.aboutBrand?.aboutTheBrand?.attributeStatus === PENDING && (
                <div id="info-column-about-brand-label-actions" className="label-actions">
                  <Button
                    id="info-column-about-brand-label-actions-approve"
                    className="btn green-action-btn invert"
                    onClick={() => {
                      handleSave(ABOUT_BRAND, aboutBrand, APPROVED);
                    }}
                  >
                    <Icon id="info-column-about-brand-label-actions-approve-icon" type="check" />{' '}
                    {i18n.t('infoColumn.approve')}
                  </Button>
                  <Button
                    id="info-column-about-brand-label-actions-reject"
                    className="btn red-action-btn invert"
                    onClick={() => {
                      setAboutBrandRejectModal(true);
                    }}
                  >
                    <Icon id="info-column-about-brand-label-actions-reject-icon" type="close" />{' '}
                    {i18n.t('infoColumn.reject')}
                  </Button>
                </div>
              )}
            {coreData?.aboutBrand?.aboutTheBrand?.authorRole === userType && showHighlighted(coreData) && (
              <div id="info-column-about-brand-completed" className="label-actions">
                <div id="info-column-about-brand-completed-status" className="status-label">
                  {coreData?.aboutBrand?.aboutTheBrand?.attributeStatus}
                </div>
                {coreData?.aboutBrand?.aboutTheBrand?.authorRole === userType && (
                  <Button
                    id="info-column-about-brand-completed-dismiss"
                    className="btn"
                    onClick={() => {
                      setAboutBrand(coreData?.aboutBrand?.aboutTheBrand?.previousValue);
                      handleSave(ABOUT_BRAND, aboutBrand, null);
                    }}
                  >
                    {i18n.t('infoColumn.dismiss')}
                  </Button>
                )}
                {/* <Button className="btn">Undo</Button> */}
              </div>
            )}
          </div>
          <BrandAndLogo
            aboutBrand={aboutBrand}
            coreData={coreData}
            aboutBrandEdit={aboutBrandEdit}
            showMissingInfoPlaceholder={showMissingInfoPlaceholder}
          />
          <div id="info-column-about-brand-data-edit" className={brandEditHideClassName(aboutBrandEdit)}>
            <TextArea
              id="info-column-about-brand-data-edit-text-area"
              value={aboutBrandUnderEdit}
              rows={4}
              onChange={(e) => setAboutBrandUnderEdit(e.target.value)}
            />
          </div>
          {aboutBrandEdit && (
            <div id="info-column-about-brand-footer" className="footer-bar">
              <div id="info-column-about-brand-footer-actions" className="footer-actions">
                <Button
                  id="info-column-about-brand-footer-actions-save-button"
                  className="btn green-action-btn invert"
                  onClick={() => {
                    handleBrandSave(aboutBrand, aboutBrandUnderEdit, setAboutBrand, handleSave, permissions);
                    setAboutBrandEdit(false);
                    setShowMissingInfoPlaceholder(true);
                  }}
                >
                  <Icon id="info-column-about-brand-footer-actions-save-button-icon" type="check" />{' '}
                  {t('infoColumn.save')}
                </Button>
                <Button
                  id="info-column-about-brand-footer-actions-cancel-button"
                  className="btn red-action-btn invert"
                  onClick={() => {
                    setAboutBrandEdit(false);
                    setShowMissingInfoPlaceholder(true);
                    setAboutBrandUnderEdit(aboutBrand);
                  }}
                >
                  <Icon id="info-column-about-brand-footer-actions-cancel-button-icon" type="close" />{' '}
                  {t('infoColumn.cancel')}
                </Button>
              </div>
            </div>
          )}
          {(coreData?.aboutBrand?.aboutTheBrand?.attributeStatus === PENDING ||
            coreData?.aboutBrand?.aboutTheBrand?.authorRole === userType) && (
            <div id="info-column-about-brand-footer-bar" className="footer-bar">
              <div id="info-column-about-brand-footer-bar-edited-meta" className="edited-meta">
                <div id="info-column-about-brand-footer-bar-edited-meta-data">
                  {attributeEditorDetails(
                    userType,
                    coreData?.aboutBrand?.aboutTheBrand?.attributeStatus || null,
                    coreData?.aboutBrand?.aboutTheBrand
                  )}
                </div>
                {coreData?.aboutBrand?.aboutTheBrand?.attributeStatus === REJECTED && (
                  <div id="info-column-about-brand-footer-bar-edited-meta-data-reason" className="reason">
                    {i18n.t('infoColumn.reason')}: <span>{coreData?.aboutBrand?.aboutTheBrand?.comment}</span>
                  </div>
                )}
              </div>
            </div>
          )}

          <RejectModal
            id="info-column-about-brand-footer-bar-edited-meta-data-reject-modal"
            modalVisibility={aboutBrandRejectModal}
            setRejectVisibility={setAboutBrandRejectModal}
            rejectFunction={(comment) => {
              setAboutBrand(coreData?.aboutBrand?.aboutTheBrand?.previousValue);
              handleSave(ABOUT_BRAND, aboutBrand, REJECTED, comment);
            }}
            rejectAttribute={i18n.t('infoColumn.aboutTheBrand')}
          />
        </div>
      </div>
    )
  );
}

export default Brand;
