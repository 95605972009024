import React, { useState } from 'react';
import { Button, Icon, Input, Modal } from 'antd';
import i18n from '../../_i18n';

export function RejectModal({ modalVisibility, setRejectVisibility, rejectFunction, rejectAttribute }) {
  const [rejectReason, setRejectReason] = useState('');

  const closeModal = () => {
    setRejectVisibility(false);
  };

  const rejectMethod = (comment) => {
    rejectFunction(comment);
    closeModal();
    setRejectReason('');
  };

  const { TextArea } = Input;

  return (
    <Modal
      id="reject-modal"
      className="item-popup"
      visible={modalVisibility}
      title={[
        <div id="reject-modal-header" key="reject-modal-header" className="header rejected">
          <Icon type="close" className="icon" />
          <div id="reject-modal-title" key="reject-modal-title" className="title ">
            {i18n.t('infoColumn.reject')}
          </div>
          <div id="reject-modal-subtitle" key="reject-modal-subtitle" className="subtitle">
            {rejectAttribute}
          </div>
        </div>,
      ]}
      onOk={closeModal}
      onCancel={closeModal}
      destroyOnClose={true}
      footer={[
        <Button id="reject-modal-back" key="reject-modal-back" onClick={closeModal}>
          {i18n.t('infoColumn.cancel')}
        </Button>,
        <Button
          id="reject-modal-submit"
          key="reject-modal-submit"
          type="primary"
          onClick={() => {
            rejectMethod(rejectReason);
          }}
        >
          {i18n.t('fileImport.submit')}
        </Button>,
      ]}
    >
      <label id="reject-modal-reason-label">{i18n.t('infoColumn.reason')}</label>
      <TextArea
        id="reject-modal-reason-textarea"
        value={rejectReason}
        onChange={(e) => {
          setRejectReason(e.target.value);
        }}
        rows={4}
      />
    </Modal>
  );
}

export default RejectModal;

RejectModal.propTypes = {};
