import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import * as batchesActions from 'actions';
import AppLoader from 'components/AppLoader';
import AppLogo from './styles/images/app-logos/item-enrichment-logo.svg';
import styles from './styles/ItemEnrichmentApp.scss';
import { userData } from './actions/index';

import MainPage from './pages/MainPage';
import { VENDOR } from './enums/UserType';

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = AppLogo;
  }
};

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

function MainApp({ userId, setVendorList, setVendorDropdownVisible, setUserRole, vendorId }) {
  const dispatch = useDispatch();
  setAppLogo();
  setAppHeaderToAppMode();

  const { fetching: userFetching, data } = useSelector((state) => state.userData);
  const { fetching: downloadItemState } = useSelector((state) => state.downloadItems);
  const { fetching: itemFetching } = useSelector((state) => state.item);
  console.log('User profile: ', data);

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  useEffect(() => {
    dispatch(userData(userId));
  }, [userId]);

  useEffect(() => {
    const vendorList = data?.vendors?.map(v => {
      return {
        vendorId: v.syscoVendorId,
        vendorName: v?.displayName ? v?.displayName : v?.vendorName
      };
    });
    setVendorList(vendorList ? vendorList : []);
    setVendorDropdownVisible(data.userRole === VENDOR); // this will display the vendor dropdown in the Landing UI
    setUserRole(data.userRole);
  }, [data]);

  return (!itemFetching || !userFetching) && !downloadItemState ? (
    <div id="item-enrichment-main-page" className="wrapper item-enrichment-wrapper">
      <MainPage vendorId={vendorId} />
    </div>
  ) : (
    <AppLoader show />
  );
}

function mapStateToProps(state) {
  return {
    item: state.item.data,
  };
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(batchesActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
