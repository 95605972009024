import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import i18n from '../_i18n';
import { USAENG, CANFRE } from '../constants/Constants';
import ActivityLogModal from './modals/ActivityLogModal';
import PreviewModal from './modals/PreviewModal';
import { APPROVED, DELETE_PENDING, PENDING, REJECTED } from 'enums/ApprovalStatus';
import { approvePendingAttributes } from 'actions';
import RejectModal from './modals/RejectModal';
import { ASSOCIATE } from '../enums/UserType';
import { FEATURES_AND_BENEFITS } from 'enums/AttributeType';

const CONTENT_SERVER_URL = process.env.REACT_APP_CONTENT_SERVER_URL;

export function hasPendingAttributes(item) {
  return (
    hasPendingFile(item?.media?.lifestyleImageUrl) ||
    hasPendingFiles(item?.mediaInfo?.msdsSheet) ||
    hasPendingFiles(item?.mediaInfo?.specificationSheet) ||
    hasPendingFiles(item?.media?.otherImageUrls) ||
    hasPendingFiles(item?.media?.videos) ||
    hasPendingFiles(item?.mediaInfo?.recipes) ||
    hasPendingFiles(item?.nutritionalInformation?.certification) ||
    hasPendingFab(item?.coreData?.productDescriptor) ||
    hasPendingFab(item?.coreData?.packagingInformation) ||
    hasPendingFab(item?.coreData?.sizeAndShapeOfProduct) ||
    hasPendingFab(item?.coreData?.yieldOrServingSize) ||
    hasPendingFab(item?.coreData?.qualityAndFormat) ||
    hasPendingFab(item?.coreData?.preparationAndCookingInstructions) ||
    hasPendingFab(item?.coreData?.storageAndUsage) ||
    hasPendingFab(item?.coreData?.handlingInstructions) ||
    hasPendingFab(item?.coreData?.additionalProductInformation) ||
    hasPendingFab(item?.coreData?.marketingStatements) ||
    hasPendingFab(item?.coreData?.culinaryApplications)
  );
}

function hasPendingFab(fab) {
  return fab?.attributeStatus === PENDING;
}

function hasPendingFile(media) {
  return media?.attributeStatus === PENDING || media?.attributeStatus === DELETE_PENDING;
}

function hasPendingFiles(files) {
  let i;
  for (i = 0; i < files?.length; i++) {
    var file = files[i];
    var isPendingFile = file?.attributeStatus === PENDING || file?.attributeStatus === DELETE_PENDING;
    if (isPendingFile === true) {
      return isPendingFile;
    }
  }
  return false;
}

export function EnrichmentHeader({ setItemOpen }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activityLogVisibility, setActivityLogVisibility] = useState(false);
  const itemData = useSelector((state) => state.item.data);
  const {
    data: { fullName: userId, userRole, associatedUsers },
  } = useSelector((state) => state.userData);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [rejectAllModal, setRejectAllModal] = useState(false);

  const approveAttribute = {
    attributeType: FEATURES_AND_BENEFITS,
    value: 'approve all',
    attributeStatus: APPROVED,
    comment: null,
    author: userId,
    timestamp: null,
    authorRole: userRole,
  };

  const supc = itemData?.coreData?.supc;

  const approveAll = () => {
    dispatch(approvePendingAttributes(supc, i18n.language, approveAttribute, associatedUsers));
  };

  const rejectAll = (comment) => {
    const rejectAttribute = {
      attributeType: FEATURES_AND_BENEFITS,
      value: 'reject all',
      attributeStatus: REJECTED,
      comment: comment,
      author: userId,
      timestamp: null,
      authorRole: userRole,
    };
    dispatch(approvePendingAttributes(supc, i18n.language, rejectAttribute, associatedUsers));
  };

  return (
    <div id="enrichment-header" className="enrichment-header">
      <div id="enrichment-header-collapser" className="collapser">
        <Icon id="enrichment-header-caret-right" type="caret-right" onClick={() => setItemOpen(false)} />
      </div>
      <div id="enrichment-header-enrichment-level" className="enrichment-level">
        {/* <div className="label">{t('enrichmentHeader.enrichmentLevel')}</div>
        <Progress percent={80} className="progress good" showInfo={true} />
        <Icon type="warning" theme="filled" className="icon alert-icon" /> */}
        <div id="enrichment-header-enrichment-example" className="enriched-example">
          <i id="enrichment-header-enrichment-example-info-button" className="icon fi flaticon-information-button" />{' '}
          {t('enrichmentHeader.seeExamplePart1')}
          <a
            id="enrichment-header-enrichment-example-url"
            href={`${CONTENT_SERVER_URL}/supc/2190429?lang=${i18n.language === 'en' ? USAENG : CANFRE}`}
            target="_blank"
          >
            {t('enrichmentHeader.seeExamplePart2')}
          </a>
          {t('enrichmentHeader.seeExamplePart3')}
        </div>
      </div>

      <div id="enrichment-header-approve-all" className="approve-all-btn">
        {userRole === ASSOCIATE && hasPendingAttributes(itemData) && (
          <Button
            id="enrichment-header-approve-all-button"
            className="btn green-action-btn invert"
            onClick={() => approveAll()}
          >
            <Icon id="enrichment-header-approve-all-icon" type="check" /> {t('button.approveAll')}
          </Button>
        )}
      </div>

      <div id="enrichment-header-reject-all" className="reject-all-btn">
        {userRole === ASSOCIATE && hasPendingAttributes(itemData) && (
          <Button
            id="enrichment-header-reject-all-button"
            className="btn red-action-btn invert"
            onClick={() => setRejectAllModal(true)}
          >
            <Icon id="enrichment-header-reject-all-icon" type="close" /> {t('button.rejectAll')}
          </Button>
        )}
      </div>

      <div
        id="enrichment-header-activity-log-button"
        onClick={() => {
          setActivityLogVisibility(true);
        }}
        className="activity-log-btn"
      >
        <i id="enrichment-header-activity-log-button-icon" className="icon fi flaticon-history" />
        {t('enrichmentHeader.activityLog')}
      </div>
      {!_.isEmpty(itemData.coreData) ? (
        <ActivityLogModal
          id="enrichment-header-activity-log-modal"
          visibility={activityLogVisibility}
          setActivityLogVisibility={setActivityLogVisibility}
          supc={itemData.coreData.supc}
          attributeType="ITEM"
        />
      ) : null}

      <div id="enrichment-header-preview" className="preview-btn">
        <Button
          id="enrichment-header-preview-button"
          className="btn item-action-btn"
          onClick={() => setPreviewVisible(true)}
        >
          <Icon id="enrichment-header-preview-button-icon" type="eye" /> {t('enrichmentHeader.preview')}
        </Button>
      </div>
      <PreviewModal id="enrichment-header-preview-modal" visible={previewVisible} setVisible={setPreviewVisible} />
      <RejectModal
        id="reject-all-modal"
        modalVisibility={rejectAllModal}
        setRejectVisibility={setRejectAllModal}
        rejectFunction={(comment) => {
          rejectAll(comment);
        }}
      />
    </div>
  );
}

export default EnrichmentHeader;

EnrichmentHeader.propTypes = {};
