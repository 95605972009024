import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sheetVisibility } from './MsdsSheet';

function SpecSheet({ mediaInfo, idMobile }) {
  const { t } = useTranslation();

  const {
    data: { userRole: userType },
  } = useSelector((state) => state?.userData);

  return (
    <div id={`common-media-widgets-preview-specsheet-${idMobile}`} className="specification-sheets">
      {mediaInfo?.specificationSheet?.length > 0 ? (
        <div id={`common-media-widgets-preview-specsheet-row-${idMobile}`} className="info-row">
          <div
            id={`common-media-widgets-preview-specsheet-row-datablock-${idMobile}`}
            className="data-block no-highlight"
          >
            <div id={`common-media-widgets-preview-specsheet-row-datablock-label-${idMobile}`} className="label">
              {t('mediaWidgetColumn.specificationSheet')}
            </div>
            <ul>
              {mediaInfo.specificationSheet
                ?.filter((sheet) => sheet?.value?.sheetURL && sheetVisibility(sheet, userType))
                ?.map((sheet, i) => (
                  <div
                    id={`common-media-widgets-preview-specsheet-row-datablock-thumbnail-${i + 1}-${idMobile}`}
                    key={`common-media-widgets-preview-specsheet-row-datablock-thumbnail-${sheet?.value?.sheetURL}`}
                    className="file-thumbnail"
                  >
                    <div
                      id={`common-media-widgets-preview-specsheet-row-datablock-fileinfo-${i + 1}-${idMobile}`}
                      className="inner-file-info"
                    >
                      <a
                        id={`common-media-widgets-preview-specsheet-row-datablock-icon-${i + 1}-${idMobile}`}
                        className="icon"
                        href={sheet?.value?.sheetURL}
                        target="_blank"
                      />
                      <div
                        id={`common-media-widgets-preview-specsheet-row-datablock-info-${i + 1}-${idMobile}`}
                        className="info"
                      >
                        <div
                          id={`common-media-widgets-preview-specsheet-row-datablock-filename-${i + 1}-${idMobile}`}
                          className="filename"
                        >
                          <a
                            id={`common-media-widgets-preview-specsheet-row-datablock-fileurl-${i + 1}-${idMobile}`}
                            href={sheet?.value?.sheetURL}
                            target="_blank"
                          >
                            {sheet?.value?.sheetName}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default SpecSheet;
