import React from 'react';
import { useTranslation } from 'react-i18next';

const CONTENT_SERVER_URL = process.env.REACT_APP_CONTENT_SERVER_URL;

function RelatedItems({ mediaInfo }) {
  const { t } = useTranslation();
  return mediaInfo?.relatedItems?.length > 0 ? (
    <div id="common-media-widgets-preview-related-items" className="related-items">
      <div id="common-media-widgets-preview-related-items-row" className="info-row">
        <div id="common-media-widgets-preview-related-items-row-datablock" className="data-block editable no-highlight">
          <div id="common-media-widgets-preview-related-items-row-datablock-label" className="label">
            {t('mediaWidgetColumn.relatedItems')}
          </div>
          <ul>
            {mediaInfo?.relatedItems.map((previewItem) => (
              <li
                id={`common-media-widgets-preview-related-items-row-datablock-thumbnail-${previewItem?.supc}`}
                key={`common-media-widgets-preview-related-items-row-datablock-thumbnail-${previewItem?.supc}`}
                className="thumbnail-block item-thumbnail"
              >
                <a
                  id={`common-media-widgets-preview-related-items-row-datablock-url-${previewItem?.supc}`}
                  href={`${CONTENT_SERVER_URL}/supc/${previewItem?.supc}`}
                  target="_blank"
                >
                  <div
                    id={`common-media-widgets-preview-related-items-row-datablock-image-${
                      previewItem?.imageUrl?.split('/')[previewItem?.imageUrl?.split('/').length - 1]
                    }`}
                    className="image"
                    style={{ background: 'url(' + previewItem?.imageUrl + ') no-repeat center center/cover' }}
                  />
                </a>
                <div
                  id={`common-media-widgets-preview-related-items-row-datablock-info-${previewItem?.supc}`}
                  className="info"
                >
                  <a
                    id={`common-media-widgets-preview-related-items-row-datablock-itemurl-${previewItem?.supc}`}
                    className="related-items"
                    href={`${CONTENT_SERVER_URL}/supc/${previewItem?.supc}`}
                    target="_blank"
                  >
                    <div
                      id={`common-media-widgets-preview-related-items-row-datablock-value-field-${previewItem?.supc}`}
                      className="value-field"
                    >
                      <span>SUPC</span> {previewItem?.supc}
                    </div>
                    <div
                      id={`common-media-widgets-preview-related-items-row-datablock-title-${previewItem?.supc}`}
                      className="title"
                    >
                      {previewItem?.description}{' '}
                    </div>
                    <div
                      id={`common-media-widgets-preview-related-items-row-datablock-subtitle-${previewItem?.supc}`}
                      className="subtitle"
                    >
                      {previewItem?.brand}
                    </div>
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ) : null;
}

export default RelatedItems;
