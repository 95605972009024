import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popconfirm } from 'antd';

import { SPECIFICATION_SHEET, RECIPES, MSDS_SHEET } from 'enums/AttributeType';
import { approvePendingFile, deleteFile, updateStagingAttribute } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../../_i18n';
import AppLoader from '../../../components/AppLoader';
import UploadModalSingle from '../../../components/modals/UploadModalSingle';
import Recipes from './Recipes';
import SpecSheet from './SpecSheet';
import {
  APPROVED,
  DELETE_APPROVED,
  DELETE_PENDING,
  DELETE_REJECTED,
  PENDING,
  REJECTED,
} from '../../../enums/ApprovalStatus';
import { RECIPE_PREFIX, SPECIFICATION_PREFIX, MSDS_PREFIX } from '../../../constants/Constants';
import RejectModal from '../../../components/modals/RejectModal';
import { attributeEditorDetails, isValidToDisplay } from '../../../utils/Utils';

const CONTENT_SERVER_URL = process.env.REACT_APP_CONTENT_SERVER_URL;

const fileCSSApplier = (approvalStatus, isChangedUser) => {
  if (approvalStatus === APPROVED && isChangedUser) {
    return 'file-thumbnail highlighted approved';
  } else if (approvalStatus === DELETE_APPROVED && isChangedUser) {
    return 'file-thumbnail highlighted approved';
  } else if (approvalStatus === REJECTED && isChangedUser) {
    return 'file-thumbnail highlighted rejected';
  } else if (approvalStatus === DELETE_REJECTED && isChangedUser) {
    return 'file-thumbnail highlighted rejected';
  } else if (approvalStatus === DELETE_PENDING) {
    return 'file-thumbnail highlighted rejected';
  } else if (approvalStatus === PENDING) {
    return 'file-thumbnail highlighted';
  } else {
    return 'file-thumbnail';
  }
};

function setRecipeStatus(recipe, statusIfTrue, statusIfFalse) {
  return recipe?.attributeStatus === DELETE_PENDING ? statusIfTrue : statusIfFalse;
}

function CommonMediaWidgets({ mediaInfo, supc, id, permissions }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fetching: deleteFetching } = useSelector((state) => state?.deleteFile);
  const { fetching: itemFetching } = useSelector((state) => state.item);
  const {
    data: { fullName: userId, userRole: userType, associatedUsers },
  } = useSelector((state) => state.userData); //userType= MDM , VENDOR
  const { fetching: updateFetching } = useSelector((state) => state.updateAttribute);
  const { fetching: approveFetching } = useSelector((state) => state.approvePendingFile);

  const [showRecipeUpload, setShowRecipeUpload] = useState(false);
  const [showSpecSheetUpload, setShowSpecSheetUpload] = useState(false);
  const [showMsdsSheetUpload, setShowMsdsSheetUpload] = useState(false);
  const [rejectRecipeModalVisibility, setRejectRecipeModalVisibility] = useState(false);
  const [rejectSpecModalVisibility, setRejectSpecModalVisibility] = useState(false);
  const [rejectMsdsModalVisibility, setRejectMsdsModalVisibility] = useState(false);
  const [recipeUnderEdit, setRecipeUnderEdit] = useState(null);
  const [specUnderEdit, setSpecUnderEdit] = useState(null);
  const [msdsUnderEdit, setMsdsUnderEdit] = useState(null);

  const handleFileDelete = async (fileName, uploadType, file, files, attributeStatus, identifier) => {
    const updatedFile = {
      attributeType: uploadType,
      value: file?.value,
      attributeStatus: attributeStatus,
      comment: null,
      author: userId,
      authorRole: userType,
      timestamp: null,
      identifier: identifier,
    };

    dispatch(
      deleteFile(
        fileName,
        uploadType,
        supc,
        userId,
        i18n.language,
        attributeStatus,
        updatedFile,
        userType,
        associatedUsers
      )
    );
  };

  const handleApprovingPendingFile = async (
    fileName,
    copySource,
    destination,
    file,
    fileList,
    uploadType,
    attributeStatus,
    identifier
  ) => {
    dispatch(
      approvePendingFile(
        i18n.language,
        uploadType,
        supc,
        userId,
        fileName,
        copySource,
        destination,
        file,
        fileList,
        attributeStatus,
        identifier,
        userType,
        associatedUsers
      )
    );
  };

  const handleSave = (attributeType, file, status, comment, identifier) => {
    const updatedFile = {
      ...file,
      attributeStatus: status,
      comment,
      author: userId,
      identifier: identifier,
      authorRole: userType,
    };

    if (file?.attributeStatus === DELETE_APPROVED && null === status) {
      if (attributeType === RECIPES) {
        dispatch(
          deleteFile(
            file?.value?.recipeName,
            attributeType,
            supc,
            userId,
            i18n.language,
            status,
            updatedFile,
            userType,
            associatedUsers
          )
        );
      } else {
        dispatch(
          deleteFile(
            file?.value?.sheetName,
            attributeType,
            supc,
            userId,
            i18n.language,
            status,
            updatedFile,
            userType,
            associatedUsers
          )
        );
      }
    } else {
      const attribute = {
        attributeType: attributeType,
        value: [updatedFile],
        attributeStatus: status,
        comment,
        author: userId,
        timestamp: null,
        authorRole: userType,
      };
      dispatch(updateStagingAttribute(supc, i18n.language, attribute, associatedUsers));
    }
  };

  const completeApprovingPendingFile = (recipe) => {
    let urlArray = recipe?.value?.recipeURL.split('/');

    handleApprovingPendingFile(
      recipe?.value?.recipeName,
      'staging/' + RECIPE_PREFIX + urlArray[urlArray.length - 1],
      RECIPE_PREFIX + urlArray[urlArray.length - 1],
      recipe,
      mediaInfo?.recipes,
      RECIPES,
      setRecipeStatus(recipe, DELETE_APPROVED, APPROVED),
      recipe?.value?.recipeURL
    );
  };

  return itemFetching || deleteFetching || updateFetching || approveFetching ? (
    <AppLoader show />
  ) : (
    <>
      <div id="common-media-widgets-spec-sheets" className="specification-sheets">
        {permissions.SPECIFICATION_SHEET.visible && (
          <SpecSheet
            isValidToDisplay={isValidToDisplay}
            handleFileDelete={handleFileDelete}
            attributeEditorDetails={attributeEditorDetails}
            setShowSpecSheetUpload={setShowSpecSheetUpload}
            setRejectModalVisibility={setRejectSpecModalVisibility}
            fileCSSApplier={fileCSSApplier}
            handleApprovingPendingFile={handleApprovingPendingFile}
            handleSave={handleSave}
            sheetType={SPECIFICATION_SHEET}
            mediaSheet={mediaInfo?.specificationSheet}
            prefix={SPECIFICATION_PREFIX}
            attributePermission={permissions?.SPECIFICATION_SHEET}
            id={id}
            setSpecUnderEdit={setSpecUnderEdit}
          />
        )}
      </div>
      <UploadModalSingle
        id="common-media-widgets-spec-sheets-upload-modal"
        visible={showSpecSheetUpload}
        loading={false}
        uploadType={SPECIFICATION_SHEET}
        supc={supc}
        visibleSetter={setShowSpecSheetUpload}
        existing={mediaInfo?.specificationSheet}
        approvable={permissions?.SPECIFICATION_SHEET?.approvable}
        urls={mediaInfo?.specificationSheet?.map((rec) => rec?.value?.sheetURL)}
      />
      <RejectModal
        id="common-media-widgets-spec-sheets-reject-modal"
        modalVisibility={rejectSpecModalVisibility}
        setRejectVisibility={setRejectSpecModalVisibility}
        rejectFunction={(comment) => {
          handleSave(
            SPECIFICATION_SHEET,
            specUnderEdit,
            setRecipeStatus(specUnderEdit, DELETE_REJECTED, REJECTED),
            comment,
            specUnderEdit?.value?.sheetURL
          );
        }}
        rejectAttribute={i18n.t('mediaWidgetColumn.specificationSheet')}
      />

      <div id="common-media-widgets-recipes" className="recipes">
        {permissions.RECIPES.visible && (
          <Recipes
            id={id}
            mediaInfo={mediaInfo}
            attributeManagement={permissions}
            isValidToDisplay={isValidToDisplay}
            fileCSSApplier={fileCSSApplier}
            completeApprovingPendingFile={completeApprovingPendingFile}
            setRejectModalVisibility={setRejectRecipeModalVisibility}
            setRecipeUnderEdit={setRecipeUnderEdit}
            handleSave={handleSave}
            handleFileDelete={handleFileDelete}
            attributeEditorDetails={attributeEditorDetails}
            setShowRecipeUpload={setShowRecipeUpload}
          />
        )}
      </div>
      <UploadModalSingle
        id="common-media-widgets-recipes-upload-modal"
        visible={showRecipeUpload}
        loading={false}
        uploadType={RECIPES}
        supc={supc}
        visibleSetter={setShowRecipeUpload}
        existing={mediaInfo?.recipes}
        approvable={permissions?.RECIPES?.approvable}
        urls={mediaInfo?.recipes?.map((rec) => rec?.value?.recipeURL)}
      />
      <RejectModal
        id="common-media-widgets-recipes-reject-modal"
        modalVisibility={rejectRecipeModalVisibility}
        setRejectVisibility={setRejectRecipeModalVisibility}
        rejectFunction={(comment) => {
          handleSave(
            RECIPES,
            recipeUnderEdit,
            setRecipeStatus(recipeUnderEdit, DELETE_REJECTED, REJECTED),
            comment,
            recipeUnderEdit?.value?.recipeURL
          );
        }}
        rejectAttribute={i18n.t('mediaWidgetColumn.additionalResources')}
      />

      <div id="common-media-widgets-related-items" className="related-items">
        <div id="common-media-widgets-related-items-row" className="info-row">
          <div id="common-media-widgets-related-items-row-datablock" className="data-block editable no-highlight">
            <div id="common-media-widgets-related-items-row-datablock-label" className="label">
              {t('mediaWidgetColumn.relatedItems')} {/*<Dropdown overlay={dataBlockMenu}>*/}
              {/*    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>*/}
              {/*        <Icon className="icon options-menu-icon" type="more"/>*/}
              {/*    </a>*/}
              {/*</Dropdown>*/}
              {/*<i className="icon add-data-block fi flaticon-add-button-inside-black-circle"/>*/}
            </div>
            {mediaInfo?.relatedItems?.length > 0 && (
              <ul id="common-media-widgets-related-items-row-datablock-label-list">
                {mediaInfo?.relatedItems.map((item) => (
                  <li
                    id={`common-media-widgets-related-items-row-datablock-label-list-item-${item?.supc}`}
                    key={`common-media-widgets-related-items-row-datablock-label-list-item-${item?.supc}`}
                    className="thumbnail-block item-thumbnail"
                  >
                    <a
                      id={`common-media-widgets-related-items-row-datablock-label-list-item-url-${item?.supc}`}
                      href={`${CONTENT_SERVER_URL}/supc/${item?.supc}`}
                      target="_blank"
                    >
                      <div
                        id={`common-media-widgets-related-items-row-datablock-label-list-item-image-${
                          item?.imageUrl?.split('/')[item?.imageUrl?.split('/').length - 1]
                        }`}
                        className="image"
                        style={{ background: 'url(' + item?.imageUrl + ') no-repeat center center/cover' }}
                      />
                    </a>
                    <div
                      id={`common-media-widgets-related-items-row-datablock-label-list-item-info-${item?.supc}`}
                      className="info"
                    >
                      <a
                        id={`common-media-widgets-related-items-row-datablock-label-list-item-info-url-${item?.supc}`}
                        className="related-items"
                        href={`${CONTENT_SERVER_URL}/supc/${item?.supc}`}
                        target="_blank"
                      >
                        <div
                          id={`common-media-widgets-related-items-row-datablock-label-list-item-value-${item?.supc}`}
                          className="value-field"
                        >
                          <span
                            id={`common-media-widgets-related-items-row-datablock-label-list-item-value-supc-${item?.supc}`}
                          >
                            SUPC
                          </span>{' '}
                          {item?.supc}
                        </div>
                        <div
                          id={`common-media-widgets-related-items-row-datablock-label-list-item-title-${item?.supc}`}
                          className="title"
                        >
                          {item?.description}{' '}
                          <Popconfirm
                            id={`common-media-widgets-related-items-row-datablock-label-list-item-pop-confirm-${item?.supc}`}
                            title="Are you sure delete this?"
                            okText="Yes"
                            cancelText="No"
                          >
                            <i
                              id={`common-media-widgets-related-items-row-datablocklabel-list-item-pop-confirm-delete-${item?.supc}`}
                              className="delete-icon fi flaticon-delete"
                            />
                          </Popconfirm>
                        </div>
                        <div
                          id={`common-media-widgets-related-items-row-datablocklabel-list-item-subtitle-${item?.supc}`}
                          className="subtitle"
                        >
                          {item?.brand}
                        </div>
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>

      <div id="common-media-widgets-msds-sheets" className="msds-sheets">
        {permissions.MSDS_SHEET.visible && (
          <SpecSheet
            isValidToDisplay={isValidToDisplay}
            handleFileDelete={handleFileDelete}
            attributeEditorDetails={attributeEditorDetails}
            setShowSpecSheetUpload={setShowMsdsSheetUpload}
            setRejectModalVisibility={setRejectMsdsModalVisibility}
            fileCSSApplier={fileCSSApplier}
            handleApprovingPendingFile={handleApprovingPendingFile}
            handleSave={handleSave}
            sheetType={MSDS_SHEET}
            mediaSheet={mediaInfo?.msdsSheet}
            prefix={MSDS_PREFIX}
            attributePermission={permissions?.MSDS_SHEET}
            id={id}
            setSpecUnderEdit={setMsdsUnderEdit}
          />
        )}
      </div>
      <UploadModalSingle
        id="common-media-widgets-msds-sheets-upload-modal"
        visible={showMsdsSheetUpload}
        loading={false}
        uploadType={MSDS_SHEET}
        supc={supc}
        visibleSetter={setShowMsdsSheetUpload}
        existing={mediaInfo?.msdsSheet}
        approvable={permissions?.MSDS_SHEET?.approvable}
        urls={mediaInfo?.msdsSheet?.map((rec) => rec?.value?.sheetURL)}
      />
      <RejectModal
        id="common-media-widgets-msds-sheets-reject-modal"
        modalVisibility={rejectMsdsModalVisibility}
        setRejectVisibility={setRejectMsdsModalVisibility}
        rejectFunction={(comment) => {
          handleSave(
            MSDS_SHEET,
            msdsUnderEdit,
            setRecipeStatus(msdsUnderEdit, DELETE_REJECTED, REJECTED),
            comment,
            msdsUnderEdit?.value?.sheetURL
          );
        }}
        rejectAttribute={i18n.t('mediaWidgetColumn.msdsSheet')}
      />
    </>
  );
}

export default CommonMediaWidgets;
