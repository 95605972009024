import React from 'react';
import { Modal } from 'antd';
import ProductSheetPreview from '../preview/productSheet/ProductSheetPreview';

export default function PreviewModal({ visible, setVisible }) {
  return (
    <>
      <Modal
        id="product-sheet-preview-modal"
        centered
        visible={visible}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        width={1200}
        footer={[]}
        forceRender={true}
      >
        <ProductSheetPreview id="product-sheet-preview-component" />
      </Modal>
    </>
  );
}
