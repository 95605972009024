import React from 'react';
import i18n from 'i18next';

export default function Brand({ coreData }) {
  return (
    <div id="info-column-preview-info-row-8" className="info-row">
      {coreData?.brandLogoURL || coreData?.aboutBrand?.aboutTheBrand?.value ? (
        <div id="info-column-preview-datablock-about-brand" className="data-block">
          <div id="info-column-preview-datablock-about-brand-label" className="label">
            {i18n.t('infoColumn.aboutTheBrand')}
          </div>
          <div id="info-column-preview-datablock-about-brand-data" className="data description">
            {coreData?.brandLogoURL && (
              <img className="inline-image info-column-brand-logo" src={coreData?.brandLogoURL} />
            )}
            {coreData?.aboutBrand?.aboutTheBrand?.value}
          </div>
        </div>
      ) : null}
    </div>
  );
}
