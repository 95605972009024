import React, { useState, useEffect } from 'react';
import CommonMediaWidgetsPreview from './CommonMediaWidgetsPreview';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import { LIFESTYLE_IMAGE, VIDEO } from '../../../../../enums/AttributeType';
import { getMainImageForPreview, isValidToDisplay, isValidToDisplayWhenDeleteApproved } from '../../../../../utils/Utils';
import { DELETE_APPROVED, REJECTED } from '../../../../../enums/ApprovalStatus';

function generateVideoSource(media) {
  return media?.videos?.length > 0 && (media?.mainImage === '' || media?.mainImage === null)
    ? media?.videos[0]?.value?.url
    : '';
}

function generateVideoOrImage(media) {
  return media?.videos?.length > 0 && media?.videos[0]?.value && (media?.mainImage === '' || media?.mainImage === null)
    ? true
    : false;
}

const isNotDeleted = (image) => {
  if (image?.attributeStatus !== DELETE_APPROVED && image?.attributeStatus !== REJECTED) {
    return true;
  }
  return false;
};

function getNewMainImage(media, setIsOtherImageUrls, userRole, enrichable) {
  if (media && media.otherImageUrls) {
    if (media?.otherImageUrls[0]?.value?.url !== null) {
      setIsOtherImageUrls(true);
    } else {
      setIsOtherImageUrls(false);
    }
  }
  let newMainImage = null;
  var selectedMainImage = getMainImageForPreview(media, userRole, enrichable);
  if (selectedMainImage?.attributeType === LIFESTYLE_IMAGE) {
    newMainImage = isValidToDisplay(selectedMainImage,userRole) ? selectedMainImage?.value?.url : selectedMainImage?.previousValue?.url;
  } else if (selectedMainImage?.url) {
    newMainImage = selectedMainImage?.url;
  } else if (selectedMainImage?.attributeType === VIDEO) {
    newMainImage = selectedMainImage?.value?.thumbnail;
  } else {
    newMainImage = selectedMainImage?.value?.url;
  }
  return newMainImage;
}

const ChangeMainImage = (targetelem, isVideo, videoThumbnail, setMainImage, setisVideoImage, setvideoSrc) => {
  setMainImage(isVideo ? videoThumbnail : targetelem);
  isVideo ? setVideoParams(targetelem, setvideoSrc, setisVideoImage) : setisVideoImage(false);
};

const setVideoParams = (videoUrl, setvideoSrc, setisVideoImage) => {
  setvideoSrc(videoUrl);
  setisVideoImage(true);
};

const togglePreview = (setShowPreview, showPreview, isVideoImage, setisVideoPreview) => {
  setShowPreview(!showPreview);
  isVideoImage ? setisVideoPreview(true) : setisVideoPreview(false);
};

const getLifestyleImageUrlToDisplay = (lifestyleImage,userRole) =>{
  return isValidToDisplay(lifestyleImage,userRole) ? lifestyleImage?.value : lifestyleImage?.previousValue
}

function MediaColumnPreview({ media, mediaInfo, enrichable }) {
  const {
    data: { userRole },
  } = useSelector((state) => state.userData);
  const [mainImage, setMainImage] = useState(null);
  const [videoSrc, setvideoSrc] = useState(generateVideoSource(media));
  const [isVideoImage, setisVideoImage] = useState(generateVideoOrImage(media));
  const [showPreview, setShowPreview] = useState(false);
  const [isVideoPreview, setisVideoPreview] = useState(false);
  const [isOtherImageUrls, setIsOtherImageUrls] = useState(false);

  useEffect(() => {
    setMainImage(getNewMainImage(media, setIsOtherImageUrls, userRole, enrichable));
  }, [media]);

  const images = [];

  media?.lifestyleImageUrl?.value?.url && enrichable &&
   isValidToDisplayWhenDeleteApproved(media?.lifestyleImageUrl, userRole) &&
   getLifestyleImageUrlToDisplay(media?.lifestyleImageUrl,userRole)&&
    images.push(
      <li
        className="ls-image"
        id={`media-column-preview-lifestyle-${getLifestyleImageUrlToDisplay(media?.lifestyleImageUrl,userRole)?.imageID}`}
        key={`media-column-preview-lifestyle-${getLifestyleImageUrlToDisplay(media?.lifestyleImageUrl,userRole)?.url}`}
        style={{ background: 'url(' + getLifestyleImageUrlToDisplay(media?.lifestyleImageUrl,userRole)?.url + ') no-repeat center center/contain' }}
        onClick={() =>
          ChangeMainImage(getLifestyleImageUrlToDisplay(media?.lifestyleImageUrl,userRole)?.url, null, null, setMainImage, setisVideoImage, setvideoSrc)
        }
      />
    );
    
  media?.gs1Images?.length > 0 &&
    images.push(
      media?.gs1Images
        ?.filter((image) => image?.url)
        ?.map((image) => (
          <li
            className="gs1-image"
            id={`media-column-preview-gs1-${image?.url}`}
            key={`media-column-preview-gs1-${image?.url}`}
            style={{ background: 'url(' + image?.url + ') no-repeat center center/contain' }}
            onClick={() => ChangeMainImage(image?.url, null, null, setMainImage, setisVideoImage, setvideoSrc)}
          />
        ))
    );

  isOtherImageUrls && enrichable &&
    images.push(
      media?.otherImageUrls
        ?.filter((image) => image && image?.value?.url && isValidToDisplay(image,userRole))
        ?.map((image) => (
          <li
            className="other-image"
            id={`media-column-preview-other-images-${image?.value?.url}`}
            key={`media-column-preview-other-images-${image?.value?.url}`}
            style={{ background: 'url(' + image?.value?.url + ') no-repeat center center/contain' }}
            onClick={() => ChangeMainImage(image?.value?.url, null, null, setMainImage, setisVideoImage, setvideoSrc)}
          />
        ))
    );

  media?.videos?.length > 0 && enrichable &&
    images.push(
      media?.videos
        ?.filter((video) => video && video?.value?.url)
        ?.map((video) => (
          <div
            key={`media-column-preview-other-images-${video?.value?.url}`}
            className="video-thumbnail-tile"
            onClick={() =>
              ChangeMainImage(
                video?.value?.url,
                true,
                video?.value?.thumbnail,
                setMainImage,
                setisVideoImage,
                setvideoSrc
              )
            }
          >
            <li
              className="video-thumbnail-tile-img"
              id={`media-column-preview-other-images-${video?.value?.url}`}
              key={`media-column-preview-other-images-${video?.value?.url}`}
              style={{ background: 'url(' + video?.value?.thumbnail + ') no-repeat center center/contain' }}
            />
          </div>
        ))
    );

    console.log("images?.length: ", images?.length);
    console.log("media?.videos?.length: ", media?.videos?.length)

  return (
    <div id="media-column-preview" className="media-column animated fadeIn">
      <div id="media-column-preview-images" className="product-images">
        {(images?.length > 0 || (media?.videos?.length > 0 && enrichable)) && (
          <div id="media-column-preview-images-main-image-outer" className="main editable">
            <div
              id="media-column-preview-images-main-image-inner"
              className={
                isVideoImage ? 'video-thumbnail main-img is-video main-img-preview' : 'main-img main-img-preview'
              }
              onClick={() => togglePreview(setShowPreview, showPreview, isVideoImage, setisVideoPreview)}
            >
              <img
                id={
                  mainImage &&
                  `media-column-preview-images-main-image-${mainImage.split('/')[mainImage.split('/').length - 1]}`
                }
                src={mainImage}
              />
            </div>
          </div>
        )}
        <ul>{images?.length > 0 && images}</ul>
        {showPreview && (
          <div
            id="media-column-preview-toggle"
            className="preview"
            onClick={() => togglePreview(setShowPreview, showPreview, isVideoImage, setisVideoPreview)}
          >
            <div id="media-column-preview-video-modal" className="modal">
              {isVideoPreview ? (
                <video
                  id="media-column-preview-video"
                  width="320"
                  height="240"
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  controlsList="nodownload"
                  controls
                >
                  <source id="media-column-preview-video-source" src={videoSrc} type="video/mp4" />
                  {i18n.t('mediaColumn.browserMessage')}
                </video>
              ) : (
                <img id="media-column-preview-video-image" src={mainImage} />
              )}
            </div>
          </div>
        )}
      </div>
      {enrichable && <CommonMediaWidgetsPreview id="common-media-widgets-preview-component" mediaInfo={mediaInfo} idMobile="id" />}
    </div>
  );
}

export default MediaColumnPreview;
