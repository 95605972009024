import { createRequestTypes } from 'reduxHelpers';

const ITEM = createRequestTypes('ITEM');
const ITEM_CATALOG = createRequestTypes('ITEM_CATALOG');
const VENDOR = createRequestTypes('VENDOR');
const ITEM_APPROVAL_PENDING_VENDOR = createRequestTypes('ITEM_APPROVAL_PENDING_VENDOR');
const CATEGORIES = createRequestTypes('CATEGORIES');
const CLASSES = createRequestTypes('CLASSES');
const UPDATE_ATTRIBUTE = createRequestTypes('UPDATE_ATTRIBUTE');
const APPROVE_ATTRIBUTE = createRequestTypes('APPROVE_ATTRIBUTE');
const ATTRIBUTES = createRequestTypes('ATTRIBUTES');
const SIGNED_URL = createRequestTypes('SIGNED_URL');
const UPLOAD_FILE = createRequestTypes('UPLOAD_FILE');
const AUDITING_DETAILS = createRequestTypes('AUDITING_DETAILS');
const DOWNLOAD_AUDIT = createRequestTypes('DOWNLOAD_AUDIT');
const DOWNLOAD_ITEMS = createRequestTypes('DOWNLOAD_ITEMS');
const DELETE_FILE = createRequestTypes('DELETE_FILE');
const BULK_UPLOAD = createRequestTypes('BULK_UPLOAD');
const USER_DATA = createRequestTypes('USER_DATA');
const VENDOR_SCORE = createRequestTypes('VENDOR_SCORE');
const APPROVE_PENDING_FILE = createRequestTypes('APPROVE_PENDING_FILE');
const REPORT_ERROR = createRequestTypes('REPORT_ERROR');
const UPDATED_ITEM = createRequestTypes('UPDATED_ITEM');

export {
  ITEM,
  ITEM_CATALOG,
  VENDOR,
  ITEM_APPROVAL_PENDING_VENDOR,
  UPDATE_ATTRIBUTE,
  APPROVE_ATTRIBUTE,
  ATTRIBUTES,
  SIGNED_URL,
  UPLOAD_FILE,
  CATEGORIES,
  DELETE_FILE,
  AUDITING_DETAILS,
  DOWNLOAD_AUDIT,
  DOWNLOAD_ITEMS,
  BULK_UPLOAD,
  USER_DATA,
  CLASSES,
  VENDOR_SCORE,
  APPROVE_PENDING_FILE,
  REPORT_ERROR,
  UPDATED_ITEM,
};
