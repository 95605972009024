import FileSaver from 'file-saver';
import Excel from 'exceljs';
import i18n from 'i18next';

export function downloadItemList(data, language){
  const workbook = new Excel.Workbook();

  const downloadHeaders = [
    { header: i18n.t('infoColumn.supc'), key: 'supc', width: 10 },
    { header: i18n.t('infoColumn.gtin'), key: 'gtin', width: 14 },
    { header: i18n.t('infoColumn.mpc'), key: 'mpc'},
    { header: i18n.t('itemList.brand'), key: 'brand' },
    { header: i18n.t('itemList.pack'), key: 'pack' },
    { header: i18n.t('itemList.itemSize'), key: 'size' },
    { header: i18n.t('itemList.description'), key: 'description' },
    { header: i18n.t('infoColumn.productDescriptor'), key: 'productDescriptor' },
    { header: i18n.t('infoColumn.packagingInformation'), key: 'packagingInformation' },
    { header: i18n.t('infoColumn.sizeAndShapeOfProduct'), key: 'sizeAndShapeOfProduct' },
    { header: i18n.t('infoColumn.yieldOrServingSize'), key: 'yieldOrServingSize' },
    { header: i18n.t('infoColumn.qualityAndFormat'), key: 'qualityAndFormat' },
    { header: i18n.t('infoColumn.preparationAndCookingInstructions'), key: 'preparationAndCookingInstructions' },
    { header: i18n.t('infoColumn.storageAndUsage'), key: 'storageAndUsage' },
    { header: i18n.t('infoColumn.handlingInstructions'), key: 'handlingInstructions' },
    { header: i18n.t('infoColumn.additionalProductInformation'), key: 'additionalProductInformation' },
    { header: i18n.t('infoColumn.marketingStatements'), key: 'marketingStatements' },
    { header: i18n.t('infoColumn.culinaryApplications'), key: 'culinaryApplications' },
    { header: i18n.t('upload.lifestyleImage'), key: 'lifeStyleImage' },
    { header: i18n.t('upload.productImage'), key: 'productImage' },
    { header: i18n.t('downloadList.gs1Image'), key: 'gs1Image' },
    { header: i18n.t('downloadList.video'), key: 'video' }, 
    { header: i18n.t('downloadList.attributes'), key: 'attributes' },
  ];

  const worksheet = workbook.addWorksheet('Sheet 1');
  worksheet.columns = downloadHeaders;
  worksheet.getRow(1).font  = {
    bold: true
  };

  worksheet.addRows(downloadData(data, language));

  workbook.xlsx.writeBuffer().then((i) => {
    const blob = new Blob([i], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blob, 'Item List.xlsx');
  });
}

export const downloadData = (result, language) => {
  if (result?.length > 0) {
    return result.map((item) => {
      return {
        supc: item?.supc,
        gtin: item?.gtin,
        mpc: item?.mpc,
        brand: setLanguageSpecificValue(language, item?.enBrand, item?.frBrand),
        description: setLanguageSpecificValue(language, item?.en?.description?.unabbreviatedDescription, item?.fr?.description?.unabbreviatedDescription ),
        pack: item?.pack,
        size: item?.size,
        lifeStyleImage: item?.lifeStyleImage,
        productImage: item?.productImage,
        gs1Image: item?.gs1Image,
        video: item?.video,
        productDescriptor: setLanguageSpecificValue(language, item?.en?.featuresAndBenefits?.productDescriptor, item?.fr?.featuresAndBenefits?.productDescriptor),
        packagingInformation: setLanguageSpecificValue(language, item?.en?.featuresAndBenefits?.packagingInformation, item?.fr?.featuresAndBenefits?.packagingInformation),
        sizeAndShapeOfProduct: setLanguageSpecificValue(language, item?.en?.featuresAndBenefits?.sizeAndShapeOfProduct, item?.fr?.featuresAndBenefits?.sizeAndShapeOfProduct),
        yieldOrServingSize: setLanguageSpecificValue(language, item?.en?.featuresAndBenefits?.yieldOrServingSize, item?.fr?.featuresAndBenefits?.yieldOrServingSize),
        qualityAndFormat: setLanguageSpecificValue(language, item?.en?.featuresAndBenefits?.qualityAndFormat, item?.fr?.featuresAndBenefits?.qualityAndFormat),
        preparationAndCookingInstructions: setLanguageSpecificValue(language, item?.en?.featuresAndBenefits?.preparationAndCookingInstructions, item?.fr?.featuresAndBenefits?.preparationAndCookingInstructions),
        storageAndUsage: setLanguageSpecificValue(language, item?.en?.featuresAndBenefits?.storageAndUsage, item?.fr?.featuresAndBenefits?.storageAndUsage),
        handlingInstructions: setLanguageSpecificValue(language, item?.en?.featuresAndBenefits?.handlingInstructions, item?.fr?.featuresAndBenefits?.handlingInstructions),
        additionalProductInformation: setLanguageSpecificValue(language, item?.en?.featuresAndBenefits?.additionalProductInformation, item?.fr?.featuresAndBenefits?.additionalProductInformation),
        marketingStatements: setLanguageSpecificValue(language, item?.en?.featuresAndBenefits?.marketingStatements, item?.fr?.featuresAndBenefits?.marketingStatements),
        culinaryApplications: setLanguageSpecificValue(language, item?.en?.featuresAndBenefits?.culinaryApplications, item?.fr?.featuresAndBenefits?.culinaryApplications),
        attributes: setLanguageSpecificValue(language, item?.enAttributes, item?.frAttributes)
      };
    });
  } else {
    return [];
  }
};

export function setLanguageSpecificValue(language, englishValue, frenchValue) {
  return language == "en" ? englishValue : frenchValue;
}

export default downloadItemList;