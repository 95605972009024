import React from 'react';
import MediaColumnPreview from './components/MediaColumn/MediaColumnPreview';
import MediaColumnMobilePreview from './components/MediaColumn/MediaColumnMobilePreview';
import InfoColumnPreview from './components/InfoColumn/infoColumnPreview';
import NutritionalColumnPreview from './components/NutritionalColumn/NutritionalColumnPreview';
import { useSelector } from 'react-redux';

function ProductSheetPreview() {
  const { data: itemData } = useSelector((state) => state.item);
  const  enrichable = itemData?.enrichable;

  console.log("preview enrichable: ", enrichable);
  
  return (
      <div id="productsheet-wrapper" className="wrapper product-sheet-wrapper">
        <MediaColumnPreview id="media-column-preview-component" media={itemData.media} mediaInfo={itemData.mediaInfo} enrichable={enrichable}/>
        <InfoColumnPreview id="info-column-preview-component" coreData={itemData.coreData} flags={itemData.flags} enrichable={enrichable}/>
        <NutritionalColumnPreview
          id="nutritional-column-preview-component"
          nutritionalInfo={itemData.nutritionalInformation}
        />
        {/* {enrichable && <MediaColumnMobilePreview id="media-column-mobile-preview-component" mediaInfo={itemData.mediaInfo}/>} */}
      </div>
  );
}

export default ProductSheetPreview;
