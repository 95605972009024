import React from 'react';
import i18n from 'i18next';
import { Button, Icon, Tooltip } from 'antd';
import { LIFESTYLE_IMAGE } from 'enums/AttributeType';
import { attributeEditorDetails } from '../../../utils/Utils';
import { REJECTED, DELETE_REJECTED } from '../../../enums/ApprovalStatus';

function LifestyleImageRejected({ media, currentImage, handleDismiss, userRole }) {
  return (
    media?.lifestyleImageUrl?.authorRole === userRole &&
    (media?.lifestyleImageUrl?.attributeStatus === REJECTED ||
      media?.lifestyleImageUrl?.attributeStatus === DELETE_REJECTED) &&
    currentImage?.attributeType === LIFESTYLE_IMAGE && (
      <div id="media-column-lifestyle-images-footer-bar-rejected" className="footer-bar rejected">
        <Icon
          id="media-column-lifestyle-images-footer-bar-rejected-icon"
          type="rejected"
          theme="filled"
          className="icon status-icon warning"
        />
        <div id="media-column-lifestyle-images-footer-bar-rejected-label" className="label">
          {media?.lifestyleImageUrl?.attributeStatus === DELETE_REJECTED
            ? i18n.t('mediaColumn.rejectedDelete')
            : i18n.t('mediaColumn.rejected')}
        </div>
        {media?.lifestyleImageUrl?.authorRole === userRole && (
          <Button
            id="media-column-lifestyle-images-footer-bar-rejected-dismiss"
            className="btn"
            onClick={() => {
              handleDismiss(media?.lifestyleImageUrl?.value?.imageID, LIFESTYLE_IMAGE, media?.lifestyleImageUrl, null);
            }}
          >
            {i18n.t('infoColumn.dismiss')}
          </Button>
        )}
        {media?.lifestyleImageUrl?.authorRole === userRole && (
          <Tooltip
            id="media-column-lifestyle-images-footer-bar-rejected-tooltip"
            overlayStyle={{ whiteSpace: 'pre-line' }}
            title={
              attributeEditorDetails(userRole, media?.lifestyleImageUrl?.attributeStatus, currentImage) +
              '\n' +
              i18n.t('infoColumn.reason') +
              ': ' +
              currentImage?.comment
            }
          >
            <i
              id="media-column-lifestyle-images-footer-bar-rejected-tooltip-icon"
              className="info-icon icon fi flaticon-information-button"
            />
          </Tooltip>
        )}
      </div>
    )
  );
}

export default LifestyleImageRejected;
