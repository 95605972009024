import React from 'react';
import ReadMoreReadLess from '../../../../../productSheet/components/ReadMoreReadLess/ReadMoreReadLess';

function ItemOverview({ id, value }) {
  return value ? (
    <div id={`${id}-row`} className="info-row preview-product-descriptor-wrap">
      <div id={`${id}-datablock`} className="data-block editable">
        <div id={`${id}-data-description`} className="data description">
          <div id={id} className="overview">
            <ReadMoreReadLess id={`${id}-read-more`} fullText={value} charLimit="250" />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default ItemOverview;
