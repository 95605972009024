import i18n from 'i18next';

export function getSampleDownload(){
  return {
    supc: '0000000',
    unabbreviatedDescription: i18n.t('fileExample.unabbreviatedDescription'),
    lifeStyleImage: 'https://public-content-server/0000000.jpeg',
    videos: 'https://public-content-server/item_images/others/samplevideo.mp4',
    productDescriptor: i18n.t('fileExample.productDescriptor'),
    packagingInformation: i18n.t('fileExample.packagingInformation'),
    sizeAndShapeOfProduct: i18n.t('fileExample.sizeAndShapeOfProduct'),
    yieldOrServingSize: i18n.t('fileExample.yieldOrServingSize'),
    qualityAndFormat: i18n.t('fileExample.qualityAndFormat'),
    preparationAndCookingInstructions: i18n.t('fileExample.preparationAndCookingInstructions'),
    storageAndUsage: i18n.t('fileExample.storageAndUsage'),
    handlingInstructions: i18n.t('fileExample.handlingInstructions'),
    additionalProductInformation: i18n.t('fileExample.additionalProductInformation'),
    marketingStatements: i18n.t('fileExample.marketingStatements'),
    culinaryApplications: i18n.t('fileExample.culinaryApplications'),
    certifications:
      'https://public-content-server/CERTIFICATIONS_0000000x1.pdf, https://public-content-server/CERTIFICATIONS_0000000x2.pdf',
    specificationSheet: 'https://public-content-server/SPECIFICATION%20SHEET_0000000x1.pdf',
    additionalResources:
      'https://public-content-server/ADDITIONAL%20RESOURCES_0000000x1.pdf, https://public-content-server/ADDITIONAL%20RESOURCES_0000000x2.pdf',
    aboutTheBrand: i18n.t('fileExample.aboutTheBrand'),
    productImages: 'https://public-content-server/0000000x1.jpeg',
    msdsSheet: 'https://public-content-server/MSDS%20SHEET_0000000x1.pdf',
  }
};

export function getSampleDownloadHeaders(){
  return [
    { header: 'SUPC', key: 'supc', width: 10 },
    { header: i18n.t('fileHeader.unabbreviatedDescription'), key: 'unabbreviatedDescription', width: 32 },
    { header: i18n.t('upload.lifestyleImage'), key: 'lifeStyleImage', width: 10 },
    { header: i18n.t('fileHeader.videos'), key: 'videos' },
    { header: i18n.t('infoColumn.productDescriptor'), key: 'productDescriptor' },
    { header: i18n.t('infoColumn.packagingInformation'), key: 'packagingInformation' },
    { header: i18n.t('infoColumn.sizeAndShapeOfProduct'), key: 'sizeAndShapeOfProduct' },
    { header: i18n.t('infoColumn.yieldOrServingSize'), key: 'yieldOrServingSize' },
    { header: i18n.t('infoColumn.qualityAndFormat'), key: 'qualityAndFormat' },
    { header: i18n.t('infoColumn.preparationAndCookingInstructions'), key: 'preparationAndCookingInstructions' },
    { header: i18n.t('infoColumn.storageAndUsage'), key: 'storageAndUsage' },
    { header: i18n.t('infoColumn.handlingInstructions'), key: 'handlingInstructions' },
    { header: i18n.t('infoColumn.additionalProductInformation'), key: 'additionalProductInformation' },
    { header: i18n.t('infoColumn.marketingStatements'), key: 'marketingStatements' },
    { header: i18n.t('infoColumn.culinaryApplications'), key: 'culinaryApplications' },
    { header: i18n.t('fileHeader.certifications'), key: 'certifications' },
    { header: i18n.t('fileHeader.specificationSheet'), key: 'specificationSheet' },
    { header: i18n.t('fileHeader.additionalResources'), key: 'additionalResources' },
    { header: i18n.t('fileHeader.aboutTheBrand'), key: 'aboutTheBrand' },
    { header: i18n.t('fileHeader.productImages'), key: 'productImages' },
    { header: i18n.t('fileHeader.msdsSheet'), key: 'msdsSheet' },
  ]
};

export default getSampleDownload;
