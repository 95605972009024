import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import i18n from './_i18n';
import MainApp from './MainApp';

function App(props) {
  const {
    language,
    Auth: {
      user: { username },
    },
    vendorId,
    setVendorList, // setVendor Method to set an Array of vendors
    setVendorDropdownVisible, // accept bool as parameters
    setUserRole, // set user role in the landing ui
  } = props;

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <Provider store={store}>
      <MainApp
        userId={username}
        setVendorList={setVendorList}
        setVendorDropdownVisible={setVendorDropdownVisible}
        setUserRole={setUserRole}
        vendorId={vendorId}
      />
    </Provider>
  );
}

export default App;
