import axios from 'axios';
import qs from 'qs';
import base64Encode from 'utils/base64Encode';
import zipObj from 'utils/zipObj';
import { getIdToken } from '../Authenticator';

const baseURL = process.env.REACT_APP_BFF;

const getHeaders = async () => {
  const token = (await getIdToken()).getJwtToken();
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

const getHeadersForCompressedData = async () => {
  const token = (await getIdToken()).getJwtToken();
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'text/plain',
  };
};

const postRequest = async (url, data, params = {}) => {
  return axios.post(`${baseURL}${url}`, data, {
    params,
  });
};

const postRequestCompressed = async (url, data, params = {}) => {
  const encodedString = base64Encode(zipObj(data));
  return axios.post(`${baseURL}${url}`, encodedString, {
    params,
    headers: await getHeadersForCompressedData(),
  });
};

// 'Cache-Control': 'no-cache'
const putS3Request = async (path, file) => {
  const config = {
    headers: {
      'Content-Type': file?.type,
    },
  };

  return axios.put(path, file, config);
};

const deleteS3Request = async (path, file) => {
  const config = {
    headers: {
      'Content-Type': file?.contentType,
    },
  };

  return axios.delete(path, config);
};

const getRequest = async (url, params = {}) => {
  return axios.get(`${baseURL}${url}`, {
    headers: await getHeaders(),
    params,
    paramsSerializer: () => qs.stringify(params),
  });
};

const putRequest = async (url, data) => {
  return axios.put(`${baseURL}${url}`, data, {
    headers: await getHeaders(),
  });
};

const patchRequest = async (url, data, params) => {
  return axios.patch(`${baseURL}${url}`, data, {
    params,
  });
};

const deleteRequest = async (url) => {
  return axios.delete(`${baseURL}${url}`, {
    headers: await getHeaders(),
  });
};

export {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
  getHeaders,
  patchRequest,
  putS3Request,
  deleteS3Request,
  postRequestCompressed,
};
