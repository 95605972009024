import React, { useState } from 'react';
import { Button, Icon, Modal, Upload } from 'antd';
import { updateStagingAttribute } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { FILENAME_DELIMITER } from 'constants/Constants';
import { getMimeType, isValidFileName, isValidFileType } from 'utils/FileUploadValidator';
import i18n from '../../_i18n';
import AppLoader from '../AppLoader';
import Notification from '../Notification';
import { CERTIFICATION, LIFESTYLE_IMAGE, SPECIFICATION_SHEET, RECIPES, MSDS_SHEET } from '../../enums/AttributeType';
import { postRequest, putS3Request } from '../../_http';
import { getFileName } from '../../utils/Utils';
import { APPROVED, PENDING } from '../../enums/ApprovalStatus';

const CONTENT_SERVER_URL = process.env.REACT_APP_CONTENT_SERVER_URL;

export const upload = async (file, uploadType, existing, supc, approvable, userId, userRole, urls) => {
  // Get file extension from file name
  const splitFilename = file.name.split(FILENAME_DELIMITER);
  const extension = FILENAME_DELIMITER.concat(splitFilename[splitFilename.length - 1]);
  const name = setFileName(existing, supc, extension, splitFilename[0], uploadType, approvable, urls);

  let filenameWithPciPrefix = getFileName(uploadType, name);
  const fileToSign = { fileName: filenameWithPciPrefix, contentType: file?.type, operation: 'putObject' };
  const response = await postRequest('/staging/signed-url', fileToSign, {});
  const result = response?.data;
  await putS3Request(result?.url, file);

  const contentUrl = `${CONTENT_SERVER_URL}/${result?.fileName}`;

  if (uploadType === LIFESTYLE_IMAGE) {
    await postRequest('/staging/invalidating-image', {}, { path: `/${filenameWithPciPrefix}` });
  }
  let commonTemplate = {
    identifier: contentUrl,
    attributeType: uploadType,
    value: {},
    previousValue: null,
    attributeStatus: approvable ? APPROVED : PENDING,
    comment: null,
    author: userId,
    authorRole: userRole,
    timestamp: null,
  };

  switch (uploadType) {
    case SPECIFICATION_SHEET:
      commonTemplate.value = {
        sheetName: file.name,
        sheetURL: contentUrl,
      };
      return commonTemplate;

    case MSDS_SHEET:
      commonTemplate.value = {
        sheetName: file?.name,
        sheetURL: contentUrl,
      };
      return commonTemplate;

    case CERTIFICATION:
      commonTemplate.value = {
        certificateName: file.name,
        certificateURL: contentUrl,
      };
      return commonTemplate;

    case RECIPES:
      commonTemplate.value = {
        recipeName: file.name,
        recipeURL: contentUrl,
      };
      return commonTemplate;
  }
};

const uploadFileAsync = async (fileList, uploadType, supc, userId, existing, approvable, userRole, urls) => {
  try {
    let saveItem;
    if (uploadType === LIFESTYLE_IMAGE) {
      saveItem = await upload(fileList[0], uploadType, existing, supc, approvable, userId, userRole, urls);
    } else {
      saveItem = [];
      for (const file of fileList) {
        saveItem.push(await upload(file, uploadType, existing, supc, approvable, userId, userRole, urls));
      }
    }
    return {
      attributeType: uploadType,
      value: existing?.length > 0 ? [...existing, ...saveItem] : saveItem,
      attributeStatus: approvable ? APPROVED : PENDING,
      comment: null,
      author: userId,
      timestamp: null,
      authorRole: userRole,
    };
  } catch (error) {}
};

const setFileName = (fileList, fileSupc, fileExtension, existingName, uploadType, approvable, urls) => {
  let size = fileList.length;
  let fileName = `${existingName}_${fileSupc}x${++size}${fileExtension}`;
  let contentUrl = `${CONTENT_SERVER_URL}/${getFileName(uploadType, fileName, approvable)}`;

  while (urls?.includes(contentUrl)) {
    fileName = `${existingName}_${fileSupc}x${++size}${fileExtension}`;
    contentUrl = `${CONTENT_SERVER_URL}/${getFileName(uploadType, fileName, approvable)}`;
  }
  urls = [...urls, contentUrl];
  return fileName;
};

export function setFileNameWithExtension(uploadType, supc, extension, file) {
  return uploadType === LIFESTYLE_IMAGE ? supc + extension : file.name;
}

function generateUploadFile(file, uploadType, supc, blobToFile) {
  if (file.name.includes(FILENAME_DELIMITER)) {
    file = file.originFileObj;
    // Get file extension from file name
    const splitFilename = file.name.split(FILENAME_DELIMITER);
    const extension = FILENAME_DELIMITER.concat(splitFilename[splitFilename.length - 1]);
    // create a blob from file calling mime type injection function
    const blob = new Blob([file], { type: getMimeType(extension) });
    // re-convert to a file
    file = blobToFile(blob, setFileNameWithExtension(uploadType, supc, extension, file));
  }
  return file;
}

export function checkFileNameValidity(file, fileListToUpload, visibleSetter, failure) {
  if (isValidFileName(file.name)) {
    if (!fileListToUpload.some((existingFile) => existingFile.name === file.name)) {
      fileListToUpload.push(file);
    } else {
      Notification({
        message: i18n.t('notification.duplicate'),
        description: '',
        className: 'warn',
        isClosable: true,
      });
    }
    return failure;
  } else {
    visibleSetter(false);
    Notification({
      message: i18n.t('notification.invalidFileName'),
      description: i18n.t('notification.invalidFileNameDescription'),
      className: 'error',
      isClosable: true,
    });
    failure = true;
    return failure;
  }
}

function UploadModal({
  visible,
  loading,
  uploadType,
  supc,
  visibleSetter,
  existing,
  approvable,
  uploadSubtitle,
  urls,
}) {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();
  const { fetching: uploadFetching } = useSelector((state) => state.uploadFile);
  const { fetching: itemFetching } = useSelector((state) => state.item);
  const {
    data: { fullName: userId, userRole, associatedUsers },
  } = useSelector((state) => state.userData);

  const { Dragger } = Upload;

  const blobToFile = (theBlob, fileName) => {
    theBlob.name = fileName;
    return theBlob;
  };

  const customUpload = async () => {
    let failure = false;
    let fileListToUpload = [];
    if (fileList?.length > 0) {
      fileList.forEach((loopFile) => {
        let file = loopFile;
        if (file && isValidFileType(file?.type)) {
          file = generateUploadFile(file, uploadType, supc, blobToFile);
          failure = checkFileNameValidity(file, fileListToUpload, visibleSetter, failure);
        } else {
          Notification({
            message: i18n.t('notification.invalidFileType'),
            description: i18n.t('notification.invalidFileTypeDescription'),
            className: 'error',
            isClosable: true,
          });
          failure = true;
        }
      });
      if (!failure) {
        setUploading(true);
        const attribute = await uploadFileAsync(
          fileListToUpload,
          uploadType,
          supc,
          userId,
          existing,
          approvable,
          userRole,
          urls
        );
        dispatch(updateStagingAttribute(supc, i18n.language, attribute, associatedUsers));
        visibleSetter(false);
        setUploading(false);
        clearFileList();
      }
    }
  };

  const props = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    defaultFileList: fileList,
    accept: 'image/png, image/jpeg, .pdf',
  };

  const handleUploadChange = (info) => {
    let tempFileList = [...info.fileList];
    setFileList(tempFileList);
  };

  const clearFileList = () => {
    setFileList([]);
  };

  switch (uploadType) {
    case LIFESTYLE_IMAGE:
      uploadSubtitle = i18n.t('fileImport.lifestyleImage');
      break;
    case SPECIFICATION_SHEET:
      uploadSubtitle = i18n.t('mediaWidgetColumn.specificationSheet');
      break;
    case MSDS_SHEET:
      uploadSubtitle = i18n.t('mediaWidgetColumn.msdsSheet');
      break;
    case CERTIFICATION:
      uploadSubtitle = i18n.t('nutritionalColumn.certifications');
      break;
    case RECIPES:
      uploadSubtitle = i18n.t('mediaWidgetColumn.additionalResources');
      break;
  }

  return itemFetching || uploadFetching || uploading ? (
    <AppLoader show />
  ) : (
    <Modal
      id="upload-modal-single"
      className="item-popup"
      destroyOnClose={true}
      visible={visible}
      onCancel={() => {
        visibleSetter(false);
      }}
      title={[
        <div id="upload-modal-single-header" key="upload-modal-single-header" className="header">
          <i className="icon fi flaticon-upload" />
          <div id="upload-modal-single-title" key="upload-modal-single-title" className="title">
            {i18n.t('upload.uploadFiles')}
          </div>
          <div id="upload-modal-single-subtitle" key="upload-modal-single-subtitle" className="subtitle">
            {uploadSubtitle}
          </div>
        </div>,
      ]}
      footer={[
        <Button
          id="upload-modal-single-back"
          key="upload-modal-single-back"
          onClick={() => {
            visibleSetter(false);
          }}
        >
          {i18n.t('infoColumn.cancel')}
        </Button>,
        <Button
          id="upload-modal-single-submit"
          key="upload-modal-single-submit"
          type="primary"
          onClick={() => customUpload()}
          disabled={fileList?.length === 0}
        >
          {i18n.t('upload.upload')}
        </Button>,
      ]}
    >
      <div id="upload-modal-single-description" className="note">
        <i className="icon fi flaticon-information-button" /> {i18n.t('upload.documentsUploadDescription')}
      </div>
      <Dragger id="upload-modal-single-dragger" {...props} onChange={handleUploadChange}>
        <p id="upload-modal-single-dragger-icon" className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p id="upload-modal-single-dragger-text" className="ant-upload-text">
          {i18n.t('fileImport.fileUpload')}
        </p>
        <p id="upload-modal-single-dragger-hint" className="ant-upload-hint">
          JPEG / PNG / PDF
        </p>
      </Dragger>
    </Modal>
  );
}

export default UploadModal;
