import { MDM, VENDOR, CATMAN, MARKETING, ASSOCIATE, VIEWONLY } from '../enums/UserType';
import i18n from '_i18n';

export const attributeManagement = (userType, isEnrichable) => {
  let data = JSON.parse(process.env.REACT_APP_FIELD_PERMISSIONS);

  if(!isEnrichable) {
    userType = VIEWONLY;
  }

  switch (userType) {
    case VENDOR:
      return i18n.language === "en" ? data.VENDOR.en : data.VENDOR.fr;
    case MDM:
      return data.MDM;
    case CATMAN:
      return data.CATMAN;
    case MARKETING:
      return data.MARKETING;
    case ASSOCIATE:
      return data.ASSOCIATE;
    default:
      return data.VIEW_ONLY;
  }
};

export default attributeManagement;
