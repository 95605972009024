import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfoColumnStaticAttribute from './InfoColumnStaticAttribute';
import { reportError, updateStagingAttribute } from 'actions';
import ReportModal from '../../../components/modals/ReportModal';

import {
  ADDITIONAL_PRODUCT_INFORMATION,
  CULINARY_APPLICATIONS,
  HANDLING_INSTRUCTIONS,
  MARKETING_STATEMENTS,
  PACKAGING_INFORMATION,
  PREPARATION_AND_COOKING_INSTRUCTIONS,
  PRODUCT_DESCRIPTOR,
  QUALITY_AND_FORMAT,
  SIZE_AND_SHAPE_OF_PRODUCT,
  STORAGE_AND_USAGE,
  YIELD_OR_SERVING_SIZE,
} from '../../../enums/AttributeType';
import { PENDING, APPROVED, REJECTED } from '../../../enums/ApprovalStatus';
import AppLoader from '../../../components/AppLoader';
import i18n from '../../../_i18n';
import Allergens from './Allergens';
import Brand from './Brand';
import Uad from './Uad';
import FABsField from './FABsField';

const attributeCSSApplier = (approvalStatus, isChangedUser) => {
  if (approvalStatus === APPROVED && isChangedUser) {
    return 'data-block editable highlighted approved';
  } else if (approvalStatus === REJECTED && isChangedUser) {
    return 'data-block editable highlighted rejected';
  } else if (approvalStatus === PENDING) {
    return 'data-block editable highlighted';
  } else {
    return 'data-block editable';
  }
};

const getValueList = (attribute, value, previousValue, userType) => {
  return attribute?.attributeStatus === REJECTED && attribute?.authorRole !== userType ? previousValue : value;
};

const getCatchWeight = (flags) => {
  return flags?.catchWeight === 'Y' ? '(Est.)' : '';
};

function InfoColumn({ coreData, flags, coreDataErrors, permissions }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { fetching: updateFetching } = useSelector((state) => state.updateAttribute);
  const {
    data: { fullName: userId, userRole: userType, associatedUsers, vendorName },
  } = useSelector((state) => state.userData);

  const [attributes, setAttributes] = useState(coreData?.attributes);

  const [showMissingInfoPlaceholder, setShowMissingInfoPlaceholder] = useState(true);

  const [stagingAttribute, setStagingAttribute] = useState(null);

  useEffect(() => {
    stagingAttribute &&
      dispatch(updateStagingAttribute(coreData?.supc, i18n.language, stagingAttribute, associatedUsers));
  }, [stagingAttribute]);

  const handleSave = (attributeType, attributeValue, status, comment) => {
    const attribute = {
      attributeType: attributeType,
      value: attributeValue,
      attributeStatus: status,
      comment,
      author: userId,
      timestamp: null,
      authorRole: userType,
    };

    setStagingAttribute(attribute);
  };
  const [previewVisible, setPreviewVisible] = useState(false);

  const pendingErrors = coreDataErrors?.filter((err) => err?.status === PENDING);

  const resolveError = (err) => {
    const modifiedList = coreDataErrors?.map((error) => {
      if (error.id === err.id) return { ...error, status: 'RESOLVED', resolvedBy: userId };
      return error;
    });
    const request = {
      username: userId,
      vendor: vendorName,
      coreDataErrorList: modifiedList,
    };
    dispatch(reportError(coreData?.supc, request));
  };

  const resolveAll = () => {
    const modifiedList = coreDataErrors?.map((error) => {
      return { ...error, status: 'RESOLVED', resolvedBy: userId };
    });
    const request = {
      username: userId,
      vendor: vendorName,
      coreDataErrorList: modifiedList,
    };
    dispatch(reportError(coreData?.supc, request));
  };

  return updateFetching ? (
    <AppLoader show />
  ) : (
    coreData != null && (
      <div id="info-column" className="info-column animated fadeIn delay-1s">
        <Uad coreData={coreData} handleSave={handleSave} userType={userType} permissions={permissions} />

        <div id="info-column-brand-manufacturer-wrapper" className="info-row brand-manufacturer-wrapper">
          <InfoColumnStaticAttribute
            label={'infoColumn.brand'}
            value={coreData?.brand}
            cssClass={'data-block brand'}
            id={'brand'}
          />
          <InfoColumnStaticAttribute
            label={'infoColumn.mpc'}
            value={coreData?.manufacturer}
            cssClass={'data-block manufacturer'}
            id={'manufacturer'}
          />
        </div>
        <div id="#" className="info-row inline-labels report-row-wrap">
          <ReportModal
            id="enrichment-header-preview-modal"
            visible={previewVisible}
            setVisible={setPreviewVisible}
            supc={coreData?.supc}
            coreDataErrors={coreDataErrors}
          />
        </div>

        <div id="info-column-supc-row" className="info-row inline-labels info-row-supc">
          <InfoColumnStaticAttribute
            label={'infoColumn.supc'}
            value={coreData?.supc}
            cssClass={'data-block supc'}
            id={'supc'}
          />
          <InfoColumnStaticAttribute
            label={'infoColumn.gtin'}
            value={coreData?.gtin}
            cssClass={'data-block gtin'}
            id={'gtin'}
          />
        </div>

        <div id="info-column-class-row" className="info-row inline-labels">
          <InfoColumnStaticAttribute
            label={'infoColumn.class'}
            value={coreData?.itemClass}
            cssClass={'data-block class'}
            id={'class'}
          />
          <InfoColumnStaticAttribute
            label={'infoColumn.category'}
            value={coreData?.category}
            cssClass={'data-block category'}
            id={'category'}
          />
        </div>

        <div id="info-column-class-row" className="info-row inline-labels">
          <InfoColumnStaticAttribute
            label={'infoColumn.gln'}
            value={coreData?.gln}
            cssClass={'data-block category'}
            id={'class'}
          />
          <InfoColumnStaticAttribute
            label={'infoColumn.trueVendor'}
            value={coreData?.trueVendor}
            cssClass={'data-block category'}
            id={'category'}
          />
        </div>

        <div id="info-column-tag-row" className="info-row">
          {/* <ItemStatus coreData={coreData} /> */}
          <InfoColumnStaticAttribute
            label={'infoColumn.pack'}
            value={coreData?.pack}
            cssClass={'data-block pack'}
            id={'pack'}
          />
          <InfoColumnStaticAttribute
            label={'infoColumn.itemSize'}
            value={coreData?.itemSize}
            cssClass={'data-block size'}
            id={'size'}
          />
        </div>

        {coreData?.packaging && (
          <div id="info-column-packaging-row" className="info-row">
            <label id="info-column-packaging-row-label" className="label">
              {t('infoColumn.packaging')}
            </label>
            {coreData?.packaging?.itemWeight && (
              <div id="info-column-packaging-weight-row-datablock" className="data-block weight">
                <div id="info-column-packaging-weight-row-datablock-label" className="label">
                  {t('infoColumn.packaging.itemWeight')}
                </div>
                <div id="info-column-packaging-weight-row-datablock-data" className="data">{`${
                  coreData?.packaging?.itemWeight
                }${getCatchWeight(flags)}`}</div>
              </div>
            )}
            <InfoColumnStaticAttribute
              label={'infoColumn.packaging.caseCube'}
              value={coreData?.packaging?.caseCube}
              cssClass={'data-block cube'}
              id={'packaging-case-row'}
            />
            <InfoColumnStaticAttribute
              label={'infoColumn.packaging.servings'}
              value={coreData?.packaging?.servings}
              cssClass={'data-block case'}
              id={'packaging-servings-row'}
            />
            <InfoColumnStaticAttribute
              label={'infoColumn.packaging.servingsUOM'}
              value={coreData?.packaging?.servingsUOM}
              cssClass={'data-block uom'}
              id={'packaging-uom-row'}
            />
          </div>
        )}
        {permissions.ATTRIBUTES.visible && (
          <div id="info-column-attributes-row" className="info-row">
            <div id="info-column-attributes-row-editable" className="data-block editable">
              <div id="info-column-attributes-row-editable-label" className="label">
                {t('infoColumn.attributes')}
              </div>
              <div id="info-column-attributes-row-editable-add-tags" className="tags">
                {attributes
                  ?.filter((tag) => tag?.value?.attributeValue)
                  ?.map((tag) => (
                    <div
                      id={`info-column-attributes-row-type-${tag?.value?.type}`}
                      key={`info-column-attributes-row-type-${tag?.value?.attributeValue}`}
                      className={`tag ${tag?.value?.type}`} /* title="Product highilight"*/
                    >
                      {tag.value.attributeValue}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}

        <Allergens coreData={coreData} />
        <span className="info-column-attributes-divider"></span>
        <div id={`info-column-fnb-status`} className="info-row">
          <div className="data-block fnb-title-wrapper">
            <div id="#" class="label">
              {i18n.t('infoColumn.featuresAndBenefits')}
            </div>
          </div>
        </div>

        <FABsField
          attributeCSSApplier={attributeCSSApplier}
          coreData={coreData}
          userType={userType}
          handleSave={handleSave}
          attribute={coreData?.productDescriptor}
          attributeType={PRODUCT_DESCRIPTOR}
          approvable={permissions?.FEATURES_AND_BENEFITS?.approvable}
          editable={permissions?.FEATURES_AND_BENEFITS?.editable}
          valueList={null}
          id="overview"
        />

        <FABsField
          attributeCSSApplier={attributeCSSApplier}
          coreData={coreData}
          userType={userType}
          handleSave={handleSave}
          attribute={coreData?.packagingInformation}
          attributeType={PACKAGING_INFORMATION}
          approvable={permissions?.FEATURES_AND_BENEFITS?.approvable}
          editable={permissions?.FEATURES_AND_BENEFITS?.editable}
          valueList={getValueList(
            coreData?.packagingInformation,
            coreData?.packagingInformation?.value?.split('\n'),
            coreData?.packagingInformation?.previousValue?.split('\n'),
            userType
          )}
          id="packaging-information"
        />

        <FABsField
          attributeCSSApplier={attributeCSSApplier}
          coreData={coreData}
          userType={userType}
          handleSave={handleSave}
          attribute={coreData?.sizeAndShapeOfProduct}
          attributeType={SIZE_AND_SHAPE_OF_PRODUCT}
          approvable={permissions?.FEATURES_AND_BENEFITS?.approvable}
          editable={permissions?.FEATURES_AND_BENEFITS?.editable}
          valueList={getValueList(
            coreData?.sizeAndShapeOfProduct,
            coreData?.sizeAndShapeOfProduct?.value?.split('\n'),
            coreData?.sizeAndShapeOfProduct?.previousValue?.split('\n'),
            userType
          )}
          id="size-and-shape"
        />

        <FABsField
          attributeCSSApplier={attributeCSSApplier}
          coreData={coreData}
          userType={userType}
          handleSave={handleSave}
          attribute={coreData?.yieldOrServingSize}
          attributeType={YIELD_OR_SERVING_SIZE}
          approvable={permissions?.FEATURES_AND_BENEFITS?.approvable}
          editable={permissions?.FEATURES_AND_BENEFITS?.editable}
          valueList={getValueList(
            coreData?.yieldOrServingSize,
            coreData?.yieldOrServingSize?.value?.split('\n'),
            coreData?.yieldOrServingSize?.previousValue?.split('\n'),
            userType
          )}
          id="yield-or-serving-size"
        />

        <FABsField
          attributeCSSApplier={attributeCSSApplier}
          coreData={coreData}
          userType={userType}
          handleSave={handleSave}
          attribute={coreData?.qualityAndFormat}
          attributeType={QUALITY_AND_FORMAT}
          approvable={permissions?.FEATURES_AND_BENEFITS?.approvable}
          editable={permissions?.FEATURES_AND_BENEFITS?.editable}
          valueList={getValueList(
            coreData?.qualityAndFormat,
            coreData?.qualityAndFormat?.value?.split('\n'),
            coreData?.qualityAndFormat?.previousValue?.split('\n'),
            userType
          )}
          id="quality-and-format"
        />

        <FABsField
          attributeCSSApplier={attributeCSSApplier}
          coreData={coreData}
          userType={userType}
          handleSave={handleSave}
          attribute={coreData?.preparationAndCookingInstructions}
          attributeType={PREPARATION_AND_COOKING_INSTRUCTIONS}
          approvable={permissions?.FEATURES_AND_BENEFITS?.approvable}
          editable={permissions?.FEATURES_AND_BENEFITS?.editable}
          valueList={getValueList(
            coreData?.preparationAndCookingInstructions,
            coreData?.preparationAndCookingInstructions?.value?.replace('↵', '\n')?.split('\n'),
            coreData?.preparationAndCookingInstructions?.previousValue?.replace('↵', '\n')?.split('\n'),
            userType
          )}
          id="pnc"
        />

        <FABsField
          attributeCSSApplier={attributeCSSApplier}
          coreData={coreData}
          userType={userType}
          handleSave={handleSave}
          attribute={coreData?.storageAndUsage}
          attributeType={STORAGE_AND_USAGE}
          approvable={permissions?.FEATURES_AND_BENEFITS?.approvable}
          editable={permissions?.FEATURES_AND_BENEFITS?.editable}
          valueList={getValueList(
            coreData?.storageAndUsage,
            coreData?.storageAndUsage?.value?.split('\n'),
            coreData?.storageAndUsage?.previousValue?.split('\n'),
            userType
          )}
          id="pns"
        />

        <FABsField
          attributeCSSApplier={attributeCSSApplier}
          coreData={coreData}
          userType={userType}
          handleSave={handleSave}
          attribute={coreData?.handlingInstructions}
          attributeType={HANDLING_INSTRUCTIONS}
          approvable={permissions?.FEATURES_AND_BENEFITS?.approvable}
          editable={permissions?.FEATURES_AND_BENEFITS?.editable}
          valueList={getValueList(
            coreData?.handlingInstructions,
            coreData?.handlingInstructions?.value?.split('\n'),
            coreData?.handlingInstructions?.previousValue?.split('\n'),
            userType
          )}
          id="handling-instructions"
        />

        <FABsField
          attributeCSSApplier={attributeCSSApplier}
          coreData={coreData}
          userType={userType}
          handleSave={handleSave}
          attribute={coreData?.additionalProductInformation}
          attributeType={ADDITIONAL_PRODUCT_INFORMATION}
          approvable={permissions?.FEATURES_AND_BENEFITS?.approvable}
          editable={permissions?.FEATURES_AND_BENEFITS?.editable}
          valueList={getValueList(
            coreData?.additionalProductInformation,
            coreData?.additionalProductInformation?.value?.split('\n'),
            coreData?.additionalProductInformation?.previousValue?.split('\n'),
            userType
          )}
          id="additional-product-info"
        />

        <FABsField
          attributeCSSApplier={attributeCSSApplier}
          coreData={coreData}
          userType={userType}
          handleSave={handleSave}
          attribute={coreData?.marketingStatements}
          attributeType={MARKETING_STATEMENTS}
          approvable={permissions?.FEATURES_AND_BENEFITS?.approvable}
          editable={permissions?.FEATURES_AND_BENEFITS?.editable}
          valueList={getValueList(
            coreData?.marketingStatements,
            coreData?.marketingStatements?.value?.split('\n'),
            coreData?.marketingStatements?.previousValue?.split('\n'),
            userType
          )}
          id="fnb"
        />

        <FABsField
          attributeCSSApplier={attributeCSSApplier}
          coreData={coreData}
          userType={userType}
          handleSave={handleSave}
          attribute={coreData?.culinaryApplications}
          attributeType={CULINARY_APPLICATIONS}
          approvable={permissions?.FEATURES_AND_BENEFITS?.approvable}
          editable={permissions?.FEATURES_AND_BENEFITS?.editable}
          valueList={getValueList(
            coreData?.culinaryApplications,
            coreData?.culinaryApplications?.value?.split('\n'),
            coreData?.culinaryApplications?.previousValue?.split('\n'),
            userType
          )}
          id="serving-suggestions"
        />

        <Brand
          coreData={coreData}
          attributeCSSApplier={attributeCSSApplier}
          userType={userType}
          setShowMissingInfoPlaceholder={setShowMissingInfoPlaceholder}
          handleSave={handleSave}
          showMissingInfoPlaceholder={showMissingInfoPlaceholder}
          permissions={permissions}
        />
      </div>
    )
  );
}

export default InfoColumn;
