import React from 'react';
import { Icon, Button, Tooltip } from 'antd';
import i18n from '../../../_i18n';
import { attributeEditorDetails } from '../../../utils/Utils';
import { DELETE_REJECTED } from '../../../enums/ApprovalStatus';

function ImageRejectedStatusWidget({
  isEditable,
  imageType,
  image,
  imageList,
  handleProductImagesSave,
  userType,
  approvable,
}) {
  return (
    <div id="image-rejected-status-widget" className="footer-bar rejected">
      <Icon
        id="image-rejected-status-widget-icon"
        type="rejected"
        theme="filled"
        className="icon status-icon warning"
      />
      <div id="image-rejected-status-widget-label" className="label">
        {image?.attributeStatus === DELETE_REJECTED
          ? i18n.t('mediaColumn.rejectedDelete')
          : i18n.t('mediaColumn.rejected')}
      </div>
      <div id="image-rejected-status-widget-footer-actions" className="footer-actions">
        {isEditable && (
          <Button
            id="image-rejected-status-widget-footer-actions-dismiss"
            className="btn"
            onClick={() => {
              handleProductImagesSave(imageType, image, imageList, null, null);
            }}
          >
            {i18n.t('infoColumn.dismiss')}
          </Button>
        )}
        {(approvable || isEditable) && (
          <Tooltip
            id="image-rejected-status-widget-tooltip"
            overlayStyle={{ whiteSpace: 'pre-line' }}
            title={
              attributeEditorDetails(userType, image?.attributeStatus, image) +
              '\n' +
              i18n.t('infoColumn.reason') +
              ': ' +
              image?.comment
            }
          >
            <i
              id="image-rejected-status-widget-tooltip-icon"
              className="info-icon icon fi flaticon-information-button"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}
export default ImageRejectedStatusWidget;
