import React from 'react';
import { Icon, Button, Tooltip } from 'antd';
import i18n from '../../../_i18n';
import { attributeEditorDetails } from '../../../utils/Utils';
import { DELETE_APPROVED } from '../../../enums/ApprovalStatus';

function ImageApprovedStatusWidget({
  isEditable,
  imageType,
  image,
  imageList,
  handleProductImagesSave,
  userType,
  approvable,
}) {
  return (
    <div id="image-approved-status-widget-footer" className="footer-bar approved">
      <Icon
        id="image-approved-status-widget-footer-warning-icon"
        type="approved"
        theme="filled"
        className="icon status-icon warning"
      />
      <div id="image-approved-status-widget-footer-label" className="label">
        {image?.attributeStatus === DELETE_APPROVED
          ? i18n.t('mediaColumn.approvedDelete')
          : i18n.t('mediaColumn.approved')}
      </div>
      <div id="image-approved-status-widget-footer-actions" className="footer-actions">
        {isEditable && (
          <div id="image-approved-status-widget-footer-actions-buttons" className="footer-actions">
            <Button
              id="image-approved-status-widget-footer-actions-dismiss"
              className="btn"
              onClick={() => {
                handleProductImagesSave(imageType, image, imageList, null, null);
              }}
            >
              {i18n.t('infoColumn.dismiss')}
            </Button>
          </div>
        )}
        {(approvable || isEditable) && (
          <Tooltip
            id="image-approved-status-widget-tooltip"
            title={attributeEditorDetails(userType, image?.attributeStatus, image)}
          >
            <i
              id="image-approved-status-widget-tooltip-icon"
              className="info-icon icon fi flaticon-information-button"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default ImageApprovedStatusWidget;
