import React, { useState } from 'react';
import { Modal, Select, Input, Button } from 'antd';
import i18n from '_i18n';
import { useDispatch, useSelector } from 'react-redux';
import { reportError } from 'actions';
import AppLoader from 'components/AppLoader';
import { PENDING } from 'enums/ApprovalStatus';

const { Option } = Select;
const { TextArea } = Input;

export default function ReportModal({ visible, setVisible, supc, coreDataErrors }) {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state) => state.reportError);
  const { data } = useSelector((state) => state.userData);

  const [description, setDescription] = useState('');
  const [field, setField] = useState('');

  const coreDataFields = [
    i18n.t('infoColumn.brand'),
    i18n.t('infoColumn.mpc'),
    i18n.t('infoColumn.trueVendor'),
    i18n.t('infoColumn.gln'),
    i18n.t('infoColumn.gtin'),
    i18n.t('infoColumn.pack'),
    i18n.t('infoColumn.itemSize'),
    i18n.t('infoColumn.packaging.itemWeight'),
    i18n.t('infoColumn.packaging.caseCube'),
    i18n.t('infoColumn.packaging.servings'),
    i18n.t('infoColumn.packaging.servingsUOM'),
    i18n.t('infoColumn.attributes'),
    i18n.t('infoColumn.allergens'),
  ];

  const descriptionChangeHandler = (event) => {
    setDescription(event.target.value);
  };

  const clear = () => {
    setField('');
    setDescription('');
    setVisible(false);
  };

  const generateId = (list) => {
    if (list.length === 0) return 1;
    const idList = list?.map(err => err?.id)?.sort(function (a, b) { return b - a });
    const max = idList[0];
    return max + 1;
  };


  const reportHandler = () => {
    if (field?.length > 0 && description?.length > 0) {
      const existingList = coreDataErrors?.length > 0 ? [...coreDataErrors] : [];
      const errors = [...existingList,
      {
        id: generateId(existingList),
        fieldName: field,
        error: description,
        status: PENDING,
        reportedBy: data?.fullName,
        notified: false
      },
      ];
      const request = {
        username: data?.fullName,
        vendor: data?.vendorName,
        coreDataErrorList: errors
      };
      dispatch(reportError(supc, request));
      if (!fetching) {
        clear();
      }
    }
  };

  const cancelHandler = () => {
    clear();
  };

  return fetching ? (
    <AppLoader show />
  ) : (
    <>
      <Modal
        title={`${i18n.t('reportModal.title')}`}
        id="product-sheet-preview-modal"
        centered
        visible={visible}
        destroyOnClose={true}
        onCancel={cancelHandler}
        width={800}
        forceRender={true}
        footer={[
          <Button
            id="report-modal-submit"
            type="primary"
            onClick={reportHandler}
            disabled={description?.length === 0 || field?.length === 0}
          >
            {i18n.t('reportModal.report')}
          </Button>,
          <Button id="report-modal-cancel" onClick={cancelHandler}>
            {i18n.t('infoColumn.cancel')}
          </Button>,
        ]}
      >
        <div className="issue-report-wraper">
          <div className="issue-report-type">
            <label>{i18n.t('reportModal.field')}</label>
            <Select id="report-modal-fields" style={{ width: '100%' }} onChange={setField}>
              {coreDataFields.map((dataField) => (
                <Option value={`${dataField}`}>{dataField}</Option>
              ))}
            </Select>
          </div>
          <div>
            <label>{i18n.t('reportModal.describe')}</label>
            <TextArea
              id="report-modal-reason"
              placeholder="Describe the error"
              allowClear
              onChange={descriptionChangeHandler}
              value={description}
              disabled={field.length === 0}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
