import React, { useState } from 'react';
import FilterPanel from '../components/FilterPanel';
import ItemList from '../components/ItemList';
import ProductSheetEditor from './ProductSheetEditor';

export default function ItemMain({ itemOpen, setItemOpen, approvals, vendorId, setProprietaryItem, proprietaryItem }) {
  const [vendorNos, setVendorNos] = useState([]);
  const [classNames, setClassNames] = useState([]);
  const [status, setStatus] = useState(null);
  const [approvalTypes, setApprovalTypes] = useState(null);
  const [enrichmentLevel, setEnrichmentLevel] = useState(null);
  const [brandType, setBrandType] = useState(null);

  return (
    <div className="item-content-wrapper">
      <FilterPanel
        setVendorNos={setVendorNos}
        setClassNames={setClassNames}
        setStatus={setStatus}
        setApprovalTypes={setApprovalTypes}
        setEnrichmentLevel={setEnrichmentLevel}
        setBrandType={setBrandType}
        setProprietaryItem={setProprietaryItem}
        approvals={approvals}
      />
      <div id="items-list-container" className={`item-content ${itemOpen ? 'item-open' : ''}`}>
        {/*DEV NOTE add 'item-open' class when an item is opened to show product-sheet editor */}
        <ItemList
          setItemOpen={setItemOpen}
          vendorNos={vendorNos}
          classNames={classNames}
          status={status}
          approvalTypes={approvalTypes}
          enrichmentLevel={enrichmentLevel}
          brandType={brandType}
          proprietaryItem={proprietaryItem}
          itemOpen={itemOpen}
          approvals={approvals}
          vendorId={vendorId}
        />
        <ProductSheetEditor setItemOpen={setItemOpen} vendorId={vendorId} />
      </div>
    </div>
  );
}
