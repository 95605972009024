import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Checkbox, Icon, Input, Pagination } from 'antd';
import { loadClasses, loadVendors, loadItemApprovalPendingVendors } from '../actions/index';
import i18n from 'i18next';
import AppLoader from './AppLoader';
import { APPROVED, PENDING, REJECTED } from '../enums/ApprovalStatus';
import { VENDOR } from '../enums/UserType';

const { Search } = Input;

function generateVendorOptions(vendorResult) {
  let vendorList = [];
  if (vendorResult?.length > 0) {
    vendorList = vendorResult
      ?.filter((vendor) => vendor?.masterVendorName)
      .map((vendor) => {
        return vendor?.masterVendorName;
      });
    return vendorList;
  } else {
    return [];
  }
}

function generateVendorNumbers(checkedValues, vendorResult) {
  return checkedValues?.map((value) => {
    if (value) {
      var foundVendor = vendorResult?.find((vendor) => vendor?.masterVendorName === value);
      if (foundVendor) {
        return foundVendor.masterVendorNumber;
      }
    }
  });
}

function calculatePage(page) {
  return page > 0 ? page - 1 : page;
}

function generateClassesList(classesResult) {
  let categoryList = [];
  if (classesResult?.length > 0) {
    categoryList = classesResult.map((category) => {
      return `${category?.className}`;
    });
  }
  return categoryList;
}

function generateStatuses(checkedValues) {
  return checkedValues?.map((value) => {
    if (value === i18n.t('infoColumn.active')) {
      return 'A';
    } else if (value === i18n.t('infoColumn.inActive')) {
      return 'I';
    }
  });
}

function generateApprovalTypes(checkedValues) {
  return checkedValues?.map((value) => {
    if (value === i18n.t('filterPanel.approved')) {
      return APPROVED;
    }
    if (value === i18n.t('filterPanel.pending')) {
      return PENDING;
    }
    if (value === i18n.t('mediaColumn.rejected')) {
      return REJECTED;
    }
  });
}

function generateBrandTypes(checkedValues, sysco, noSysco) {
  return checkedValues?.map((value) => {
    if (value === i18n.t('infoColumn.syscoBrand')) {
      return 'Y';
    } else if (value === i18n.t('infoColumn.notSyscoBrand')) {
      return 'N';
    }
  });
}

function generateProprietaryTypes(checkedValues) {
  return checkedValues?.map((value) => {
    if (value === i18n.t('infoColumn.syscoProprietary')) {
      return 'Y';
    } else if (value === i18n.t('infoColumn.notSyscoProprietary')) {
      return 'N';
    }
  });
}

const getFilterPanelClassName = (filtersCollapsed) => {
  return filtersCollapsed ? 'filter-panel collapsed' : 'filter-panel';
};

const getCheckedVendors = (currentCheckedVendors, chekedVendorNames, vendors, previousPageVendors) => {
  // remove unchecked vendors
  const checkedVendors = currentCheckedVendors
    .filter((chekedVendor) => previousPageVendors.includes(chekedVendor.masterVendorName))
    .map((rv) => rv);

  // find checked vendors information from vendors object list
  chekedVendorNames.forEach((checkedVendor) => {
    if (!checkedVendors.find((currentVendor) => currentVendor?.masterVendorName == checkedVendor)) {
      checkedVendors.push(vendors.find((vendor) => vendor?.masterVendorName == checkedVendor));
    }
  });

  return checkedVendors;
};

export function FilterPanel({
  setVendorNos,
  setClassNames,
  setStatus,
  setApprovalTypes,
  setEnrichmentLevel,
  setBrandType,
  setProprietaryItem,
  approvals,
}) {
  const { t } = useTranslation();
  const vendorPageSize = 10;
  const CheckboxGroup = Checkbox.Group;
  const [vendorOptions, setVendorOptions] = useState([]);
  const [vendorResult, setVendersToVendorResult] = useState([]);
  const [totalVendorCount, setTotalVendorCount] = useState([]);
  const [itemClassOptions, setItemClassOptions] = useState([]);
  const statusOptions = [t('infoColumn.inActive'), t('infoColumn.active')];
  const brandOptions = [t('infoColumn.syscoBrand'), t('infoColumn.notSyscoBrand')];
  const proprietaryOptions = [t('infoColumn.syscoProprietary'), t('infoColumn.notSyscoProprietary')];
  const approvalsOptions = [t('filterPanel.pending'), t('mediaColumn.rejected')]; //, t('filterPanel.approved')
  const levelOptions = [t('header.good'), t('header.partial'), t('header.poor')];

  const [vendorName, setVendorName] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [vendorPage, setVendorPage] = useState(0);
  const [categoryPage] = useState(0);
  const [checkedList, setCheckedList] = React.useState([]);
  const [checkedItemClassList, setCheckedItemClassList] = React.useState([]);
  const [checkedItemStatusList, setCheckedItemStatusList] = React.useState([]);
  const [checkedItemBrandList, setCheckedItemBrandList] = React.useState([]);
  const [checkedItemProprietaryList, setCheckedItemProprietaryList] = React.useState([]);
  const [checkedEnrichmentLevelList, setCheckedEnrichmentLevelList] = React.useState([]);
  const [checkedApprovalList, setCheckedApprovalList] = React.useState([]);

  const [filtersCollapsed, setFiltersCollapsed] = useState(false);

  const [checkedMasterVendors, setCheckedMasterVendors] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (userRole !== VENDOR) {
      if (approvals) {
        dispatch(loadItemApprovalPendingVendors(vendorName, vendorPage, vendorPageSize, i18n.language, userRole));
      } else {
        dispatch(loadVendors(vendorName, vendorPage, vendorPageSize));
      }
    }
  }, [vendorName, vendorPage, approvals]);

  useEffect(() => {
    if (userRole !== VENDOR) {
      dispatch(loadClasses(i18n.language, categoryPage, 10));
    }
  }, [categoryPage]);

  useEffect(() => {
    setCheckedItemClassList([]);
    setCheckedItemStatusList([]);
    setCheckedList([]);
    setCheckedEnrichmentLevelList([]);
    setCheckedApprovalList([]);
    if (userRole !== VENDOR) {
      dispatch(loadClasses(i18n.language, categoryPage, 10));
      if (approvals) {
        dispatch(loadItemApprovalPendingVendors(vendorName, vendorPage, vendorPageSize, i18n.language, userRole));
      }
    }
  }, [i18n.language]);

  const {
    data: { userRole },
  } = useSelector((state) => state.userData);
  const { data: classesResult, fetching: classesFetching } = useSelector((state) => state.classes);
  const {
    data: { totalItems: totalVendors, content: vendorResultCatalog },
    fetching: vendorFetching,
  } = useSelector((state) => state.vendors);

  const {
    data: { totalItems: totalItemApprovalPendingvendors, content: itemApprovalPendingvendorResult },
    fetching: totalitemApprovalPendingVendorFetching,
  } = useSelector((state) => state.itemApprovalPendingVendors);

  useEffect(() => {
    setVendorOptions(generateVendorOptions(vendorResult));
  }, [vendorResult]);

  useEffect(() => {
    if (approvals) {
      setVendersToVendorResult(itemApprovalPendingvendorResult);
      setTotalVendorCount(totalItemApprovalPendingvendors);
    }
  }, [itemApprovalPendingvendorResult, totalItemApprovalPendingvendors]);

  useEffect(() => {
    if (!approvals) {
      setVendersToVendorResult(vendorResultCatalog);
      setTotalVendorCount(totalVendors);
    }
  }, [vendorResultCatalog, totalVendors]);

  useEffect(() => {
    setItemClassOptions(generateClassesList(classesResult));
  }, [classesResult]);

  const onChangeMasterVendor = (checkedValues) => {
    const previousPageCheckedValues = checkedList.filter((checkedValue) => !vendorOptions.includes(checkedValue));
    const checkedVendors = getCheckedVendors(
      checkedMasterVendors,
      checkedValues,
      vendorResult,
      previousPageCheckedValues
    );
    setCheckedMasterVendors(checkedVendors);

    checkedValues.push(...previousPageCheckedValues);
    setCheckedList(checkedValues);
    setVendorNos(generateVendorNumbers(checkedValues, checkedVendors));
  };

  const onChangeCategory = (checkedValues) => {
    setCheckedItemClassList(checkedValues);
    setClassNames(checkedValues);
  };

  const onChangeStatus = (checkedValues) => {
    setCheckedItemStatusList(checkedValues);
    setStatus(generateStatuses(checkedValues));
  };

  const onChangeApprovals = (checkedValues) => {
    setCheckedApprovalList(checkedValues);
    setApprovalTypes(generateApprovalTypes(checkedValues));
  };
  const onChangeEnrichLevel = (checkedValues) => {
    setCheckedEnrichmentLevelList(checkedValues);
    setEnrichmentLevel(checkedValues);
  };

  const onChangeBrandStatus = (checkedValues) => {
    setCheckedItemBrandList(checkedValues);
    setBrandType(generateBrandTypes(checkedValues));
  };

  const onChangeProprietaryStatus = (checkedValues) => {
    setCheckedItemProprietaryList(checkedValues);
    setProprietaryItem(generateProprietaryTypes(checkedValues));
  };

  const clearVendors = () => {
    onChangeMasterVendor([]);
    setCheckedList([]);
    setVendorNos([]);
    setCheckedMasterVendors([]);
    setSearchValue(null);
    setVendorName(null);
  };

  const toggleCollpasedFilterPanel = () => {
    setFiltersCollapsed(!filtersCollapsed);
  };

  const changeVendorPage = (page, pageSize) => {
    setVendorPage(calculatePage(page));
  };

  return vendorFetching || totalitemApprovalPendingVendorFetching || classesFetching ? (
    <AppLoader show />
  ) : (
    <React.Fragment>
      <div id="filter-panel" className={getFilterPanelClassName(filtersCollapsed)}>
        <div id="filter-panel-title" className="title">
          <div id="filter-panel-label" className="label">
            {t('filterPanel.filter')}
          </div>
          <div id="filter-panel-collapser" className="collapser" onClick={toggleCollpasedFilterPanel}>
            <Icon id="filter-panel-icon" type="caret-left" />
          </div>
        </div>
        {userRole && userRole !== VENDOR && (
          <div id="filter-panel-filter-group" className="filter-group ">
            <div id="filter-panel-filter-group-label" className="label">
              {t('filterPanel.vendor')}
            </div>
            <Search
              id="filter-panel-filter-group-search"
              className="search-item-list"
              placeholder={t('filterPanel.search')}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onSearch={(value) => {
                setVendorName(value);
              }}
            />
            <div id="filter-panel-filter-group-input" className="input">
              <CheckboxGroup
                id="filter-panel-filter-group-checkbox-group"
                value={checkedList}
                options={vendorOptions}
                onChange={onChangeMasterVendor}
              />
              <div
                id="filter-panel-filter-group-sub-group-button"
                className="sub-group-btn"
                onClick={() => clearVendors()}
              >
                {i18n.t('filterPanel.clearAll')}
              </div>
              <Pagination
                id="filter-panel-filter-group-pagination"
                size="small"
                pageSize={vendorPageSize}
                total={totalVendorCount}
                current={vendorPage + 1}
                onChange={(page, pageSize) => changeVendorPage(page, pageSize)}
              />
            </div>

            {/*<Button type="link" onClick={() = change}>Next page</Button>*/}
          </div>
        )}
        {userRole && userRole !== VENDOR && (
          <div id="filter-panel-filter-group-item-class" className="filter-group">
            <div id="filter-panel-filter-group-item-class-label" className="label">
              {t('filterPanel.itemClass')}
            </div>
            <div id="filter-panel-filter-group-item-class-input" className="input">
              <CheckboxGroup
                id="filter-panel-filter-group-item-class-checkbox-group"
                options={itemClassOptions}
                onChange={onChangeCategory}
                value={checkedItemClassList}
              />
            </div>
          </div>
        )}
        {userRole && userRole === VENDOR && (
          <div id="filter-panel-filter-group-enrichment-level" className="filter-group">
            <div id="filter-panel-filter-group-enrichment-level-label" className="label">
              {t('filterPanel.enrichmentLevel')}
            </div>
            <div id="filter-panel-filter-group-enrichment-level-input" className="input">
              <CheckboxGroup
                id="filter-panel-filter-group-enrichment-level-checkbox-group"
                options={levelOptions}
                onChange={onChangeEnrichLevel}
                value={checkedEnrichmentLevelList}
              />
            </div>
          </div>
        )}
        {userRole && userRole === VENDOR && (
          <div id="filter-panel-filter-group-approvals" className="filter-group">
            <div id="filter-panel-filter-group-approvals-label" className="label">
              {t('filterPanel.approvals')}
            </div>
            <div id="filter-panel-filter-group-approvals-input" className="input">
              <CheckboxGroup
                id="filter-panel-filter-group-approvals-checkbox-group"
                options={approvalsOptions}
                onChange={onChangeApprovals}
                value={checkedApprovalList}
              />
            </div>
          </div>
        )}
        {/* <div id="filter-panel-filter-group-item-status" className="filter-group">
          <div id="filter-panel-filter-group-item-status-label" className="label">
            {t('filterPanel.itemStatus')}
          </div>
          <div id="filter-panel-filter-group-item-status-input" className="input">
            <CheckboxGroup
              id="filter-panel-filter-group-item-status-checkbox-group"
              options={statusOptions}
              onChange={onChangeStatus}
              value={checkedItemStatusList}
            />
          </div>
        </div> */}
        <div id="filter-panel-filter-group-item-brand" className="filter-group">
          <div id="filter-panel-filter-group-item-brand-label" className="label">
            {t('filterPanel.syscoBrand')}
          </div>
          <div id="filter-panel-filter-group-item-brand-input" className="input">
            <CheckboxGroup
              id="filter-panel-filter-group-item-brand-checkbox-group"
              options={brandOptions}
              onChange={onChangeBrandStatus}
              value={checkedItemBrandList}
            />
          </div>
        </div>
        {userRole && userRole === VENDOR && (
          <div id="filter-panel-filter-group-item-proprietary" className="filter-group">
            <div id="filter-panel-filter-group-item-proprietary-label" className="label">
              {t('filterPanel.proprietaryItem')}
            </div>
            <div id="filter-panel-filter-group-item-proprietary-input" className="input">
              <CheckboxGroup
                id="filter-panel-filter-group-item-proprietary-checkbox-group"
                options={proprietaryOptions}
                onChange={onChangeProprietaryStatus}
                value={checkedItemProprietaryList}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default FilterPanel;

FilterPanel.propTypes = {};
