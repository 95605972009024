import React from 'react';

function CoreData({ blockId, blockClassName, labelId, label, dataId, value }) {
  return value ? (
    <div id={blockId} className={blockClassName}>
      <div id={labelId} className="label">
        {label}
      </div>
      <div id={dataId} className="data">
        {value}
      </div>
    </div>
  ) : null;
}

export default CoreData;
