import React from 'react';
import { Icon, Button, Tooltip } from 'antd';
import i18n from '../../../_i18n';
import { PRODUCT_IMAGE_PREFIX, VIDEO_PREFIX } from '../../../constants/Constants';
import { APPROVED, DELETE_APPROVED, DELETE_PENDING } from '../../../enums/ApprovalStatus';
import { attributeEditorDetails } from '../../../utils/Utils';
import { PRODUCT_IMAGE } from 'enums/AttributeType';

function ImagePendingStatusWidget({
  image,
  imageList,
  handleApprovingPendingProductImages,
  setProductImageRejectModal,
  approvable,
  editable,
  userType,
  attributeType,
}) {
  return (
    <div id="image-pending-status-widget-footer" className="footer-bar highlight warning">
      <Icon
        id="image-pending-status-widget-footer-icon"
        type="warning"
        theme="filled"
        className="icon status-icon warning"
      />
      <div id="image-pending-status-widget-footer-label" className="label">
        {image?.attributeStatus === DELETE_PENDING
          ? i18n.t('mediaColumn.pendingApprovalDelete')
          : i18n.t('mediaColumn.pendingApproval')}
      </div>
      <div id="image-pending-status-widget-footer-actions" className="footer-actions">
        {approvable && (
          <React.Fragment>
            <Button
              id="image-pending-status-widget-footer-approve"
              className="btn green-action-btn invert"
              onClick={() => {
                handleApprovingPendingProductImages(
                  attributeType === PRODUCT_IMAGE ? image?.value?.imageID : image?.value?.videoID,
                  getCopySource(attributeType, image),
                  getDestination(attributeType, image),
                  image,
                  imageList,
                  attributeType,
                  image?.attributeStatus === DELETE_PENDING ? DELETE_APPROVED : APPROVED
                );
              }}
            >
              <Icon id="image-pending-status-widget-footer-approve-icon" type="check" />
              {i18n.t('infoColumn.approve')}
            </Button>
            <Button
              id="image-pending-status-widget-footer-reject"
              className="btn red-action-btn invert"
              onClick={() => {
                setProductImageRejectModal(true);
              }}
            >
              <Icon id="image-pending-status-widget-footer-reject-icon" type="close" />
              {i18n.t('infoColumn.reject')}
            </Button>
          </React.Fragment>
        )}
        {(approvable || editable) && (
          <Tooltip
            id="image-pending-status-widget-tooltip"
            title={attributeEditorDetails(userType, image.attributeStatus, image)}
          >
            <i
              id="image-pending-status-widget-tooltip-icon"
              className="info-icon icon fi flaticon-information-button"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

const getCopySource = (attributeType, image) => {
  return `staging/${attributeType === PRODUCT_IMAGE ? PRODUCT_IMAGE_PREFIX : VIDEO_PREFIX}${
    attributeType === PRODUCT_IMAGE ? image?.value?.imageID : image?.value?.videoID
  }`;
};

const getDestination = (attributeType, image) => {
  return `${attributeType === PRODUCT_IMAGE ? PRODUCT_IMAGE_PREFIX : VIDEO_PREFIX}${
    attributeType === PRODUCT_IMAGE ? image?.value?.imageID : image?.value?.videoID
  }`;
};

export default ImagePendingStatusWidget;
