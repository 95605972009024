import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sheetVisibility } from './MsdsSheet';

function Recipes({ mediaInfo, idMobile }) {
  const { t } = useTranslation();

  const {
    data: { userRole: userType },
  } = useSelector((state) => state?.userData);

  return (
    <div id={`common-media-widgets-preview-recipes-${idMobile}`} className="recipes">
      {mediaInfo?.recipes?.length > 0 ? (
        <div id={`common-media-widgets-preview-recipes-row-${idMobile}`} className="info-row">
          <div id={`common-media-widgets-preview-recipes-row-datablock-${idMobile}`} className="data-block no-highlight">
            <div id={`common-media-widgets-preview-recipes-row-datablock-label-${idMobile}`} className="label">
              {t('mediaWidgetColumn.additionalResources')}
            </div>
            <ul>
              {mediaInfo.recipes
                ?.filter((recipe) => recipe?.value?.recipeURL && sheetVisibility(recipe, userType))
                ?.map((recipe, i) => (
                  <div
                    id={`common-media-widgets-preview-recipes-row-datablock-thumbnail-${i + 1}-${idMobile}`}
                    key={`common-media-widgets-preview-recipes-row-datablock-thumbnail-${recipe?.value?.recipeURL}`}
                    className="file-thumbnail"
                  >
                    <div
                      id={`common-media-widgets-preview-recipes-row-datablock-fileinfo-${i + 1}-${idMobile}`}
                      className="inner-file-info"
                    >
                      <a
                        id={`common-media-widgets-preview-recipes-row-datablock-icon-${i + 1}-${idMobile}`}
                        className="icon"
                        href={recipe?.value?.recipeURL}
                        target="_blank"
                      />
                      <div
                        id={`common-media-widgets-preview-recipes-row-datablock-info-${i + 1}-${idMobile}`}
                        className="info"
                      >
                        <div
                          id={`common-media-widgets-preview-recipes-row-datablock-filename-${i + 1}-${idMobile}`}
                          className="filename"
                        >
                          <a
                            id={`common-media-widgets-preview-recipes-row-datablock-fileurl-${i + 1}-${idMobile}`}
                            href={recipe?.value?.recipeURL}
                            target="_blank"
                          >
                            {recipe?.value?.recipeName}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Recipes;
