import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppLoader from '../../../../../components/AppLoader';
import CoreData from './CoreData';
import Packaging from './Packaging';
import Brand from './Brand';
import ItemOverview from './ItemOverview';
import { REJECTED } from 'enums/ApprovalStatus';
import InfoColumnStaticAttribute from './InfoColumnStaticAttribute';
import Allergens from 'productSheet/components/InfoColumn/Allergens';

function getCurrentValue(attribute, userType) {
  return attribute?.attributeStatus === REJECTED && attribute?.authorRole !== userType
    ? attribute?.previousValue
    : attribute?.value;
}

const isFabsAvailable = (coreData) => {
  return (
    coreData?.productDescriptor ||
    coreData?.packagingInformation ||
    coreData?.sizeAndShapeOfProduct ||
    coreData?.yieldOrServingSize ||
    coreData?.qualityAndFormat ||
    coreData?.preparationAndCookingInstructions ||
    coreData?.storageAndUsage ||
    coreData?.handlingInstructions ||
    coreData?.additionalProductInformation ||
    coreData?.marketingStatements ||
    coreData?.culinaryApplications
  );
};

const getDescription = (coreData) => {
  return coreData?.titleDescription?.value || coreData?.idsDescription;
};

const isFetchng = (updateFetching, attrFetching) => {
  return updateFetching || attrFetching;
};

function InfoColumnPreview({ coreData, flags, enrichable }) {
  const { t } = useTranslation();

  useDispatch();
  const { fetching: updateFetching } = useSelector((state) => state.item);
  const { fetching: attrFetching } = useSelector((state) => state.attributes);
  const {
    data: { userRole: userType },
  } = useSelector((state) => state.userData);

  return isFetchng(updateFetching, attrFetching) ? (
    <AppLoader show />
  ) : (
    coreData != null && (
      <div id="info-column-preview" className="info-column">
        <div id="info-column-preview-title" className="title-description editable inline-edits">
          <span id="info-column-preview-title-description" className="data">
            {getDescription(coreData)}
          </span>
        </div>
        <ItemOverview id={'item-product-descriptor'} value={getCurrentValue(coreData.productDescriptor, userType)} />
        <div id="info-column-preview-row-brand-manufacturer" className="info-row brand-manufacturer-wrapper">
          <CoreData
            blockId={'info-column-preview-datablock-brand'}
            blockClassName={'data-block brand'}
            labelId={'info-column-preview-datablock-brand-label'}
            label={t('infoColumn.brand')}
            dataId={'info-column-preview-datablock-brand-data'}
            value={coreData?.brand}
          />

          <CoreData
            blockId={'info-column-preview-datablock-manufacturer'}
            blockClassName={'data-block manufacturer'}
            labelId={'info-column-preview-datablock-manufacturer-label'}
            label={t('infoColumn.mpc')}
            dataId={'info-column-preview-datablock-manufacturer-data'}
            value={coreData?.manufacturer}
          />
        </div>
        <div id="info-column-preview-row-inline-1" className="info-row inline-labels inline-labels-supc-row">
          <CoreData
            blockId={'info-column-preview-datablock-supc'}
            blockClassName={'data-block supc'}
            labelId={'info-column-preview-datablock-supc-label'}
            label={t('infoColumn.supc')}
            dataId={'info-column-preview-datablock-supc-data'}
            value={coreData?.supc}
          />

          <CoreData
            blockId={'info-column-preview-datablock-gtin'}
            blockClassName={'data-block gtin'}
            labelId={'info-column-preview-datablock-gtin-label'}
            label={t('infoColumn.gtin')}
            dataId={'info-column-preview-datablock-gtin-data'}
            value={coreData?.gtin}
          />
        </div>

        <div id="info-column-preview-row-inline-2" className="info-row inline-labels">
          <CoreData
            blockId={'info-column-preview-datablock-class'}
            blockClassName={'data-block class'}
            labelId={'info-column-preview-datablock-class-label'}
            label={t('infoColumn.class')}
            dataId={'info-column-preview-datablock-class-data'}
            value={coreData?.itemClass}
          />
          <CoreData
            blockId={'info-column-preview-datablock-category'}
            blockClassName={'data-block category'}
            labelId={'info-column-preview-datablock-category-label'}
            label={t('infoColumn.category')}
            dataId={'info-column-preview-datablock-category-data'}
            value={coreData?.category}
          />
        </div>

        <div id="info-column-preview-row-inline-3" className="info-row inline-labels">
          <CoreData
            blockId={'info-column-category-datablock'}
            blockClassName={'data-block category'}
            labelId={'info-column-category-datablock-label'}
            label={t('infoColumn.gln')}
            dataId={'info-column-category-datablock-data'}
            value={coreData?.gln}
          />
          <CoreData
            blockId={'info-column-category-datablock'}
            blockClassName={'data-block category'}
            labelId={'info-column-category-datablock-label'}
            label={t('infoColumn.trueVendor')}
            dataId={'info-column-category-datablock-data'}
            value={coreData?.trueVendor}
          />
        </div>

        <div id="info-column-preview-info-row-1" className="info-row">
          {/* <ItemStatus coreData={coreData} /> */}
          <CoreData
            blockId={'info-column-preview-datablock-pack'}
            blockClassName={'data-block pack'}
            labelId={'info-column-preview-datablock-pack-label'}
            label={t('infoColumn.pack')}
            dataId={'info-column-preview-datablock-pack-data'}
            value={coreData?.pack}
          />

          <CoreData
            blockId={'info-column-preview-datablock-size'}
            blockClassName={'data-block size'}
            labelId={'info-column-preview-datablock-size-label'}
            label={t('infoColumn.itemSize')}
            dataId={'info-column-preview-datablock-size-data'}
            value={coreData?.itemSize}
          />
        </div>
        <Packaging coreData={coreData} flags={flags} />

        <div id="info-column-preview-info-row-3" className="info-row">
          {coreData?.attributes?.filter((att) => att?.value?.attributeValue)?.length > 0 && (
            <div id="info-column-preview-datablock-attributes" className="data-block editable">
              <div id="info-column-preview-datablock-attributes-label" className="label">
                {t('infoColumn.attributes')}
              </div>
              <div id="info-column-preview-datablock-attributes-tags" className="tags">
                {coreData?.attributes
                  ?.filter((att) => att?.value?.attributeValue)
                  ?.map((attribute) => (
                    <div
                      id={`info-column-preview-attributes-${attribute?.value?.attributeValue}`}
                      key={`info-column-preview-attributes-${attribute?.value?.attributeValue}`}
                      className={`tag ${attribute?.value?.type}`}
                      title="Product highilight"
                    >
                      <div id="info-column-preview-datablock-attributes-icon" className="canada-icon" />
                      {attribute?.value?.attributeValue}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>

        <Allergens coreData={coreData} />

        <span className="info-column-attributes-divider"></span>
        {enrichable && (<div id="item-features-and-benefits-main" className="info-row">
          {isFabsAvailable(coreData) && (
            <div id="item-features-and-benefits-block" className="data-block ">
              <div id="item-features-and-benefits-label" className="label">
                {t('infoColumn.featuresAndBenefits')}
              </div>
            </div>
          )}
          <InfoColumnStaticAttribute
            id={'packaging-information'}
            label={t('infoColumn.packagingInformation')}
            value={getCurrentValue(coreData.packagingInformation, userType)}
          />
          <InfoColumnStaticAttribute
            id={'size-and-shape'}
            label={t('infoColumn.sizeAndShapeOfProduct')}
            value={getCurrentValue(coreData.sizeAndShapeOfProduct, userType)}
          />
          <InfoColumnStaticAttribute
            id={'yield-or-serving-size'}
            label={t('infoColumn.yieldOrServingSize')}
            value={getCurrentValue(coreData.yieldOrServingSize, userType)}
          />
          <InfoColumnStaticAttribute
            id={'quality-and-format'}
            label={t('infoColumn.qualityAndFormat')}
            value={getCurrentValue(coreData.qualityAndFormat, userType)}
          />
          <InfoColumnStaticAttribute
            id={'pnc'}
            label={t('infoColumn.preparationAndCookingInstructions')}
            value={getCurrentValue(coreData.preparationAndCookingInstructions, userType)}
          />
          <InfoColumnStaticAttribute
            id={'storage-and-usage'}
            label={t('infoColumn.storageAndUsage')}
            value={getCurrentValue(coreData.storageAndUsage, userType)}
          />
          <InfoColumnStaticAttribute
            id={'handling-instructions'}
            label={t('infoColumn.handlingInstructions')}
            value={getCurrentValue(coreData.handlingInstructions, userType)}
          />
          <InfoColumnStaticAttribute
            id={'additional-information'}
            label={t('infoColumn.additionalProductInformation')}
            value={getCurrentValue(coreData.additionalProductInformation, userType)}
          />
          <InfoColumnStaticAttribute
            id={'marketing-statements'}
            label={t('infoColumn.marketingStatements')}
            value={getCurrentValue(coreData.marketingStatements, userType)}
          />
          <InfoColumnStaticAttribute
            id={'serving-suggestions'}
            label={t('infoColumn.culinaryApplications')}
            value={getCurrentValue(coreData.culinaryApplications, userType)}
          />
        </div>)}
        {enrichable && (<Brand coreData={coreData} />)}
      </div>
    )
  );
}

export default InfoColumnPreview;
