import {
  CERTIFICATION,
  LIFESTYLE_IMAGE,
  SPECIFICATION_SHEET,
  PRODUCT_IMAGE,
  RECIPES,
  VIDEO,
  MSDS_SHEET,
  PRODUCT_DESCRIPTOR,
  PACKAGING_INFORMATION,
  SIZE_AND_SHAPE_OF_PRODUCT,
  YIELD_OR_SERVING_SIZE,
  QUALITY_AND_FORMAT,
  PREPARATION_AND_COOKING_INSTRUCTIONS,
  STORAGE_AND_USAGE,
  HANDLING_INSTRUCTIONS,
  ADDITIONAL_PRODUCT_INFORMATION,
  MARKETING_STATEMENTS,
  CULINARY_APPLICATIONS,
  GS1_IMAGE,
} from '../enums/AttributeType';
import {
  CERTIFICATES_PREFIX,
  LIFESTYLE_PREFIX,
  SPECIFICATION_PREFIX,
  MSDS_PREFIX,
  PRODUCT_IMAGE_PREFIX,
  GS1_IMAGE_PREFIX,
  RECIPE_PREFIX,
  VIDEO_PREFIX,
  GS1,
  P_REGEX,
  C_REGEX,
  M_REGEX,
} from '../constants/Constants';
import i18n from '../_i18n';
import { APPROVED, REJECTED, DELETE_APPROVED, DELETE_REJECTED, PENDING, DELETE_PENDING } from '../enums/ApprovalStatus';

const adPrefix = process.env.REACT_APP_AD_PREFIX;
const oktaPrefix = process.env.REACT_APP_OKTA_PREFIX;

export const checkFileName = (uploadType, fileName) => {
  let filenameWithPciPrefix;
  switch (uploadType) {
    case LIFESTYLE_IMAGE:
      filenameWithPciPrefix = LIFESTYLE_PREFIX + fileName;
      break;
    case SPECIFICATION_SHEET:
      filenameWithPciPrefix = SPECIFICATION_PREFIX + fileName;
      break;
    case MSDS_SHEET:
      filenameWithPciPrefix = MSDS_PREFIX + fileName;
      break;
    case CERTIFICATION:
      filenameWithPciPrefix = CERTIFICATES_PREFIX + fileName;
      break;
    case PRODUCT_IMAGE:
      filenameWithPciPrefix = PRODUCT_IMAGE_PREFIX + fileName;
      break;
    case GS1_IMAGE:
      filenameWithPciPrefix = GS1_IMAGE_PREFIX + fileName;
      break;
    case VIDEO:
      if (fileName?.includes('.jpg')) {
        filenameWithPciPrefix = VIDEO_PREFIX + 'thumbnails/' + fileName;
      } else {
        filenameWithPciPrefix = VIDEO_PREFIX + fileName;
      }
      break;
    case RECIPES:
      filenameWithPciPrefix = RECIPE_PREFIX + fileName;
      break;
    default:
      filenameWithPciPrefix = fileName;
  }
  return filenameWithPciPrefix;
};

export const getFileName = (uploadType, fileName, approvable) => {
  let filenameWithPciPrefix = checkFileName(uploadType, fileName);
  return approvable ? filenameWithPciPrefix : 'staging/' + filenameWithPciPrefix;
};

export const getFileNameForDelete = (uploadType, fileName, status) => {
  let filenameWithPciPrefix = checkFileName(uploadType, fileName);
  return status === 'PENDING' ? 'staging/' + filenameWithPciPrefix : filenameWithPciPrefix;
};

export const subFieldNameSelector = (attributeType) => {
  let i18nSubFieldName;
  switch (attributeType) {
    case PRODUCT_DESCRIPTOR:
      i18nSubFieldName = i18n.t('infoColumn.productDescriptor');
      break;
    case PACKAGING_INFORMATION:
      i18nSubFieldName = i18n.t('infoColumn.packagingInformation');
      break;
    case SIZE_AND_SHAPE_OF_PRODUCT:
      i18nSubFieldName = i18n.t('infoColumn.sizeAndShapeOfProduct');
      break;
    case YIELD_OR_SERVING_SIZE:
      i18nSubFieldName = i18n.t('infoColumn.yieldOrServingSize');
      break;
    case QUALITY_AND_FORMAT:
      i18nSubFieldName = i18n.t('infoColumn.qualityAndFormat');
      break;
    case PREPARATION_AND_COOKING_INSTRUCTIONS:
      i18nSubFieldName = i18n.t('infoColumn.preparationAndCookingInstructions');
      break;
    case STORAGE_AND_USAGE:
      i18nSubFieldName = i18n.t('infoColumn.storageAndUsage');
      break;
    case HANDLING_INSTRUCTIONS:
      i18nSubFieldName = i18n.t('infoColumn.handlingInstructions');
      break;
    case ADDITIONAL_PRODUCT_INFORMATION:
      i18nSubFieldName = i18n.t('infoColumn.additionalProductInformation');
      break;
    case MARKETING_STATEMENTS:
      i18nSubFieldName = i18n.t('infoColumn.marketingStatements');
      break;
    case CULINARY_APPLICATIONS:
      i18nSubFieldName = i18n.t('infoColumn.culinaryApplications');
      break;
  }
  return i18nSubFieldName;
};

export const subFieldCommentSelector = (attributeType) => {
  let i18nSubFieldComment;
  switch (attributeType) {
    case PRODUCT_DESCRIPTOR:
      i18nSubFieldComment = i18n.t('infoColumn.productDescriptor.comment');
      break;
    case PACKAGING_INFORMATION:
      i18nSubFieldComment = i18n.t('infoColumn.packagingInformation.comment');
      break;
    case SIZE_AND_SHAPE_OF_PRODUCT:
      i18nSubFieldComment = i18n.t('infoColumn.sizeAndShapeOfProduct.comment');
      break;
    case YIELD_OR_SERVING_SIZE:
      i18nSubFieldComment = i18n.t('infoColumn.yieldOrServingSize.comment');
      break;
    case QUALITY_AND_FORMAT:
      i18nSubFieldComment = i18n.t('infoColumn.qualityAndFormat.comment');
      break;
    case PREPARATION_AND_COOKING_INSTRUCTIONS:
      i18nSubFieldComment = i18n.t('infoColumn.preparationAndCookingInstructions.comment');
      break;
    case STORAGE_AND_USAGE:
      i18nSubFieldComment = i18n.t('infoColumn.storageAndUsage.comment');
      break;
    case HANDLING_INSTRUCTIONS:
      i18nSubFieldComment = i18n.t('infoColumn.handlingInstructions.comment');
      break;
    case ADDITIONAL_PRODUCT_INFORMATION:
      i18nSubFieldComment = i18n.t('infoColumn.additionalProductInformation.comment');
      break;
    case MARKETING_STATEMENTS:
      i18nSubFieldComment = i18n.t('infoColumn.marketingStatements.comment');
      break;
    case CULINARY_APPLICATIONS:
      i18nSubFieldComment = i18n.t('infoColumn.culinaryApplications.comment');
      break;
  }
  return i18nSubFieldComment;
};

export const attributeEditorDetails = (user, attributeStatus, attribute) => {
  if (attributeStatus === PENDING || attributeStatus === DELETE_PENDING) {
    return `${i18n.t('infoColumn.editedBy')} ${attribute?.author} at ${attribute?.timestamp}`;
  } else if (attributeStatus === APPROVED || attributeStatus === DELETE_APPROVED) {
    return `${i18n.t('infoColumn.approvedBy')} ${attribute?.author} at ${attribute?.timestamp}`;
  } else if (attributeStatus === REJECTED || attributeStatus === DELETE_REJECTED) {
    return `${i18n.t('infoColumn.rejectedBy')} ${attribute?.author} at ${attribute?.timestamp} `;
  }
};

export const isValidToDisplay = (attribute, userRole) => {
  return !(
    (attribute?.attributeStatus === REJECTED || attribute?.attributeStatus === DELETE_APPROVED) &&
    attribute?.authorRole !== userRole
  );
};

export const isValidToDisplayWhenDeleteApproved = (attribute, userRole) => {
  return !(
    (attribute?.attributeStatus === DELETE_APPROVED || (attribute?.attributeStatus === REJECTED && (attribute?.previousValue === null || attribute?.previousValue?.url === "")) ) && 
    attribute?.authorRole !== userRole
  );
};

export const chooseGS1Image = (images, pRegex, cRegex, mRegex) => {
  return (
    images.filter((image) => image.url && pRegex.test(image.url))[0] ||
    images.filter((image) => image.url && cRegex.test(image.url))[0] ||
    images.filter((image) => image.url && mRegex.test(image.url))[0] ||
    images[0]
  );
};

export const getMainImage = (media, userRole) => {
  const pRegex = new RegExp(P_REGEX);
  const cRegex = new RegExp(C_REGEX);
  const mRegex = new RegExp(M_REGEX);

  return (
    (media?.lifestyleImageUrl?.value?.url?.length > 0 &&
      isValidToDisplayWhenDeleteApproved(media?.lifestyleImageUrl, userRole) &&
      media?.lifestyleImageUrl) ||
    (media?.gs1Images?.length > 0 && chooseGS1Image(media?.gs1Images, pRegex, cRegex, mRegex)) ||
    (media?.otherImageUrls?.filter((im) => isValidToDisplay(im, userRole))?.length > 0 &&
      media?.otherImageUrls?.filter((im) => im && isValidToDisplay(im, userRole) && im?.value?.url?.length > 0)[0]) ||
    (media?.videos?.filter((vi) => isValidToDisplay(vi, userRole))?.length > 0 &&
      media?.videos?.filter((vi) => vi && isValidToDisplay(vi, userRole) && vi?.value?.url?.length > 0)[0])
  );
};

export const getMainImageForPreview = (media, userRole, enrichable) => {
  const pRegex = new RegExp(P_REGEX);
  const cRegex = new RegExp(C_REGEX);
  const mRegex = new RegExp(M_REGEX);

  return (
    (enrichable && media?.lifestyleImageUrl?.value?.url?.length > 0 &&
      isValidToDisplayWhenDeleteApproved(media?.lifestyleImageUrl, userRole) &&
      media?.lifestyleImageUrl) ||
    (media?.gs1Images?.length > 0 && chooseGS1Image(media?.gs1Images, pRegex, cRegex, mRegex)) ||
    (enrichable && media?.otherImageUrls?.filter((im) => isValidToDisplay(im, userRole))?.length > 0 &&
      media?.otherImageUrls?.filter((im) => im && isValidToDisplay(im, userRole) && im?.value?.url?.length > 0)[0]) ||
    (enrichable && media?.videos?.filter((vi) => isValidToDisplay(vi, userRole))?.length > 0 &&
      media?.videos?.filter((vi) => vi && isValidToDisplay(vi, userRole) && vi?.value?.url?.length > 0)[0])
  );
};

export const checkFabsEditable = (editable, identifier) => {
  return identifier !== GS1 && editable ? true : false;
};


