import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactImageMagnify from 'react-image-magnify';
import { GS1_IMAGE, LIFESTYLE_IMAGE, PRODUCT_IMAGE, VIDEO } from 'enums/AttributeType';
import { useDispatch, useSelector } from 'react-redux';
import CommonMediaWidgets from './CommonMediaWidgets';
import UploadModal from '../../../components/modals/UploadModal';
import RejectModal from '../../../components/modals/RejectModal';
import ImageApprovedStatusWidget from './ImageApprovedStatusWidget';
import ImageRejectedStatusWidget from './ImageRejectedStatusWidget';
import ImageDelete from './ImageDelete';
import LifestyleImageDelete from './LifestyleImageDelete';
import LifestyleImagePending from './LifestyleImagePending';
import LifestyleImageApproved from './LifestyleImageApproved.js';
import LifestyleImageRejected from './LifestyleImageRejected.js';
import MainImagePreview from './MainImagePreview.js';
import { approvePendingFile, deleteFile, updateStagingAttribute, updateItem } from '../../../actions';
import i18n from '../../../_i18n';

import {
  PENDING,
  APPROVED,
  REJECTED,
  DELETE_APPROVED,
  DELETE_PENDING,
  DELETE_REJECTED,
} from '../../../enums/ApprovalStatus';
import ImagePendingStatusWidget from './ImagePendingStatusWidget';
import { isValidToDisplay, getMainImage, isValidToDisplayWhenDeleteApproved } from '../../../utils/Utils';

function checkImageApprovedStatus(image, userRole) {
  return (
    image?.authorRole === userRole &&
    (image?.attributeStatus === APPROVED || image?.attributeStatus === DELETE_APPROVED) &&
    (image?.attributeType === PRODUCT_IMAGE || image?.attributeType === VIDEO)
  );
}

function checkImageRejectedStatus(image, userRole) {
  return (
    image?.authorRole === userRole &&
    (image?.attributeStatus === REJECTED || image?.attributeStatus === DELETE_REJECTED) &&
    (image?.attributeType === PRODUCT_IMAGE || image?.attributeType === VIDEO)
  );
}

function checkImagePendingStatus(image) {
  return (
    (image?.attributeStatus === PENDING || image?.attributeStatus === DELETE_PENDING) &&
    (image?.attributeType === PRODUCT_IMAGE || image?.attributeType === VIDEO)
  );
}

function checkIsEditable(currentImage, attributeManagement) {
  return currentImage?.attributeType === PRODUCT_IMAGE
    ? attributeManagement.PRODUCT_IMAGE.editable
    : attributeManagement.VIDEO.editable;
}

function checkIsApprovable(currentImage, attributeManagement) {
  return currentImage?.attributeType === PRODUCT_IMAGE
    ? attributeManagement.PRODUCT_IMAGE.approvable
    : attributeManagement.VIDEO.approvable;
}

function setImageList(currentImage, media) {
  return currentImage?.attributeType === PRODUCT_IMAGE ? media?.otherImageUrls : media?.videos;
}

function checkLifestyleHeaderValidToDisplay (currentImage,userRole){
  return (currentImage?.attributeType === LIFESTYLE_IMAGE && 
    !((currentImage?.attributeStatus === REJECTED && currentImage?.previousValue === null) &&
    currentImage?.authorRole !== userRole ))
}

const getLifestyleImageUrlToDisplay = (lifestyleImage, userRole) =>{
  return isValidToDisplay(lifestyleImage, userRole) ? lifestyleImage?.value : lifestyleImage?.previousValue
}

function MediaColumn({ media, mediaInfo, supc, permissions }) {
  const dispatch = useDispatch();
  const [videoSrc, setvideoSrc] = useState('');
  const [isVideoImage, setisVideoImage] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isVideoPreview, setisVideoPreview] = useState(false);
  const [previousImageType, setPreviousImageType] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [currentImage, setCurrentImage] = useState({});
  const [mainImage, setMainImage] = useState(null);
  const [mainImageType, setMainImageType] = useState(null);

  const [lifeStyleImageRejectModal, setLifeStyleImageRejectModal] = useState(false);
  const [productImageRejectModal, setProductImageRejectModal] = useState(false);

  const [stagingAttribute, setStagingAttribute] = useState(null);
  console.log('currentImage', currentImage);

  const { fetching: deleteFileFetching } = useSelector((state) => state.deleteFile);
  const { fetching: updateAttributeFetching } = useSelector((state) => state.updateAttribute);

  const {
    data: { fullName: userId, userRole, associatedUsers },
  } = useSelector((state) => state.userData);
  const { fetching: approvePendingFileFetching } = useSelector((state) => state.approvePendingFile);

  const ChangeMainImage = (targetelem, isVideo, videoUrl, isLifestyle, isOtherImage, isGS1) => {
    setMainImage(targetelem);
    if (isLifestyle) {
      setMainImageType(LIFESTYLE_IMAGE);
    } else if (isOtherImage) {
      setMainImageType(PRODUCT_IMAGE);
    } else if (isVideo) {
      setMainImageType(VIDEO);
    } else if (isGS1) {
      setMainImageType(GS1_IMAGE);
    } else {
      setMainImageType(null);
    }
    currentImage?.attributeType === VIDEO ? setVideoParams(videoUrl) : setisVideoImage(false);
  };

  const setVideoParams = (videoUrl) => {
    setvideoSrc(videoUrl);
    setisVideoImage(true);
  };

  const togglePreview = () => {
    setShowPreview(!showPreview);
    currentImage?.attributeType === VIDEO ? setisVideoPreview(true) : setisVideoPreview(false);
  };

  const [images, setImages] = useState([]);

  useEffect(() => {
    stagingAttribute && dispatch(updateStagingAttribute(supc, i18n.language, stagingAttribute, associatedUsers));
  }, [stagingAttribute]);

  useEffect(() => {
    currentImage?.attributeType === VIDEO ? setVideoParams(currentImage?.value?.url) : setisVideoImage(false);
  }, [currentImage]);

  const handleDismiss = (fileName, uploadType, file, attributeStatus) => {
    if (attributeStatus == null && (file?.attributeStatus === DELETE_APPROVED || file?.attributeStatus === REJECTED)) {
      handleLifestyleImageDelete(fileName, uploadType, attributeStatus, file);
    } else {
      handleSave(uploadType, file, attributeStatus, null);
    }
  };

  const handleSave = (attributeType, attributeValue, status, comment) => {
    const attribute = {
      attributeType: attributeType,
      value: attributeValue,
      attributeStatus: status,
      comment,
      author: userId,
      timestamp: null,
      authorRole: userRole,
    };
    setPreviousImageType(attributeType);
    setStagingAttribute(attribute);
  };

  useEffect(() => {
    if (updateAttributeFetching === false && previousImageType === LIFESTYLE_IMAGE) {
      dispatch(updateItem());
      setPreviousImageType(null);
    }
  }, [updateAttributeFetching]);

  const handleProductImagesSave = (attributeType, file, fileList, status, comment) => {
    const updatedFile = {
      ...file,
      attributeStatus: status,
      comment,
      author: userId,
      identifier: attributeType === PRODUCT_IMAGE ? file?.value?.imageID : file?.value?.videoID,
      authorRole: userRole,
    };
    if (file?.attributeStatus === DELETE_APPROVED && status === null) {
      dispatch(
        deleteFile(
          attributeType === PRODUCT_IMAGE ? file?.value?.imageID : file?.value?.videoID,
          attributeType,
          supc,
          userId,
          i18n.language,
          status,
          updatedFile,
          userRole,
          associatedUsers
        )
      );
    } else {
      const attribute = {
        attributeType,
        value: [updatedFile],
        attributeStatus: status,
        comment,
        author: userId,
        timestamp: null,
        authorRole: userRole,
      };
      dispatch(updateStagingAttribute(supc, i18n.language, attribute, associatedUsers));
    }
  };

  const handleImagesDelete = async (fileName, uploadType, file, files, attributeStatus, identifier) => {
    const updatedFile = {
      attributeType: uploadType,
      value: uploadType === GS1_IMAGE ? file : file?.value,
      attributeStatus,
      comment: null,
      author: userId,
      timestamp: null,
      identifier,
      authorRole: userRole,
    };

    dispatch(
      deleteFile(
        fileName,
        uploadType,
        supc,
        userId,
        i18n.language,
        attributeStatus,
        updatedFile,
        userRole,
        associatedUsers
      )
    );
  };

  useEffect(() => {
    if (deleteFileFetching === false && previousImageType === LIFESTYLE_IMAGE) {
      dispatch(updateItem());
      setPreviousImageType(null);
    }
  }, [deleteFileFetching]);

  const handleLifestyleImageDelete = async (fileName, uploadType, attributeStatus, file) => {
    setPreviousImageType(uploadType);
    dispatch(
      deleteFile(fileName, uploadType, supc, userId, i18n.language, attributeStatus, file, userRole, associatedUsers)
    );
  };

  const generateImages = () =>{
    let imagesArr = [];
    (media?.lifestyleImageUrl?.value?.url) &&
    isValidToDisplayWhenDeleteApproved(media?.lifestyleImageUrl, userRole) &&
    getLifestyleImageUrlToDisplay(media?.lifestyleImageUrl,userRole) &&
    imagesArr.push(
      <li
        id={`media-column-lifestyle-image-${getLifestyleImageUrlToDisplay(media?.lifestyleImageUrl,userRole)?.imageID.split('.')[0]}`}
        key={uuidv4()}
        style={{
          background:
            'url(' + getLifestyleImageUrlToDisplay(media?.lifestyleImageUrl,userRole)?.url + '?key=' + uuidv4() + ') no-repeat center center/contain',
        }}
        onClick={() => {
          ChangeMainImage(getLifestyleImageUrlToDisplay(media?.lifestyleImageUrl,userRole)?.url, null, null, true, false, false);
          setCurrentImage(media.lifestyleImageUrl);
        }}
      />
    );

    imagesArr.push(
      media?.gs1Images?.map((gs1Image, i) => (
        <li
          id={`media-column-gs1-image-${i}`}
          key={uuidv4()}
          style={{ background: 'url(' + gs1Image?.url + ') no-repeat center center/contain' }}
          onClick={() => {
            ChangeMainImage(gs1Image?.url, null, null, false, false, true);
            setCurrentImage(gs1Image);
          }}
        />
      ))
    );

    imagesArr.push(
      media?.otherImageUrls
        ?.filter(
          (otherImage) =>
            otherImage && otherImage?.value && otherImage?.value?.url && isValidToDisplay(otherImage, userRole)
        )
        ?.map((otherImage, i) => (
          <li
            id={`media-column-product-image-${i}`}
            key={uuidv4()}
            style={{ background: 'url(' + otherImage.value.url + '?key='+ uuidv4()+ ') no-repeat center center/contain' }}
            onClick={() => {
              ChangeMainImage(otherImage.value.url, null, null, false, true, false);
              setCurrentImage(otherImage);
            }}
          />
        ))
    );

    imagesArr.push(
      media?.videos
        ?.filter((video) => video && video?.value && video?.value?.url && isValidToDisplay(video, userRole))
        ?.map((video, i) => (
          <div
            className="video-thumbnail-tile"
            onClick={() => {
              ChangeMainImage(video?.value?.thumbnail, true, video?.value?.url, false, false, false);
              setCurrentImage(video);
            }}
          >
            <li
              id={`media-column-videos-${i}`}
              className="video-thumbnail-tile-img"
              key={uuidv4()}
              style={{ background: 'url(' + video.value.thumbnail + ') no-repeat center center/contain' }}
            />
          </div>
        ))
    );

    return imagesArr;
  };

  useEffect(() => {
    if (approvePendingFileFetching === false && currentImage?.attributeType === LIFESTYLE_IMAGE) {
      dispatch(updateItem());
    }
  }, [approvePendingFileFetching]);

  const handleApprovingPendingFile = async (
    fileName,
    copySource,
    destination,
    file,
    fileList,
    uploadType,
    attributeStatus,
    identifier
  ) => {
    dispatch(
      approvePendingFile(
        i18n.language,
        uploadType,
        supc,
        userId,
        fileName,
        copySource,
        destination,
        file,
        fileList,
        attributeStatus,
        identifier,
        userRole,
        associatedUsers
      )
    );
  };

  useEffect(() => {
    let newMainImage = null;
    const selectedMainImage = getMainImage(media, userRole);
    setMainImageType(
      selectedMainImage?.attributeType ? selectedMainImage?.attributeType : null
    );
    if (selectedMainImage?.attributeType === LIFESTYLE_IMAGE) {
      newMainImage = isValidToDisplay(selectedMainImage, userRole) ? selectedMainImage?.value?.url : selectedMainImage?.previousValue?.url;
    } else if (selectedMainImage?.url) {
      newMainImage = selectedMainImage?.url;
      setMainImageType(GS1_IMAGE);
    } else if (selectedMainImage?.attributeType === VIDEO) {
      newMainImage = selectedMainImage?.value?.thumbnail;
    } else {
      newMainImage = selectedMainImage?.value?.url;
    }
    setMainImage(newMainImage ? newMainImage+ '?key=' + uuidv4() : null);
    setImages(generateImages());
    setCurrentImage(getNewMainImage());
  }, [media, approvePendingFileFetching]);

  const getNewMainImage = () => {
    if (getMainImage(media, userRole)?.attributeType || getMainImage(media, userRole)?.url) {
      return getMainImage(media, userRole);
    } else if (getMainImage(media, userRole)?.attributeType === VIDEO) {
      return getMainImage(media, userRole)?.thumbnail;
    } else {
      return null;
    }
  };

  const handleApprovingPendingProductImages = async (
    fileName,
    copySource,
    destination,
    file,
    fileList,
    uploadType,
    attributeStatus
  ) => {
    await dispatch(
      approvePendingFile(
        i18n.language,
        uploadType,
        supc,
        userId,
        fileName,
        copySource,
        destination,
        file,
        fileList,
        attributeStatus,
        fileName,
        userRole,
        associatedUsers
      )
    );
  };
  return (
    <div id="media-column-animated-fadein" className="media-column animated fadeIn">
      <div id="media-column-images" className="product-images">
        <div id="media-column-images-editable" className="main editable">
          <div
            id="media-column-images-main-image"
            className={isVideoImage ? 'video-thumbnail main-img is-video' : 'main-img'}
          >
            {mainImage && (
              <div
                id={
                  mainImage &&
                  `media-column-images-main-image-image-${
                    mainImage.split('/')[mainImage.split('/').length - 1].split('.')[0]
                  }`
                }
                key={uuidv4()}
                onClick={togglePreview}
              >
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: 'Magnifier',
                      isFluidWidth: true,
                      src: mainImage,
                    },
                    largeImage: {
                      src: mainImage,
                      width: 1800,
                      height: 1800,
                    },
                    shouldUsePositiveSpaceLens: true,
                    enlargedImageContainerDimensions: { width: '200%', height: '200%' },
                    imageStyle: { minHeight: '19vw', maxHeight: '19vw' },
                    enlargedImageContainerStyle: { borderColor: '#000000', boxShadow: '0 3px 7px rgba(0, 0, 0, 0.5)' },
                  }}
                />
              </div>
            )}
            {mainImageType !== null && mainImageType !== LIFESTYLE_IMAGE ? (
              <div id="media-column-images-main-image-header" className="image-header">
                {
                  <div id={`media-column-images-main-image-${mainImageType.toLowerCase()}-header`} className="label">
                    {mainImageType}
                  </div>
                }
                <ImageDelete
                  media={media}
                  currentImage={currentImage}
                  attributeManagement={permissions}
                  handleImagesDelete={handleImagesDelete}
                  imageType={mainImageType}
                />
              </div>
            ) : null}

            {checkLifestyleHeaderValidToDisplay(currentImage,userRole) && (
              <div id="media-column-images-main-image-header" className="image-header">
                {
                  <div id="media-column-images-main-image-lifestyle-header" className="label">
                    {i18n.t('fileImport.lifestyleImage')}
                  </div>
                }
                <LifestyleImageDelete
                  currentImage={currentImage}
                  attributeManagement={permissions}
                  handleLifestyleImageDelete={handleLifestyleImageDelete}
                />
              </div>
            )}

            <LifestyleImagePending
              media={media}
              currentImage={currentImage}
              attributeManagement={permissions}
              handleApprovingPendingFile={handleApprovingPendingFile}
              setLifeStyleImageRejectModal={setLifeStyleImageRejectModal}
              userRole={userRole}
            />
            <LifestyleImageApproved
              media={media}
              currentImage={currentImage}
              attributeManagement={permissions}
              handleDismiss={handleDismiss}
              userRole={userRole}
            />
            <LifestyleImageRejected
              media={media}
              currentImage={currentImage}
              handleDismiss={handleDismiss}
              userRole={userRole}
            />

            {/* Status widget of the otherImages */}
            {checkImageApprovedStatus(currentImage, userRole) && (
              <ImageApprovedStatusWidget
                id="media-column-product-images-approved-status-widget"
                isEditable={checkIsEditable(currentImage, permissions)}
                imageType={currentImage?.attributeType}
                image={currentImage}
                imageList={setImageList(currentImage, media)}
                handleProductImagesSave={handleProductImagesSave}
                approvable={checkIsApprovable(currentImage, permissions)}
              />
            )}
            {checkImageRejectedStatus(currentImage, userRole) && (
              <ImageRejectedStatusWidget
                id="media-column-product-images-rejected-status-widget"
                isEditable={checkIsEditable(currentImage, permissions)}
                imageType={currentImage?.attributeType}
                image={currentImage}
                imageList={setImageList(currentImage, media)}
                handleProductImagesSave={handleProductImagesSave}
                setCurrentImage={setCurrentImage}
                approvable={checkIsApprovable(currentImage, permissions)}
              />
            )}
            {checkImagePendingStatus(currentImage) && (
              <ImagePendingStatusWidget
                id="media-column-product-images-pending-status-widget"
                image={currentImage}
                imageList={setImageList(currentImage, media)}
                handleApprovingPendingProductImages={handleApprovingPendingProductImages}
                setProductImageRejectModal={setProductImageRejectModal}
                approvable={checkIsApprovable(currentImage, permissions)}
                editable={checkIsEditable(currentImage, permissions)}
                attributeType={currentImage?.attributeType}
              />
            )}
          </div>
        </div>
        <ul>
          {images?.length > 0 && images}
          {(permissions.LIFESTYLE_IMAGE.editable ||
            permissions.PRODUCT_IMAGE.editable ||
            permissions.VIDEO.editable) && (
            <li id="media-column-images-addnew" className="add-new" onClick={() => setShowUpload(true)}>
              <i id="media-column-images-addnew-icon" className="icon fi flaticon-add-button-inside-black-circle" />
            </li>
          )}
        </ul>
        <MainImagePreview
          showPreview={showPreview}
          isVideoPreview={isVideoPreview}
          videoSrc={videoSrc}
          mainImage={mainImage}
          togglePreview={togglePreview}
        />
      </div>
      <CommonMediaWidgets id="media-column-common-media-widgets" mediaInfo={mediaInfo} supc={supc} id="id" permissions={permissions}/>
      <UploadModal
        id="media-column-upload-modal"
        visible={showUpload}
        loading={false}
        supc={supc}
        visibleSetter={setShowUpload}
        approvable={permissions?.LIFESTYLE_IMAGE?.approvable}
        userType={userRole}
        setCurrentImage={setCurrentImage}
        videoEditable={permissions?.VIDEO?.editable}
        permissions={permissions}
      />
      <RejectModal
        id="media-column-lifestyle-reject-modal"
        modalVisibility={lifeStyleImageRejectModal}
        setRejectVisibility={setLifeStyleImageRejectModal}
        rejectFunction={(comment) => {
          handleSave(
            LIFESTYLE_IMAGE,
            media?.lifestyleImageUrl?.value,
            media?.lifestyleImageUrl?.attributeStatus === DELETE_PENDING ? DELETE_REJECTED : REJECTED,
            comment
          );
        }}
        rejectAttribute={i18n.t('activityLog.lifestyleImage')}
      />
      <RejectModal
        id={`media-column-${currentImage?.attributeType === PRODUCT_IMAGE ? 'product-image' : 'video'}-reject-modal`}
        modalVisibility={productImageRejectModal}
        setRejectVisibility={setProductImageRejectModal}
        rejectFunction={(comment) => {
          handleProductImagesSave(
            currentImage?.attributeType,
            currentImage,
            setImageList(currentImage, media),
            currentImage?.attributeStatus === DELETE_PENDING ? DELETE_REJECTED : REJECTED,
            comment
          );
        }}
        rejectAttribute={
          currentImage?.attributeType === PRODUCT_IMAGE
            ? i18n.t('activityLog.productImage')
            : i18n.t('activityLog.video')
        }
      />
    </div>
  );
}

export default MediaColumn;
