const initialState = {
  item: {
    data: {},
    error: null,
    fetching: false,
  },
  itemCatalog: {
    data: [],
    error: null,
    fetching: false,
  },
  vendors: {
    data: [],
    pageable: {},
    error: null,
    fetching: false,
  },
  itemApprovalPendingVendors: {
    data: [],
    pageable: {},
    error: null,
    fetching: false,
  },
  updateAttribute: {
    data: [],
    error: null,
    fetching: false,
  },
  attributes: {
    data: [],
    error: null,
    fetching: false,
  },
  uploadFile: {
    data: {},
    error: null,
    fetching: false,
  },
  deleteFile: {
    data: {},
    error: null,
    fetching: false,
  },
  categories: {
    data: [],
    error: null,
    fetching: false,
  },
  classes: {
    data: [],
    error: null,
    fetching: false,
  },
  auditList: {
    data: [],
    error: null,
    fetching: false,
  },
  bulkUpload: {
    data: [],
    error: null,
    fetching: false,
  },
  userData: {
    data: [],
    error: null,
    fetching: false,
  },
  vendorScore: {
    data: {},
    error: null,
    fetching: false,
  },
  approvePendingFile: {
    data: {},
    error: null,
    fetching: false,
  },
  reportError: {
    data: {},
    error: null,
    fetching: false,
  },
  updateItem: {
    data: {},
    error: null,
    fetching: false,
  },
  downloadItems: {
    data: {},
    error: null,
    fetching: false,
  },
  downloadActivityLog: {
    data: {},
    error: null,
    fetching: false,
  },
  approveAttributes: {
    data: [],
    error: null,
    fetching: false,
  },
};
export default initialState;
