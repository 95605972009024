import React, { useState } from 'react';
import { APPROVED, PENDING, REJECTED } from '../../../enums/ApprovalStatus';
import { Button, Icon, Input } from 'antd';
import { UNABBREVIATED_DESCRIPTION } from '../../../enums/AttributeType';
import i18n from '../../../_i18n';
import MissingInfoPlaceholder from '../../../components/MissingInfoPlaceholder';
import RejectModal from '../../../components/modals/RejectModal';
import { useTranslation } from 'react-i18next';
import { attributeEditorDetails } from '../../../utils/Utils';

const itemTitleBackgroundCSSApplier = (approvalStatus, isChangedUser) => {
  if (approvalStatus === APPROVED && isChangedUser) {
    return 'highlighted approved';
  } else if (approvalStatus === REJECTED && isChangedUser) {
    return 'highlighted rejected';
  } else if (approvalStatus === PENDING) {
    return 'highlighted';
  } else {
    return 'editable';
  }
};

function getTitleDescription(coreData, userType) {
  return coreData?.titleDescription?.authorRole === userType && coreData?.titleDescription?.attributeStatus === REJECTED
    ? coreData?.titleDescription?.value
    : coreData?.titleDescription?.previousValue;
}

function handleUadSave(coreData, descriptionUnderEdit, attributeManagement, handleSave) {
  const desc = coreData?.titleDescription?.value ? coreData?.titleDescription?.value : coreData?.idsDescription;
  if (descriptionUnderEdit !== desc) {
    handleSave(
      UNABBREVIATED_DESCRIPTION,
      descriptionUnderEdit,
      attributeManagement.UNABBREVIATED_DESCRIPTION.approvable ? APPROVED : PENDING,
      null
    );
  }
}

function showHighlighted(coreData, userType) {
  return (
    coreData?.titleDescription?.authorRole === userType &&
    (coreData?.titleDescription?.attributeStatus === APPROVED ||
      coreData?.titleDescription?.attributeStatus === REJECTED)
  );
}

function Uad({ coreData, handleSave, userType, permissions }) {
  const { t } = useTranslation();

  //When unabbreviated description is made editable again, do the changes related to authorRole
  const [description, setDescription] = useState(getTitleDescription(coreData, userType));
  const [idsDescription] = useState(coreData?.idsDescription);
  const [descriptionUnderEdit, setDescriptionUnderEdit] = useState(getTitleDescription(coreData, userType) ? getTitleDescription(coreData, userType) : idsDescription);
  const [descriptionEdit, setDescriptionEdit] = useState(false);
  const [descriptionRejectModal, setDescriptionRejectModal] = useState(false);

  return (
    <div className="info-row">
      <div
        id="info-column-title"
        className={`title-description title-editable data-block editable inline-edits ${itemTitleBackgroundCSSApplier(
          coreData?.titleDescription?.attributeStatus,
          coreData?.titleDescription?.authorRole === userType
        )}`}
      >
        {permissions.UNABBREVIATED_DESCRIPTION.editable && (
          <div
            id="info-column-edit-icon"
            className="icon edit-icon fi flaticon-edit title-edit-icon"
            onClick={() => setDescriptionEdit(true)}
          />
        )}
        <span id="info-column-data" className={`data ${descriptionEdit ? 'hide' : ''}`}>
          <span className="title-description">{description ? description : idsDescription}</span>

          {/* TOGGLE THIS WHEN HIGHLIGHTED */}
          {showHighlighted(coreData, userType) && (
            <div id="info-column-description-label" className="label-actions">
              <div id="info-column-description-status" className="status-label">
                {coreData?.titleDescription?.attributeStatus}
              </div>
              {coreData?.titleDescription?.authorRole === userType && (
                <Button
                  id="info-column-description-dismiss"
                  className="btn"
                  onClick={() => {
                    setDescriptionUnderEdit(coreData?.titleDescription?.previousValue);
                    setDescription(coreData?.titleDescription?.previousValue);
                    handleSave(UNABBREVIATED_DESCRIPTION, description, null);
                  }}
                >
                  {i18n.t('infoColumn.dismiss')}
                </Button>
              )}
            </div>
          )}
          {!permissions.UNABBREVIATED_DESCRIPTION.approvable &&
            coreData?.titleDescription?.attributeStatus === PENDING && (
              <div id="info-column-edits-pending-label" className="label-actions">
                <div id="info-column-edits-pending-status" className="label">
                  <span className="label-actions">{i18n.t('infoColumn.editsPending')}</span>
                </div>
              </div>
            )}

          <MissingInfoPlaceholder
            id="info-column-description-missing-placeholder"
            show={!description && !idsDescription && !descriptionEdit}
          />
        </span>
        <div id="info-column-description-data-edit" className={`data edit ${descriptionEdit ? '' : 'hide'}`}>
          <Input
            id="info-column-description-data-edit-input"
            value={descriptionUnderEdit}
            onChange={(e) => {
              setDescriptionUnderEdit(e.target.value);
            }}
          />
        </div>
        {(coreData?.titleDescription?.authorRole === userType ||
          coreData?.titleDescription?.attributeStatus === PENDING) && (
          <div className="footer-bar">
            {' '}
            <div className="edited-meta">
              <div>
                {attributeEditorDetails(
                  userType,
                  coreData?.titleDescription?.attributeStatus,
                  coreData?.titleDescription
                )}
              </div>
              {coreData?.titleDescription?.attributeStatus === REJECTED && (
                <div className="reason">
                  {i18n.t('infoColumn.reason')}: <span>{coreData?.titleDescription?.comment}</span>
                </div>
              )}
            </div>
          </div>
        )}
        {/* remove ! sign in this */}

        <div id="info-column-footer-bar" className="footer-bar">
          {/* TOGGLE THIS WHEN HIGHLIGHTED */}

          {permissions.UNABBREVIATED_DESCRIPTION.approvable &&
            coreData?.titleDescription?.attributeStatus === PENDING && (
              <div id="info-column-title-description-actions" className="footer-actions">
                <Button
                  id="info-column-title-description-actions-approve"
                  className="btn green-action-btn invert"
                  onClick={() => {
                    handleSave(UNABBREVIATED_DESCRIPTION, descriptionUnderEdit, APPROVED);
                  }}
                >
                  <Icon id="info-column-title-description-actions-approve-icon" type="check" />{' '}
                  {i18n.t('infoColumn.approve')}
                </Button>
                <Button
                  id="info-column-title-description-actions-reject"
                  className="btn red-action-btn invert"
                  onClick={() => {
                    setDescriptionRejectModal(true);
                  }}
                >
                  <Icon id="info-column-title-description-actions-reject-icon" type="close" />{' '}
                  {i18n.t('infoColumn.reject')}
                </Button>
              </div>
            )}

          {/* DISABLE THIS WHEN HIGHLIGHTED */}
          {descriptionEdit && (
            <div id="info-column-description-edit" className="footer-actions">
              <Button
                id="info-column-description-edit-save"
                className="btn green-action-btn invert"
                onClick={() => {
                  setDescription(descriptionUnderEdit);
                  handleUadSave(coreData, descriptionUnderEdit, permissions, handleSave);
                  setDescriptionEdit(false);
                }}
              >
                <Icon id="info-column-description-edit-save-icon" type="check" /> {t('infoColumn.save')}
              </Button>
              <Button
                id="info-column-description-edit-cancel"
                className="btn red-action-btn invert"
                onClick={() => {
                  setDescriptionEdit(false);
                  setDescriptionUnderEdit(description ? description : idsDescription);
                }}
              >
                <Icon id="info-column-description-edit-cancel-icon" type="close" /> {t('infoColumn.cancel')}
              </Button>
            </div>
          )}
        </div>
        <RejectModal
          id="info-column-description-reject-modal"
          modalVisibility={descriptionRejectModal}
          setRejectVisibility={setDescriptionRejectModal}
          rejectFunction={(comment) => {
            handleSave(UNABBREVIATED_DESCRIPTION, description, REJECTED, comment);
            setDescription(coreData?.titleDescription?.previousValue);
          }}
          rejectAttribute={i18n.t('fileImport.columnDescription')}
        />
      </div>
    </div>
  );
}

export default Uad;
