import React from 'react';
import i18n from 'i18next';
import { Button, Icon, Tooltip } from 'antd';
import { LIFESTYLE_IMAGE } from 'enums/AttributeType';
import { attributeEditorDetails } from '../../../utils/Utils';
import { APPROVED, DELETE_APPROVED } from '../../../enums/ApprovalStatus';

function LifestyleImageApproved({ media, currentImage, attributeManagement, handleDismiss, userRole }) {
  return (
    media?.lifestyleImageUrl?.authorRole === userRole &&
    (media?.lifestyleImageUrl?.attributeStatus === APPROVED ||
      media?.lifestyleImageUrl?.attributeStatus === DELETE_APPROVED) &&
    currentImage?.attributeType === LIFESTYLE_IMAGE && (
      <div id="media-column-lifestyle-images-footer-bar-approved" className="footer-bar approved">
        <Icon
          id="media-column-lifestyle-images-footer-bar-approved-icon"
          type="approved"
          theme="filled"
          className="icon status-icon warning"
        />
        <div id="media-column-lifestyle-images-footer-bar-approved-label" className="label">
          {media?.lifestyleImageUrl?.attributeStatus === DELETE_APPROVED
            ? i18n.t('mediaColumn.approvedDelete')
            : i18n.t('mediaColumn.approved')}
        </div>
        {media?.lifestyleImageUrl?.authorRole === userRole && (
          <div id="media-column-lifestyle-images-footer-bar-approved-actions" className="footer-actions">
            <Button
              id="media-column-lifestyle-images-footer-bar-approved-actions-dismiss"
              className="btn"
              onClick={() => {
                console.log('lifestyle dismiss');
                handleDismiss(
                  media?.lifestyleImageUrl?.value?.imageID,
                  LIFESTYLE_IMAGE,
                  media?.lifestyleImageUrl,
                  null
                );
              }}
            >
              {i18n.t('infoColumn.dismiss')}
            </Button>
          </div>
        )}
        {(attributeManagement.LIFESTYLE_IMAGE?.editable || attributeManagement.LIFESTYLE_IMAGE?.approvable) && (
          <Tooltip
            id="media-column-lifestyle-images-footer-bar-approved-tooltip"
            title={attributeEditorDetails(userRole, media?.lifestyleImageUrl?.attributeStatus, currentImage)}
          >
            <i
              id="media-column-lifestyle-images-footer-bar-approved-tooltip-icon"
              className="info-icon icon fi flaticon-information-button"
            />
          </Tooltip>
        )}
      </div>
    )
  );
}

export default LifestyleImageApproved;
