import { combineReducers } from 'redux';
import initialState from 'store/initialState';

import {
  ATTRIBUTES,
  CATEGORIES,
  DELETE_FILE,
  ITEM,
  ITEM_CATALOG,
  UPLOAD_FILE,
  VENDOR,
  ITEM_APPROVAL_PENDING_VENDOR,
  AUDITING_DETAILS,
  BULK_UPLOAD,
  USER_DATA,
  CLASSES,
  VENDOR_SCORE,
  UPDATE_ATTRIBUTE,
  APPROVE_ATTRIBUTE,
  APPROVE_PENDING_FILE,
  REPORT_ERROR,
  UPDATED_ITEM,
  DOWNLOAD_ITEMS,
  DOWNLOAD_AUDIT,
} from 'actions/actionTypes';

import { createReducer } from 'reduxHelpers';

const item = createReducer(ITEM, initialState.item);
const itemCatalog = createReducer(ITEM_CATALOG, initialState.itemCatalog);
const vendors = createReducer(VENDOR, initialState.vendors);
const itemApprovalPendingVendors = createReducer(ITEM_APPROVAL_PENDING_VENDOR, initialState.itemApprovalPendingVendors);
const categories = createReducer(CATEGORIES, initialState.categories);
const classes = createReducer(CLASSES, initialState.classes);
const attributes = createReducer(ATTRIBUTES, initialState.attributes);
const updateAttribute = createReducer(UPDATE_ATTRIBUTE, initialState.updateAttribute);
const approveAttributes = createReducer(APPROVE_ATTRIBUTE, initialState.approveAttributes);
const deleteFile = createReducer(DELETE_FILE, initialState.deleteFile);
const uploadFile = createReducer(UPLOAD_FILE, initialState.uploadFile);
const auditList = createReducer(AUDITING_DETAILS, initialState.auditList);
const bulkUpload = createReducer(BULK_UPLOAD, initialState.bulkUpload);
const userData = createReducer(USER_DATA, initialState.userData);
const vendorScore = createReducer(VENDOR_SCORE, initialState.vendorScore);
const approvePendingFile = createReducer(APPROVE_PENDING_FILE, initialState.approvePendingFile);
const reportError = createReducer(REPORT_ERROR, initialState.reportError);
const updateItem = createReducer(UPDATED_ITEM, initialState.updateItem);
const downloadItems = createReducer(DOWNLOAD_ITEMS, initialState.downloadItems);
const downloadActivityLog = createReducer(DOWNLOAD_AUDIT, initialState.downloadActivityLog);

export default combineReducers({
  item,
  itemCatalog,
  vendors,
  itemApprovalPendingVendors,
  attributes,
  uploadFile,
  categories,
  auditList,
  deleteFile,
  bulkUpload,
  userData,
  classes,
  vendorScore,
  updateAttribute,
  approveAttributes,
  approvePendingFile,
  reportError,
  updateItem,
  downloadItems,
  downloadActivityLog,
});
