import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { vendorScore, downloadItems } from '../actions';
import i18n from '../_i18n';
import { ASSOCIATE, VENDOR, VIEWONLY } from '../enums/UserType';
import ActivityLogDownloadModal from './modals/ActivityLogDownloadModal';
import AppLoader from './AppLoader';

export function Header({ setItemCatalogOpen, setApprovals, vendorId, setItemOpen, proprietaryFilter }) {
  const [current, setCurrent] = useState('mail');
  const dispatch = useDispatch();
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);

  const {
    data: { userRole },
  } = useSelector((state) => state.userData);
  const { data } = useSelector((state) => state.vendorScore);
  const { fetching } = useSelector((state) => state.downloadItems);
  const { data: { totalItems } } = useSelector((state) => state.itemCatalog);
  useEffect(() => {
    if (userRole === VENDOR && vendorId) {
      dispatch(vendorScore(vendorId, i18n.language));
    }
  }, [vendorId, userRole, i18n.language]);

  const navigateToFileImport = () => {
    setItemCatalogOpen(false);
  };

  const navigateToItemCatalog = (approvals) => {
    setItemCatalogOpen(true);
    setApprovals(approvals);
    setItemOpen(false);
  };

  const handleClick = (e) => {
    if (e.key == "download") {
      setCurrent("mail");
    } else {
      setCurrent(e.key);
    }
  };

  var proprietarySelected = proprietaryFilter?.join();

  const { t } = useTranslation();
  return fetching ? <AppLoader show /> : (
    <div id="header-section" className="header-section">
      <div id="header-section-title" className="title">
        <div id="header-section-label" className="label"></div>
        <div id="header-section-data" className="data"></div>
      </div>
      <Menu id="header-section-menu" onClick={handleClick} selectedKeys={current} mode="horizontal" className="menu">
        {userRole !== VENDOR && userRole !== VIEWONLY && (
          <Menu.Item id="header-section-menu-pending" key="pending" onClick={() => navigateToItemCatalog(true)}>
            <Icon id="header-section-menu-pending-icon" type="bell" />
            {t('header.pendingApprovals')}
          </Menu.Item>
        )}
        <Menu.Item id="header-section-menu-catalog" key="catalog" onClick={() => navigateToItemCatalog(false)}>
          <Icon id="header-section-menu-catalog-icon" type="unordered-list" />
          {t('header.itemCatalog')}
        </Menu.Item>
        {userRole !== VIEWONLY && (
          <Menu.Item id="header-section-menu-import" key="import" onClick={navigateToFileImport}>
            <Icon id="header-section-menu-import-icon" type="cloud-upload" />
            {t('header.importViaFile')}
          </Menu.Item>
        )}
        {userRole === VENDOR && (
          <Menu.Item id="header-section-menu-download" key="download" onClick={() => dispatch(downloadItems(vendorId, totalItems, proprietarySelected))}>
            <Icon id="header-section-menu-download-icon" type="cloud-download" />
            {i18n.t('header.downloadToFile')}
          </Menu.Item>
        )}
        {userRole === ASSOCIATE && (
          <Menu.Item id="header-section-menu-activity-download" key="download" onClick={() => setDownloadModalVisible(true)}>
            <Icon id="header-section-menu-download-icon" type="download" />
            {i18n.t('header.downloadActivityLog')}
          </Menu.Item>
        )}
        {downloadModalVisible && (
          <ActivityLogDownloadModal id="header-activity-log-download-modal" visible={downloadModalVisible} setVisible={setDownloadModalVisible} />
        )}
      </Menu>
      {data?.total && userRole && userRole === VENDOR && (
        <div id="header-section-rank-widget" className="rank-widget">
          <ul id="header-section-rank-widget-breakdown" className="rank-breakdown">
            <li id="header-section-rank-widget-breakdown-poor" className="poor">
              <h3 id="header-section-rank-widget-breakdown-poor-header">{t('header.poor')?.toUpperCase()}</h3>
              <h4 id="header-section-rank-widget-breakdown-poor-data">{data?.poor}</h4>
            </li>
            <li id="header-section-rank-widget-breakdown-partial" className="partial">
              <h3 id="header-section-rank-widget-breakdown-partial-header">{t('header.partial')?.toUpperCase()}</h3>
              <h4 id="header-section-rank-widget-breakdown-partial-data">{data?.partial}</h4>
            </li>
            <li id="header-section-rank-widget-breakdown-good" className="good">
              <h3 id="header-section-rank-widget-breakdown-good-header">{t('header.good')?.toUpperCase()}</h3>
              <h4 id="header-section-rank-widget-breakdown-good-data">{data?.good}</h4>
            </li>
            <li id="header-section-rank-widget-breakdown-total" className="total">
              <h3 id="header-section-rank-widget-breakdown-total-header">{t('header.total')}</h3>
              <h4 id="header-section-rank-widget-breakdown-total-data">{data?.total}</h4>
            </li>
          </ul>
          <ul id="header-section-rank-widget-rank" className="rank ">
            <div
              id="header-section-rank-widget-rank-position"
              className={`rank-position ${data?.rating}`}
              style={{ paddingRight: 10 + 'px' }}
            >
            </div>
            <div id="header-section-rank-widget-rank-value" className={`rank-value ${data?.rating}`}>
              {data?.score}%
            </div>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Header;

Header.propTypes = {};
