import FileSaver from 'file-saver';
import Excel from 'exceljs';
import i18n from 'i18next';
import { getAttributeTypes } from './modals/ActivityLogDownloadModal';
import { setLanguageSpecificValue } from './DownloadItemList';

export function downloadAudit(data, language){
    const workbook = new Excel.Workbook();

    const downloadHeaders = [
      { header: i18n.t('infoColumn.supc'), key: 'supc', width: 10 },
      { header: i18n.t('itemList.brand'), key: 'brand', width: 20 },
      { header: i18n.t('itemList.vendor'), key: 'vendor', width: 30 },
      { header: i18n.t('itemList.description'), key: 'description', width: 30 },
      { header: i18n.t('infoColumn.gtin'), key: 'gtin', width: 20 },
      { header: i18n.t('infoColumn.mpc'), key: 'mpc', width: 20 },
      { header: i18n.t('activityColumn.attributeType'), key: 'attributeType', width: 30 },
      { header: i18n.t('activityColumn.originalValue'), key: 'originalValue', width: 30},
      { header: i18n.t('activityColumn.changedValue'), key: 'changedValue', width: 30},
      { header: i18n.t('activityColumn.changedBy'), key: 'changedBy', width: 30 },
      { header: i18n.t('activityColumn.changedDate'), key: 'changedDate', width: 30 },
      { header: i18n.t('activityColumn.status'), key: 'status', width: 30 },
      { header: i18n.t('activityColumn.approvedBy'), key: 'approvedBy', width: 30 },
      { header: i18n.t('activityColumn.approvedDate'), key: 'approvedDate', width: 30 },
    ];

    const worksheet = workbook.addWorksheet('Activity Log');
    worksheet.columns = downloadHeaders;
    worksheet.getRow(1).font  = {
      bold: true
    };

    const attributeStatuses = {
        APPROVED: i18n.t('activityLog.approved'),
        REJECTED: i18n.t('activityLog.rejected'),
        DELETE_APPROVED: i18n.t('activityLog.deleteApproved'),
        DELETE_REJECTED: i18n.t('activityLog.deleteRejected'),
    };

    worksheet.addRows(downloadData(data, attributeStatuses, language));

    workbook.xlsx.writeBuffer().then((i) => {
      const blob = new Blob([i], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, `${i18n.t('enrichmentHeader.activityLog')}.xlsx`);
    });
}

export const downloadData = (result, attributeStatuses, language) => {
    if (result?.length > 0) {
      return result.map((item) => {
        return {
            supc: item?.supc,
            brand: item?.brand,
            vendor: item?.vendor,
            description: setLanguageSpecificValue(language, item?.enDescription, item?.frDescription ),
            gtin: item?.gtin,
            mpc: item?.mpc,
            attributeType: getAttributeTypes()[item?.attributeType],
            originalValue: item?.initialValue,
            changedValue: item?.changedValue,
            changedBy: item?.author,
            changedDate: item?.changedDate,
            status: attributeStatuses[item?.attributeStatus],
            approvedBy: item?.approver,
            approvedDate: item?.approvedDate,
        };
      });
    } else {
      return [];
    }
};

export default downloadAudit;
