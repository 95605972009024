import React, { useEffect } from 'react';
import { Modal, Button, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { loadAuditingDetails } from '../../actions';
import i18n from '../../_i18n';
import { APPROVED, REJECTED, DELETE_APPROVED, DELETE_REJECTED } from '../../enums/ApprovalStatus';

export function ActivityLogModal({ visibility, setActivityLogVisibility, supc, attributeType }) {
  const dispatch = useDispatch();
  const {
    data: { userRole },
  } = useSelector((state) => state.userData);
  const { data: result, fetching: activityLogFetching } = useSelector((state) => state.auditList);
  useEffect(() => {
    if (visibility) {
      dispatch(
        loadAuditingDetails(supc, attributeType !== 'ITEM' ? attributeType : '', [
          APPROVED,
          REJECTED,
          DELETE_APPROVED,
          DELETE_REJECTED,
        ])
      );
    }
  }, [dispatch, visibility, supc]);

  const closeModal = () => {
    setActivityLogVisibility(false);
  };

  const attributeTypes = {
    FEATURES_AND_BENEFITS: i18n.t('infoColumn.featuresAndBenefits'),
    PREPARATION_AND_COOKING: i18n.t('infoColumn.preparationAndCooking'),
    SERVING_SUGGESTIONS: i18n.t('infoColumn.servingSuggestions'),
    PACKAGING_AND_STORAGE: i18n.t('infoColumn.packagingAndStorage'),
    ABOUT_BRAND: i18n.t('infoColumn.aboutTheBrand'),
    RECIPES: i18n.t('mediaWidgetColumn.additionalResources'),
    CERTIFICATION: i18n.t('nutritionalColumn.certifications'),
    SPECIFICATION_SHEET: i18n.t('mediaWidgetColumn.specificationSheet'),
    MSDS_SHEET: i18n.t('mediaWidgetColumn.msdsSheet'),
    ATTRIBUTE: i18n.t('infoColumn.attributes'),
    LIFESTYLE_IMAGE: i18n.t('activityLog.lifestyleImage'),
    PRODUCT_IMAGE: i18n.t('activityLog.productImage'),
    GS1_IMAGE: i18n.t('activityLog.gs1Image'),
    VIDEO: i18n.t('activityLog.video'),
    UNABBREVIATED_DESCRIPTION: i18n.t('fileImport.columnDescription'),
    ITEM_OVERVIEW: i18n.t('fileImport.itemOverview'),
    PRODUCT_DESCRIPTOR: i18n.t('activityLog.productDescriptor'),
    PACKAGING_INFORMATION: i18n.t('activityLog.packagingInformation'),
    SIZE_AND_SHAPE_OF_PRODUCT: i18n.t('activityLog.sizeAndShapeOfProduct'),
    YIELD_OR_SERVING_SIZE: i18n.t('activityLog.yieldOrServingSize'),
    QUALITY_AND_FORMAT: i18n.t('activityLog.qualityAndFormat'),
    PREPARATION_AND_COOKING_INSTRUCTIONS: i18n.t('activityLog.preparationAndCookingInstructions'),
    STORAGE_AND_USAGE: i18n.t('activityLog.storageAndUsage'),
    HANDLING_INSTRUCTIONS: i18n.t('activityLog.handlingInstructions'),
    ADDITIONAL_PRODUCT_INFORMATION: i18n.t('activityLog.additionalProductInformation'),
    MARKETING_STATEMENTS: i18n.t('activityLog.marketingStatements'),
    CULINARY_APPLICATIONS: i18n.t('activityLog.culinaryApplications')
  };

  const columns = [
    {
      title: i18n.t('activityColumn.dateTime'),
      dataIndex: ['commitMetadata', 'timestamp'],
      ellipsis: true,
      className: 'date-time-col',
    },
    {
      title: i18n.t('activityColumn.attributeType'),
      dataIndex: 'attributeType',
      ellipsis: true,
      className: 'changedby-col',
      render: (text, row) =>
        text != null
          ? attributeTypes [row.attributeType] || row.attributeType
          : null,
    },
    {
      title: i18n.t('activityColumn.change'),
      dataIndex: 'changedData',
      ellipsis: true,
      className: 'change-col',
      render: (text) =>
        text != null ? (
          <>
            <div id="activity-log-original" className="original">
              <div id="activity-log-original-label" className="label">
                {i18n.t('activityColumn.original')}
              </div>
              <div id="activity-log-original-value" className="data">
                {text?.initialValue}
              </div>
            </div>
            <div id="activity-log-changed-to" className="changed">
              <div id="activity-log-changed-to-label" className="label">
                {i18n.t('activityColumn.changedTo')}
              </div>
              <div id="activity-log-changed-to-value" className="data">
                {text?.changedValue}
              </div>
            </div>
          </>
        ) : null,
    },
    {
      title: i18n.t('activityColumn.changedBy'),
      dataIndex: ['commitMetadata', 'author'],
      ellipsis: true,
      className: 'changedby-col',
    },

    {
      title: i18n.t('activityColumn.status'),
      dataIndex: 'approvalData',
      className: 'approvedby-col',
      render: (text, row) =>
        text != null
          ? {
              APPROVED: (
                <p id="activity-log-approved">{`${i18n.t('activityLog.approvedBy')} ${text?.approver} ${i18n.t(
                  'activityLog.on'
                )} ${text?.timestamp}`}</p>
              ),
              REJECTED: (
                <p id="activity-log-rejected">{`${i18n.t('activityLog.rejectedBy')} ${text?.approver} ${i18n.t(
                  'activityLog.on'
                )} ${text?.timestamp}`}</p>
              ),
              DELETE_APPROVED: (
                <p id="activity-log-delete-approved">{`${i18n.t('activityLog.deleteApprovedBy')} ${
                  text?.approver
                } ${i18n.t('activityLog.on')} ${text?.timestamp}`}</p>
              ),
              DELETE_REJECTED: (
                <p id="activity-log-delete-rejected">{`${i18n.t('activityLog.deleteRejectedBy')} ${
                  text?.approver
                } ${i18n.t('activityLog.on')} ${text?.timestamp}`}</p>
              ),
            }[row?.attributeStatus]
          : null,
    },
  ];
  return (
    <Modal
      id="item-popup-activity-log"
      className="item-popup activity-log"
      visible={visibility}
      destroyOnClose={true}
      style={{ width: '1vw' }}
      title={[
        <div id="item-popup-activity-log-title" className="header" key="item-popup-activity-log-header">
          <i className="icon fi flaticon-history"></i>
          <div id="item-popup-activity-log-label" className="title">
            {i18n.t('enrichmentHeader.activityLog')}
          </div>
          {attributeType !== 'ITEM' ? (
            <div id="item-popup-activity-log-subtitle" className="subtitle">
              {attributeType?.replace(/_/g, ' ')}
            </div>
          ) : null}
        </div>,
      ]}
      onCancel={closeModal}
      footer={[
        <Button id="activity-log-submit" type="primary" onClick={closeModal} key="activity-log-submit">
          {i18n.t('activityLog.close')}
        </Button>,
      ]}
    >
      <Table
        id="activity-log-table"
        columns={columns}
        dataSource={result}
        pagination={{ pageSize: 50 }}
        scroll={{ y: '55vh' }}
        loading={activityLogFetching}
      />
    </Modal>
  );
}


export default ActivityLogModal;

ActivityLogModal.propTypes = {};
