import React from 'react';
import { GS1_IMAGE, PRODUCT_IMAGE, VIDEO } from 'enums/AttributeType';
import { PENDING, REJECTED, DELETE_APPROVED, DELETE_PENDING } from '../../../enums/ApprovalStatus';

function checkDeleteStatus(approved) {
  return approved ? DELETE_APPROVED : DELETE_PENDING;
}

function setAttributeType(type) {
  if (type === PRODUCT_IMAGE){
    return PRODUCT_IMAGE;
  } else if (type === GS1_IMAGE) {
    return GS1_IMAGE;
  } else {
    return VIDEO;
  }
}

function setID(type, currentImage) {
  if (type === PRODUCT_IMAGE){
    return currentImage?.value?.imageID;
  } else if (type === GS1_IMAGE) {
    return currentImage?.imageID;
  } else {
    return currentImage?.value?.videoID;
  }
}

function setFiles(type, media) {
  if (type === PRODUCT_IMAGE){
    return media?.otherImageUrls;
  } else if (type === GS1_IMAGE) {
    return media?.gs1Images;
  } else {
    return media?.videos;
  }
}

function ImageDelete({ media, currentImage, attributeManagement, handleImagesDelete, imageType }) {
  return (
    ((imageType === PRODUCT_IMAGE && attributeManagement.PRODUCT_IMAGE.editable) ||
      (imageType === GS1_IMAGE && attributeManagement.GS1_IMAGE.editable) ||
      (imageType === VIDEO && attributeManagement.VIDEO.editable)) &&
    !(
      currentImage?.attributeStatus === DELETE_APPROVED ||
      currentImage?.attributeStatus === DELETE_PENDING ||
      currentImage?.attributeStatus === REJECTED
    ) && (
      <div
        id="media-column-images-editable-delete"
        style={{ paddingLeft: 10 + 'px' }}
        className="delete-icon fi flaticon-delete"
        onClick={() => {
          handleImagesDelete(
            setID(imageType, currentImage),
            setAttributeType(imageType),
            currentImage,
            setFiles(imageType, media),
            currentImage?.attributeStatus === PENDING
              ? null
              : checkDeleteStatus(
                  (imageType === PRODUCT_IMAGE && attributeManagement?.PRODUCT_IMAGE?.approvable) ||
                  (imageType === GS1_IMAGE && attributeManagement?.GS1_IMAGE?.approvable) ||
                    (imageType === VIDEO && attributeManagement?.VIDEO?.approvable)
                ),
            setID(imageType, currentImage)
          );
        }}
      />
    )
  );
}

export default ImageDelete;
