import React from 'react';
import i18n from '../_i18n';

function MissingInfoPlaceholder({ show }) {
  return (
    <div id="missing-info-placeholder" className={show ? 'missing-info-placeholder' : 'missing-info-placeholder hide'}>
      <div id="missing-info-placeholder-divider-1" className="left-right-divider"></div>
      {i18n.t('placeholder.missingInformation')}
      <div id="missing-info-placeholder-divider-2" className="left-right-divider"></div>
    </div>
  );
}

export default MissingInfoPlaceholder;
