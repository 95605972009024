import React from 'react';
import { Icon, Button, Tooltip } from 'antd';
import { SPECIFICATION_SHEET } from 'enums/AttributeType';
import {
  APPROVED,
  DELETE_APPROVED,
  DELETE_PENDING,
  DELETE_REJECTED,
  PENDING,
  REJECTED,
} from '../../../enums/ApprovalStatus';

import i18n from '../../../_i18n';
import { useSelector } from 'react-redux';

function setSheetId(sheetType) {
  return sheetType === SPECIFICATION_SHEET ? 'spec-sheets' : 'msds-sheets';
}

function setSheetLabel(sheetType) {
  return sheetType === SPECIFICATION_SHEET
    ? i18n.t('mediaWidgetColumn.specificationSheet')
    : i18n.t('mediaWidgetColumn.msdsSheet');
}

function checkStatusAndSetLabel(mediaSheet, attrStatus, ifTrue, ifFalse) {
  return mediaSheet?.attributeStatus === attrStatus ? i18n.t(ifTrue) : i18n.t(ifFalse);
}

function checkStatusAndAuthorRole(mediaSheet, userType, attrStatus, attrDeleteStatus) {
  return (
    mediaSheet?.authorRole === userType &&
    (mediaSheet?.attributeStatus === attrStatus || mediaSheet?.attributeStatus === attrDeleteStatus)
  );
}

function checkIfPendingAndApprovable(mediaSheet, attributePermission) {
  return (
    (mediaSheet?.attributeStatus === PENDING || mediaSheet?.attributeStatus === DELETE_PENDING) &&
    attributePermission?.approvable
  );
}

function checkIfPendingAndNotApprovable(mediaSheet, attributePermission) {
  return (
    (mediaSheet?.attributeStatus === PENDING || mediaSheet?.attributeStatus === DELETE_PENDING) &&
    !attributePermission?.approvable
  );
}

function isDeletable(attributePermission, mediaSheet) {
  return (
    attributePermission.editable &&
    !(
      mediaSheet?.attributeStatus === DELETE_APPROVED ||
      mediaSheet?.attributeStatus === DELETE_PENDING ||
      mediaSheet?.attributeStatus === REJECTED
    )
  );
}

export function getSheetStatus(mediaSheet, attributePermission) {
  if (mediaSheet?.attributeStatus === PENDING) {
    return null;
  } else if (attributePermission?.approvable) {
    return DELETE_APPROVED;
  } else {
    return DELETE_PENDING;
  }
}

function isStatusNotRejectedForTooltip(mediaSheet, userType) {
  return (
    (mediaSheet?.attributeStatus === PENDING ||
      mediaSheet?.attributeStatus === DELETE_PENDING ||
      mediaSheet?.authorRole === userType) &&
    mediaSheet.attributeStatus !== REJECTED &&
    mediaSheet.attributeStatus !== DELETE_REJECTED &&
    mediaSheet.attributeStatus !== null
  );
}

export function SpecSheet({
  isValidToDisplay,
  fileCSSApplier,
  handleApprovingPendingFile,
  handleSave,
  handleFileDelete,
  sheetType,
  mediaSheet,
  prefix,
  attributePermission,
  id,
  attributeEditorDetails,
  setShowSpecSheetUpload,
  setRejectModalVisibility,
  setSpecUnderEdit,
}) {
  const {
    data: { userRole: userType },
  } = useSelector((state) => state.userData);

  return (
    <div id={`common-media-widgets-spec-sheets-info-row${id}`} className="info-row">
      <div
        id={`common-media-widgets-spec-sheets-info-row-datablock-${id}`}
        className="data-block editable no-highlight"
      >
        <div id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-label-${id}`} className="label">
          {setSheetLabel(sheetType)}
          {attributePermission.editable && (
            <i
              id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-label-add-${id}`}
              className="icon add-data-block fi flaticon-add-button-inside-black-circle"
              onClick={() => setShowSpecSheetUpload(true)}
            />
          )}
        </div>
        {mediaSheet?.filter((sheet) => isValidToDisplay(sheet, userType))?.length > 0
          ? mediaSheet
              ?.filter((sheet) => isValidToDisplay(sheet, userType))
              ?.filter((sheet) => sheet?.value?.sheetURL)
              ?.map((sheet, i) => (
                <div
                  id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-status-${i + 1}-${id}`}
                  key={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-status-${
                    sheet?.value?.sheetURL
                  }`}
                  className={fileCSSApplier(sheet?.attributeStatus, sheet?.authorRole === userType)}
                >
                  {checkIfPendingAndApprovable(sheet, attributePermission) && (
                    <div
                      id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-file-label-${i + 1}-${id}`}
                      className="file-label-header"
                    >
                      <Icon
                        id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-file-label-icon-$${
                          i + 1
                        }-${id}`}
                        type="warning"
                        theme="filled"
                        className="status-icon warning"
                      />
                      <div
                        id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-file-pending-${
                          i + 1
                        }-${id}`}
                        className="label"
                      >
                        {checkStatusAndSetLabel(
                          sheet,
                          DELETE_PENDING,
                          'mediaColumn.pendingApprovalDelete',
                          'mediaColumn.pendingApproval'
                        )}
                      </div>
                      <div
                        id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-label-actions-${
                          i + 1
                        }-${id}`}
                        className="label-file-actions"
                      >
                        <Button
                          id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-label-actions-approve-${
                            i + 1
                          }-${id}`}
                          className="btn green-action-btn pure empty-btn"
                          onClick={() => {
                            let urlArray = sheet?.value?.sheetURL?.split('/');
                            handleApprovingPendingFile(
                              sheet?.value?.sheetName,
                              'staging/' + prefix + urlArray[urlArray.length - 1],
                              prefix + urlArray[urlArray.length - 1],
                              sheet,
                              mediaSheet,
                              sheetType,
                              sheet?.attributeStatus === DELETE_PENDING ? DELETE_APPROVED : APPROVED,
                              sheet?.value?.sheetURL
                            );
                          }}
                        >
                          <Icon
                            id={`common-media-widgets-${setSheetId(
                              sheetType
                            )}-info-row-datablock-label-actions-approve-icon-${i + 1}-${id}`}
                            type="check"
                          />
                        </Button>
                        <Button
                          id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-label-actions-reject-${
                            i + 1
                          }-${id}`}
                          className="btn red-action-btn pure empty-btn"
                          onClick={() => {
                            setRejectModalVisibility(true);
                            setSpecUnderEdit(sheet);
                          }}
                        >
                          <Icon
                            id={`common-media-widgets-${setSheetId(
                              sheetType
                            )}-info-row-datablock-label-actions-reject-icon-${i + 1}-${id}`}
                            type="close"
                          />
                        </Button>
                      </div>
                    </div>
                  )}
                  {checkIfPendingAndNotApprovable(sheet, attributePermission) && (
                    <div
                      id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-non-approvable-pending-${
                        i + 1
                      }-${id}`}
                      className="file-label-header"
                    >
                      <Icon
                        id={`common-media-widgets-${setSheetId(
                          sheetType
                        )}-info-row-datablock-non-approvable-pending-icon-${i + 1}-${id}`}
                        type="warning"
                        theme="filled"
                        className="status-icon warning"
                      />
                      <div
                        id={`common-media-widgets-${setSheetId(
                          sheetType
                        )}-info-row-datablock-non-approvable-pending-label-${i + 1}-${id}`}
                        className="label"
                      >
                        {checkStatusAndSetLabel(
                          sheet,
                          DELETE_PENDING,
                          'mediaColumn.pendingApprovalDelete',
                          'mediaColumn.pendingApproval'
                        )}
                      </div>
                    </div>
                  )}
                  {checkStatusAndAuthorRole(sheet, userType, APPROVED, DELETE_APPROVED) && (
                    <div
                      id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-completed-${i + 1}-${id}`}
                      className="file-label-header"
                    >
                      <Icon
                        id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-completed-approved-icon-${
                          i + 1
                        }-${id}`}
                        type="checked"
                        theme="filled"
                        className="status-icon approved"
                      />
                      <div
                        id={`common-media-widgets-${setSheetId(
                          sheetType
                        )}-info-row-datablock-completed-approved-label-${i + 1}-${id}`}
                        className="label"
                      >
                        {checkStatusAndSetLabel(
                          sheet,
                          DELETE_APPROVED,
                          'mediaColumn.approvedDelete',
                          'mediaColumn.approved'
                        )}
                      </div>
                      {sheet?.authorRole === userType && (
                        <Button
                          id={`common-media-widgets-${setSheetId(
                            sheetType
                          )}-info-row-datablock-completed-approved-dismiss-${i + 1}-${id}`}
                          className="btn btn-reject"
                          onClick={() => handleSave(sheetType, sheet, null, null, sheet?.value?.sheetURL)}
                        >
                          {i18n.t('infoColumn.dismiss')}
                        </Button>
                      )}
                    </div>
                  )}
                  {checkStatusAndAuthorRole(sheet, userType, REJECTED, DELETE_REJECTED) && (
                    <div
                      id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-completed-reject-${
                        i + 1
                      }-${id}`}
                      className="file-label-header"
                    >
                      <Icon
                        id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-completed-reject-icon-${
                          i + 1
                        }-${id}`}
                        type="cross"
                        theme="filled"
                        className="status-icon rejected"
                      />
                      <div
                        id={`common-media-widgets-${setSheetId(sheetType)}-info-row-datablock-completed-reject-label-${
                          i + 1
                        }-${id}`}
                        className="label"
                      >
                        {checkStatusAndSetLabel(
                          sheet,
                          DELETE_REJECTED,
                          'mediaColumn.rejectedDelete',
                          'mediaColumn.rejected'
                        )}
                      </div>
                      <div
                        id={`common-media-widgets-${setSheetId(
                          sheetType
                        )}-info-row-datablock-completed-reject-label-actions-${i + 1}-${id}`}
                        className="label-file-actions"
                      >
                        {sheet?.authorRole === userType && (
                          <Button
                            id={`common-media-widgets-${setSheetId(
                              sheetType
                            )}-info-row-datablock-completed-reject-button-${i + 1}-${id}`}
                            className="btn btn-reject"
                            onClick={() => {
                              handleSave(sheetType, sheet, null, null, sheet?.value?.sheetURL);
                            }}
                          >
                            {i18n.t('infoColumn.dismiss')}{' '}
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    id={`common-media-widgets-${setSheetId(sheetType)}-inner-fileinfo-${i + 1}-${id}`}
                    className="inner-file-info"
                  >
                    <a
                      id={`common-media-widgets-${setSheetId(sheetType)}-inner-fileinfo-icon-${i + 1}-${id}`}
                      className="icon"
                      href={sheet?.value?.sheetURL}
                      target="_blank"
                    />
                    <div
                      id={`common-media-widgets-${setSheetId(sheetType)}-inner-fileinfo-info-${i + 1}-${id}`}
                      className="info"
                    >
                      <div
                        id={`common-media-widgets-${setSheetId(sheetType)}-inner-fileinfo-info-inner-filename-${
                          i + 1
                        }-${id}`}
                        className="filename"
                      >
                        <a
                          id={`common-media-widgets-${setSheetId(sheetType)}-inner-fileinfo-info-inner-fileurl-${
                            i + 1
                          }-${id}`}
                          href={sheet?.value?.sheetURL}
                          target="_blank"
                        >
                          {sheet?.value?.sheetName}
                        </a>
                        {isDeletable(attributePermission, sheet) && (
                          <i
                            id={`common-media-widgets-${setSheetId(sheetType)}-inner-fileinfo-info-inner-delete-${
                              i + 1
                            }-${id}`}
                            className="delete-icon fi flaticon-delete"
                            onClick={() =>
                              handleFileDelete(
                                sheet?.value?.sheetName,
                                sheetType,
                                sheet,
                                mediaSheet,
                                getSheetStatus(sheet, attributePermission),
                                sheet?.value?.sheetURL
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                    {checkStatusAndAuthorRole(sheet, userType, REJECTED, DELETE_REJECTED) && (
                      <Tooltip
                        id={`common-media-widgets-${setSheetId(sheetType)}-inner-fileinfo-rejected-tooltip-${
                          i + 1
                        }-${id}`}
                        overlayStyle={{ whiteSpace: 'pre-line' }}
                        title={
                          attributeEditorDetails(userType, sheet.attributeStatus, sheet) +
                          '\n' +
                          i18n.t('infoColumn.reason') +
                          ': ' +
                          sheet?.comment
                        }
                      >
                        <i
                          id={`common-media-widgets-${setSheetId(sheetType)}-inner-fileinfo-rejected-tooltip-icon-${
                            i + 1
                          }-${id}`}
                          className="info-icon fi flaticon-information-button"
                        />
                      </Tooltip>
                    )}
                    {isStatusNotRejectedForTooltip(sheet, userType) && (
                      <Tooltip
                        id={`common-media-widgets-${setSheetId(sheetType)}-inner-fileinfo-not-rejected-tooltip-${
                          i + 1
                        }-${id}`}
                        title={attributeEditorDetails(userType, sheet.attributeStatus, sheet)}
                      >
                        <i
                          id={`common-media-widgets-${setSheetId(sheetType)}-inner-fileinfo-not-rejected-tooltip-icon-${
                            i + 1
                          }-${id}`}
                          className="info-icon fi flaticon-information-button"
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              ))
          : null}
      </div>
    </div>
  );
}

export default SpecSheet;
