import React from 'react';
import { useSelector } from 'react-redux';
import AppLoader from '../../../../../components/AppLoader';
import SpecSheet from './SpecSheet';
import Recipes from './Recipes';
import RelatedItems from './RelatedItems';
import MsdsSheet from './MsdsSheet';

function CommonMediaWidgetsPreview({ mediaInfo, idMobile }) {
  const { fetching: deleteFetching } = useSelector((state) => state.deleteFile);
  const { fetching: itemFetching } = useSelector((state) => state.item);

  return itemFetching || deleteFetching ? (
    <AppLoader show />
  ) : (
    <>
      <SpecSheet mediaInfo={mediaInfo} idMobile={idMobile} />
      <Recipes mediaInfo={mediaInfo} idMobile={idMobile} />
      <RelatedItems mediaInfo={mediaInfo} />
      <MsdsSheet mediaInfo={mediaInfo} idMobile={idMobile} />
    </>
  );
}

export default CommonMediaWidgetsPreview;
